import React, { useEffect, useRef, useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import apiHelper from '../../../../Helper/api-helper'
import DEVELOPMENT_CONFIG from '../../../../Helper/config'
import ReactLoader from '../../../../ControlComponents/react-loader'
import { NotificationManager } from 'react-notifications'
import VideoChallengeUser from './videoChallengeUser'

function ChallengesDetail() {
    const location = useLocation()
    const[data,setData] = useState(JSON.parse(localStorage.getItem("challengeItem")))
    const ref = useRef()
    const [seriesDetail, setSeriesDetail] = useState(null)
    const naviagte = useNavigate()
    const [id, setId] = useState(localStorage.getItem("userChallengeId"))
    const [episodes, setEpisodes] = useState([])
    const [responses, setResponses] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [creatorName, setCreatorName] = useState(null)
    const [creatorImg, setCreatorImg] = useState(null)
    const [chatOpen, setChatOpen] = useState(false)
    let message = localStorage.getItem("message")

    useEffect(() => {
        if (message) {
            NotificationManager.success(message);
            localStorage.removeItem("message")
            getDetailsAPI()
        }
    }, [])

    async function getMyDetailsAPI() {
        let result = await apiHelper.getRequest("auth/get-my-detail")
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setCreatorName(result?.data?.name);
            setCreatorImg(result.data.profile_pic)
        } else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult?.data?.token)
                localStorage.setItem("refresh-token", refreshTokenResult?.data?.refresh_token)
                setRefresh(true)
            }
        }
    }

    async function getDetailsAPI() {
            setIsLoading(true)
            let result = await apiHelper.getRequest("creator/challenge-details?id=" + id)
            if (result.code == DEVELOPMENT_CONFIG.statusCode) { 
                setSeriesDetail(result.data.challengesDetails)
                setEpisodes(result?.data.Episodes)
                setResponses(result.data.myResponse)
                const trimmedName = result?.data?.challengesDetails?.title?.replace(/\s/g, '');
                const chatId = `${result.data.challengesDetails.id}${trimmedName}`;
                localStorage.setItem("chatId", chatId)
                setChatOpen(true)
                setIsLoading(false)
            }
            else if (result.code == 401) {
                let refreshToken = localStorage.getItem("refresh-token")
                let data = {
                    "refresh_token": refreshToken,
                    "scope": "refresh_token"
                }
                let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
                if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                    localStorage.setItem("token", refreshTokenResult.data.token)
                    localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                    setRefresh(true)
                }
            }
        
    }

  

    useEffect(() => {
        getDetailsAPI()
        getMyDetailsAPI()
    }, [refresh])


    const extractVideoAndThumbnailFromEmbedCode = async (embedCode) => {
        try {
            const urlRegex = /https?:\/\/[^\s]+/i;
            const urlMatch = embedCode.match(urlRegex);
            if (urlMatch && urlMatch[0]) {
                let videoUrl = urlMatch[0];
                videoUrl = videoUrl.replace(/"/g, '');
                return videoUrl;
            }
        } catch (error) {
            return null;
        }
    };


    return (
        <div ref={ref} className="main-inner-content">
            {isLoading && <ReactLoader />}
           
            <VideoChallengeUser  seriesDetail={seriesDetail} extractVideoAndThumbnailFromEmbedCode={extractVideoAndThumbnailFromEmbedCode} chatOpen={chatOpen}
                creatorName={creatorName} creatorImg={creatorImg} episodes={episodes} responses={responses} data={data}/>
        </div>
    )
}

export default ChallengesDetail;