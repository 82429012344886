import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { modalCross, dummy, dotshorizontal, heartfill, message, Send_feed, smile } from "../../Constants/images";

const UserDetailModal = ({ setDetailModal }) => {
  const [text, setText] = useState('');
  const [styles, setStyles] = useState({
    isBold: false,
    isItalic: false,
    isUnderline: false,
    fontColor: '#000000'
  });
  const [colorPickerVisible, setColorPickerVisible] = useState(false);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const toggleStyle = (style) => {
    if (style === 'fontColor') {
      setColorPickerVisible(!colorPickerVisible);
    } else {
      setStyles((prevStyles) => ({
        ...prevStyles,
        [style]: !prevStyles[style]
      }));
    }
  };

  const handleColorChange = (color) => {
    setStyles((prevStyles) => ({
      ...prevStyles,
      fontColor: color.hex
    }));
  };

  const textStyle = {
    fontWeight: styles.isBold ? 'bold' : 'normal',
    fontStyle: styles.isItalic ? 'italic' : 'normal',
    textDecoration: styles.isUnderline ? 'underline' : 'none',
    color: styles.fontColor
  };

  return (
    <div
      className="modal fade show modal-cast"
      style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}
    >
      <div className="loader"></div>
      <div className="modal-dialog modal-xl modal-dialog-centered">
        <div className="modal-content border-0">
          <div className="modal-body verify-modal-box p-0">
            <div className="row">
              <div className="col-8 position-relative p-0">
                <img src={dummy} className="detail-modal-img" />
                <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close" >
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setDetailModal(false)

                    }}
                    src={modalCross}
                    alt=""
                    className="img-fluid btn-cloesed"
                  />
                </a>
              </div>
              <div className="col-4 p-0">
                <div className="feed-user px-2">
                  <a href="" className=" text-decoration-none text-black">
                    <img src={dummy} className="userimg" />
                  </a>
                  <div className="">
                    <a href="" className=" text-decoration-none text-black">
                      <h4 className="fw-600 mb-2 lh-1">John Smith</h4>
                    </a>
                    <p className="fw-500  mb-0 side_text lh-1">15 hours ago</p>
                  </div>
                  <div className="post-options">
                    <img
                      src={dotshorizontal}
                      className="btn pl-2 pr-0 py-2"
                      type="button"
                      id="postdrop"
                    
                    />
                  </div>
                </div>
                <div className="description_feed px-2">
                  <p className="mb-0 side-text modal-side-text">
                    Loreum ipsum dolor sit amet, consectetur adipiscing elit.
                    <span className="fw-600 blue-text   "> More...</span>
                  </p>
                </div>
                <div className="totallikecomment px-2">
                  <p className="">
                    Liked by <span className="fw-600"> John Smith </span> and{" "}
                    <span className="fw-600"> 123 </span>others
                  </p>
                </div>
                <div className="social_icon_feed modal_social_icon_feed">
                  <div className="like_feed d-flex align-items-center gap-2">
                    <img src={heartfill} />
                    <h5 className="total_like mb-0">125</h5>
                  </div>
                  <div className="like_feed d-flex align-items-center gap-2">
                    <img src={message} />
                    <h5 className="total_like mb-0">124</h5>
                  </div>
                  <div className="like_feed d-flex align-items-center gap-2">
                    <img
                      src={Send_feed}
                    />
                    <h5 className="total_like mb-0">25</h5>
                  </div>
                </div>
                <div>
                  <h5 className="p-2">Comments</h5>
                  <div>
                    <div className="comment-section px-2 ">
                      <div className="d-flex">
                        <div>
                          <img src={dummy} className="commentImg" />
                        </div>
                        <div className="comment-text  mx-2">
                          <div className="d-flex">
                            <div>
                              <h6 className="fw-600 mb-2 lh-1">John Smith</h6>
                              <p className="fw-200  mb-0 side_text lh-1" style={{ fontSize: "12px" }}>1 h</p>
                            </div>
                            <div>   <img
                              style={{ marginLeft: "198px" }}
                              src={dotshorizontal}
                            />
                            </div>
                          </div>
                          <div>
                            <p className="mb-0 side-text modal-side-text">
                              Loreum ipsum dolor sit amet, consectetur adipiscing elit.
                              <span className="fw-600 blue-text"> More...</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="px-5 d-flex">
                        <div className="py-1"><img src={heartfill} style={{ height: 16, width: 40 }} /></div>
                        <p style={{ fontSize: "12px" }} className="px-2 text-black fw-300">10 Like</p>
                        <p style={{ fontSize: "12px" }} className="px-2">5 Reply</p>

                      </div>
                    </div>
                    <div className="reply-section px-5 ">
                      <div className="d-flex">
                        <div>
                          <img src={dummy} className="commentImg" />
                        </div>
                        <div className="comment-text  mx-2">
                          <div className="d-flex">
                            <div>
                              <h6 className="fw-600 mb-2 lh-1">John Smith</h6>
                              <p className="fw-200  mb-0 side_text lh-1" style={{ fontSize: "12px" }}>1 h</p>
                            </div>
                            <div>  <img
                              style={{ marginLeft: "120px" }}
                              src={dotshorizontal}
                            />
                            </div>
                          </div>
                          <div>
                            <p className="mb-0 side-text modal-side-text">
                              Loreum ipsum dolor sit amet, consectetur adipiscing elit.
                              <span className="fw-600 blue-text"> More...</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="px-5 d-flex">
                        <div className="py-1"><img src={heartfill} style={{ height: 16, width: 40 }} /></div>
                        <p style={{ fontSize: "12px" }} className="px-2 text-black fw-300">10 Like</p>
                        <p style={{ fontSize: "12px" }} className="px-2">5 Reply</p>

                      </div>
                    </div>
                    <div className="reply-section px-5 ">
                      <div className="d-flex">
                        <div>
                          <img src={dummy} className="commentImg" />
                        </div>
                        <div className="comment-text  mx-2">
                          <div className="d-flex">
                            <div>
                              <h6 className="fw-600 mb-2 lh-1">John Smith</h6>
                              <p className="fw-200  mb-0 side_text lh-1" style={{ fontSize: "12px" }}>1 h</p>
                            </div>
                            <div>  <img
                              style={{ marginLeft: "120px" }}
                              src={dotshorizontal}
                            />
                            </div>
                          </div>
                          <div>
                            <p className="mb-0 side-text modal-side-text">
                              Loreum ipsum dolor sit amet, consectetur adipiscing elit.
                              <span className="fw-600 blue-text"> More...</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="px-5 d-flex">
                        <div className="py-1"><img src={heartfill} style={{ height: 16, width: 40 }} /></div>
                        <p style={{ fontSize: "12px" }} className="px-2 text-black fw-300">10 Like</p>
                        <p style={{ fontSize: "12px" }} className="px-2">5 Reply</p>

                      </div>
                    </div>
                    <div className="comment-post-input"> 
                      <div className="d-flex">
                        <img src={smile}/>
                        <input type="text"  className=" comment-input" placeholder="type a comment"/>
                      </div>
                      <div className="position-relative">
                            <p>B I U A</p>
                          </div>
                          <button className="comment-post-btn">post</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailModal;
