import React from 'react';
import "./trailorPlayer.css"
import ReactPlayer from 'react-player';
const TrailorPlayer = ({ videoUrl }) => {
console.log(videoUrl,"videoUrl")
    const autoplayUrl = `${videoUrl}?autoplay=1&mute=1`;
    return (
        <div className="modal-cus" id="videoplayer">
            <div className="container-fluid px-0">
                <div className="custom-trailorplayer position-relative p-0 video-width">
                    {videoUrl && (
                        <ReactPlayer
                            className="video-seriesdetail"
                            url={autoplayUrl}
                            controls
                            playing
                            width="100%"
                            height="500"

                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default TrailorPlayer;

