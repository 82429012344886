import React, { useEffect, useRef, useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from '../../Helper/config'
import ReactLoader from '../../ControlComponents/react-loader'
import './challenges-detail.css'
import ROUTES from '../../Helper/route-helper'
import VideoChallenge from './videoChallenge'
import ChallengeModal from './createChallengeModal'
import DeleteChallengeModal from './deleteChallengeModal'
import { NotificationManager } from 'react-notifications'
import ProgressBarComponent from '../../ControlComponents/progressBar'

function CreatorChallengesDetail() {
  const [challengeModal, setChallengeModal] = useState(false)
  const [progressLoading, setProgressLoading] = useState(false)
  const [progress, setProgress] = useState(0);
  const location = useLocation()
  const ref = useRef()
  const [seriesDetail, setSeriesDetail] = useState(null)
  const naviagte = useNavigate()
  const [id, setId] = useState(localStorage.getItem("challenge_id"))
  const [episodes, setEpisodes] = useState([])
  const [responses, setResponses] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [creatorName, setCreatorName] = useState(null)
  const [creatorImg, setCreatorImg] = useState(null)
  const [chatId, setChatId] = useState("1")
  const [chatOpen, setChatOpen] = useState(false)
  let message = localStorage.getItem("message")

  useEffect(() => {
    if (message) {
      NotificationManager.success(message);
      localStorage.removeItem("message")
    }
    return () => {
      message = null
    };
  }, []);
  const handleDeleteOpen = () => {
    setDeleteModal(!deleteModal)
  }

  async function getDeleteAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest("creator/delete-challenge?id=" + id)
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      setIsLoading(false)
      setDeleteModal(false)
      naviagte(ROUTES.CREATER_CHALLENGES)
      localStorage.setItem("message", result.message)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
    else {
      NotificationManager.error(result.message)
    }
  }

  async function getMyDetailsAPI() {
    let result = await apiHelper.getRequest("auth/get-my-detail")
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      setCreatorName(result?.data?.name);
      setCreatorImg(result.data.profile_pic)
    } else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult?.data?.token)
        localStorage.setItem("refresh-token", refreshTokenResult?.data?.refresh_token)
        setRefresh(true)
      }
    }
  }
  
  async function getDetailsAPI() {
    if (id != undefined) {
      setIsLoading(true)
      let result = await apiHelper.getRequest("creator/my-challenge-details?id=" + id)
      if (result.code == DEVELOPMENT_CONFIG.statusCode) {
        setSeriesDetail(result.data.challengesDetails)
        setEpisodes(result?.data.Episodes)
        setResponses(result.data.challengeResponses)
        const trimmedName = result?.data?.challengesDetails?.title?.replace(/\s/g, '');
        const chatId = `${result?.data?.challengesDetails?.id}${trimmedName}`;
        localStorage.setItem("chatId", chatId)
        setChatOpen(true)
        setIsLoading(false)
      }
      else if (result.code == 401) {
        let refreshToken = localStorage.getItem("refresh-token")
        let data = {
          "refresh_token": refreshToken,
          "scope": "refresh_token"
        }
        let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
        if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
          localStorage.setItem("token", refreshTokenResult.data.token)
          localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
          setRefresh(true)
        }
      }
    }
  }

 
  const handleOpen = () => {
    setChallengeModal(!challengeModal)
  }

  useEffect(() => {
    getDetailsAPI()
    getMyDetailsAPI()
  }, [refresh])


  const extractVideoAndThumbnailFromEmbedCode = (embedCode) => {
    try {
      const urlRegex = /https?:\/\/[^\s]+/i;
      const urlMatch = embedCode.match(urlRegex);
      if (urlMatch && urlMatch[0]) {
        const videoUrl = urlMatch[0].replace(/"/g, '');
        const regex = /\/embed\/([^?]+)/;
        const match = videoUrl.match(regex);
        if (match && match[1]) {
          return match[1];
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };


  return (
    <div ref={ref} className="main-inner-content">
      {isLoading && <ReactLoader />}
      {progressLoading && <>
        <ProgressBarComponent progress={progress} />
      </>}
      <VideoChallenge seriesDetail={seriesDetail} extractVideoAndThumbnailFromEmbedCode={extractVideoAndThumbnailFromEmbedCode} chatOpen={chatOpen}
        creatorName={creatorName} creatorImg={creatorImg} episodes={episodes} responses={responses} handleOpen={handleOpen} handleDeleteOpen={handleDeleteOpen} getDetailsAPI={getDetailsAPI} />
      {challengeModal && <ChallengeModal challengeId={seriesDetail.id} getListAPI={getDetailsAPI} setIsLoading={setIsLoading} setChallengeModal={setChallengeModal} setProgressLoading={setProgressLoading} setProgress={setProgress} />}
      {deleteModal && <DeleteChallengeModal setDeleteModal={setDeleteModal} getDeleteAPI={getDeleteAPI} />}
    </div>
  )
}

export default CreatorChallengesDetail;