import React, { useEffect, useRef, useState } from 'react'
import Header2 from './Components/Header2/Header2'
import Sidebar from './Components/Sidebar/Sidebar'
import { dashBackground1 } from './Constants/images'
import ROUTES from './Helper/route-helper'
import { useLocation } from 'react-router-dom'
const Layout = ({ children }) => {
  const location = useLocation();
  const refToTop = useRef(null);
  const [user, setUser] = useState(false)
  useEffect(() => {
    refToTop.current && refToTop.current.scrollIntoView();
  })
  const userFn = (setShowModal) => {
    setShowModal(false)
  }
  const filteredRoutes = Object.keys(ROUTES)
  .filter(route => route !== 'CREATER_MEMBERSHIP')
  .map(key => {
    const routeWithoutSlash = ROUTES[key];
    const routeWithSlash = ROUTES[key].endsWith("/") ? ROUTES[key] : ROUTES[key] + "/";
    return [routeWithoutSlash, routeWithSlash];
  })
  .flat();

const routeExists = filteredRoutes.includes(location.pathname);

  return (
    <div>
      {((routeExists)) && <Sidebar user={user} setUser={setUser} />}
      <div style={{ backgroundImage: `url(${dashBackground1})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
        className={(location.pathname == ROUTES.CREATER_MEMBERSHIP || !routeExists) ? "main-content content-open content-open-uper" : "main-content"}>
        <Header2 user={user} userFn={userFn} />
        <main ref={refToTop}>
          {children}
        </main>
      </div>
    </div>
  )
}
export default Layout;
