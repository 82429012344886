import React, { useState } from "react";
import { modalCross, pinkgallery, dummy, crossthemebgpink, selectImage } from "../../Constants/images";

const PostModal = ({ setPostModal }) => {
    return (
        <div
            className="modal fade show modal-cast"
            style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}
        >
            <div className="loader"></div>
            <div className="modal-dialog  modal-dialog-centered modal-schedule-width">
                <div className="modal-content modal-radius  border-0">
                    <div className="modal-header forgot-header align-items-center pt-4">
                        <h3
                            className="modal-title text-center mx-auto forgot-title"
                            id="exampleModalLabel"
                        >
                              {" "}
                            Create Post{" "}
                        </h3>
                        <a
                            className="m-0 px-0 btn-closed-main"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <img
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPostModal(false);
                                }}
                                src={modalCross}
                                alt=""
                                className="img-fluid btn-cloesed"
                            />
                        </a>
                    </div>
                    <div className="modal-body  verify-modal-box px-2 px-sm-4">
                        <div className="feed-user px-0">
                            <a href="" className=" text-decoration-none text-black">
                                <img
                                    src="/assets/images/creater-dashboard/dummy.png"
                                    className="userimg"
                                />
                            </a>
                            <div className="">
                                <a href="" className=" text-decoration-none text-black">
                                    <h4 className="fw-600 mb-2 lh-1">John Smith</h4>
                                </a>
                            </div>
                        </div>
                        <form className="my-2">
                            <textarea className="form-control create-post-textarea" rows={7} placeholder="What's on your mind?"></textarea>
                            <div className="d-flex my-4">
                                <div class="mx-2">
                                    <span className="py-5 px-4 create-post-select-span" >
                                        <img src={selectImage} class="create-post-select-img" />
                                    </span>
                                </div>
                                <div class="d-flex justify-content-between overflow-scroll">
                                    <div class="mx-2 position-relative">
                                        <img src={dummy} class="create-post-img" />
                                        <img src={crossthemebgpink} class="create-post-cross-btn" />
                                    </div>
                                    <div class="mx-2 position-relative">
                                        <img src={dummy} class="create-post-img" />
                                        <img src={crossthemebgpink} class="create-post-cross-btn" />
                                    </div>
                                    <div class="mx-2 position-relative">
                                        <img src={dummy} class="create-post-img" />
                                        <img src={crossthemebgpink} class="create-post-cross-btn" />
                                    </div>
                                    <div class="mx-2 position-relative">
                                        <img src={dummy} class="create-post-img" />
                                        <img src={crossthemebgpink} class="create-post-cross-btn" />
                                    </div>
                                    <div class="mx-2 position-relative">
                                        <img src={dummy} class="create-post-img" />
                                        <img src={crossthemebgpink} class="create-post-cross-btn" />
                                    </div>
                                </div>
                            </div>



                            <div className="px-4 py-3">
                                <button className="common-btn w-100">Post</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostModal;
