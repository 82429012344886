import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { favGrey, favRed, noSeries, notification } from '../../Constants/images'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../../src/Helper/config"
import ROUTES from '../../Helper/route-helper'
import PriceTag from '../../CreaterDashboard/component/price-tag'
import { NotificationManager } from 'react-notifications'
import BuyModal from './buyModal'
function ShopSeries({ setTabSwitch, loading, detailList, channelImg, channelName, channelId, setLoading, getDetailApi ,check}) {
    const navigate = useNavigate()
    const [refresh, setRefresh] = useState(false)
    const [buyModal, setBuyModal] = useState(false)
    const [item, setItem] = useState({})

    const handleNavigate = (id) => {
        if(check == "shop"){
            navigate(ROUTES.SHOP_SERIES_DETAIL, { state: { id: id } }
            )
        }
        else if( check == "download"){
            navigate(ROUTES.DOWNLOAD_SERIES_DETAIL, { state: { id: id } }
            )
        }
     
        localStorage.setItem("channelName", channelName)
        localStorage.setItem("channelImg", channelImg)
        localStorage.setItem("channelId", channelId)
    }

    const handleOpen = (item) => {
        setItem(item)
        setBuyModal(true)
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="stream-btn pt-3 pb-4">
                        <button className="common-btn btn-blue left-corner" onClick={() => setTabSwitch(0)}>Series</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(1)}>Recorded Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(2)}>Upcoming Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(3)}> Images</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(4)}>Audios</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(5)}>Videos</button>
                    </div>
                    {
                        detailList?.length == 0 && loading == false ? (
                            <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                <img src={noSeries} />
                            </div>
                        ) :
                            <>
                                {detailList.length > 0 && detailList?.map((item) => (
                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 pb-4">
                                        <div className="latest-video mx-auto" key={item.id}
                                        >
                                            <div className='position-relative' >
                                                <div className="latest-video-content  pt-2 pt-sm-3 px-2 px-sm-3"
                                                >
                                                    <img src={item.thumbnail} className="img-fluid latest-vid-img new-latest-video" />
                                                    {
                                                        item?.is_purchase == 0 && <PriceTag classes={"price_tag"} firstValue={`$${item.price}`} secondValue={"00"} />
                                                    }

                                                </div>
                                                {/* {
                                                    item.is_fav == 1 ? <div className=" like-fav-cus" onClick={() => addFavourites(item)} style={{ cursor: "pointer" }}>
                                                        <img src={favRed} alt="" className='fav-like-series' />                                                 </div>
                                                        :
                                                        <div className=" like-fav-cus" onClick={() => addFavourites(item)} style={{ cursor: 'pointer' }}>
                                                            <img src={favGrey} alt="" className='fav-like-series' />
                                                        </div>
                                                } */}
                                            </div>
                                            <div className="latest-video-cus px-3 py-3">
                                                <h4 className="fw-600">{item.title}</h4>
                                                <div className="latest-series-name">
                                                    <div className="video-tile" >
                                                        <p className='mb-0 fw-400'>{item.episodesCount} {item.episodesCount == 1 ? "Episode" : "Episodes"}</p>
                                                        <p className='mb-0 fw-500'>{item.year}</p>
                                                    </div>
                                                    {
                                                        item.is_purchase == 0 ? <button style={{ cursor: 'pointer' }} onClick={() => handleOpen(item)} className="common-btn watch-btn buy-btn">
                                                            <span className='me-2'>Buy Now</span>
                                                        </button> : <button style={{ cursor: "pointer" }} onClick={() => handleNavigate(item.id)
                                                        } className="common-btn watch-btn buy-btn">
                                                            <span className='me-2'>View</span>
                                                        </button>
                                                    }
                                                 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ))}

                                {
                                    buyModal && <BuyModal item={item} setLoading={setLoading} getDetailApi={getDetailApi} setBuyModal={setBuyModal} check="series" />
                                }

                            </>
                    }

                </div>
            </div >
        </>
    )
}

export default ShopSeries