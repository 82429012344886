import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { facebook, insta, logoFooter, twitter, footer  } from '../../Constants/images';
import ROUTES from '../../Helper/route-helper';
import './footer.css'

function Footer() {
  return (
    <div> 
    <footer className="endsecton text-center mt-5" style={{backgroundImage: `url(${footer})`}}>
    <div className="fooetr-enbar py-60 mt-3">
      <Link className="navbar-brand" to="/">
        <img src={logoFooter} alt=""/>
        </Link>
     
        <ul className="d-flex flex-wrap align-items-center mx-auto justify-content-center my-5  p-0 footer-ul">
          <li><NavLink activeclassname="active" to="/" className="nav-link">Home</NavLink></li>
          <li><NavLink activeclassname="active" to={ROUTES.ABOUT_US} className="nav-link">About us</NavLink>
          </li>
          <li><NavLink activeclassname="active" to={ROUTES.HELP} className="nav-link">Help</NavLink></li>
          <li><NavLink  activeclassname="active" to={ROUTES.CONTACT_US} className="nav-link">Contact us</NavLink></li>
          <li><NavLink activeclassname="active" to={ROUTES.HELP} className="nav-link">Disclaimer</NavLink></li>
          <li><NavLink activeclassname="active" to={ROUTES.HELP} className="nav-link">Terms & Conditions</NavLink></li>
        </ul>
       <div className="social-icon">
          <ul className="d-flex flex-wrap align-items-center mx-auto justify-content-center my-5 gap-2 p-0 ">
            <li><Link to="">
              <img src={facebook} alt=""/>
            </Link></li>
            <li><Link to="">
              <img src={twitter} alt=""/>
            </Link></li>
            <li><Link to="">
              <img src={insta} alt=""/>
            </Link></li>
          </ul>
        </div>
    </div>

      <div className="footer-bottom py-4 f18-size">
        <span>Copyright © 2023 Play Human</span>
      </div>

   </footer>
   </div>
  )
}

export default Footer;