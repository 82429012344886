import React, { useEffect, useState } from 'react'
import { leftArrow, playLightbtn, seeAllArrow } from '../../../../Constants/images';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import SeriesVideoPlayer from '../../../../Components/VideoPlayer/seriesVideoPlayer';

function Trailor(props) {
  const [video, setVideo] = useState('')
  const [show, setShow] = useState(false)

  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    var hDisplay = h > 0 ? h + ":" : "";
    var mDisplay = m > 0 ? m : "00";
    var sDisplay = s > 0 ? s : "00";
    return hDisplay + mDisplay + ":" + sDisplay;
  }

  const handleClick = (item) => {
    setVideo(item?.video)
    setShow(!show)
  }

  return (
    <div>
      <section className="trailer py-60">
        <div className="container">
          <div className="chanel-header ">
            <h3 className="fw-600 mb-0">Trailer & Clips</h3>
            {/* <div className=' text-end '>
            <img src={leftArrow} className="me-3" />
            <img src={seeAllArrow} className="ms-2" />
            </div> */}
          </div>
          <div className=" trailor-slide-main mt-5  ">
            {
              props?.seriesDetail?.series_clips?.map((item) => {
                return (
                  <div key={item?.id} onClick={() => handleClick(item)} className="item ">
                    <div className="trailor-slider-cus ">
                      <img
                        src={item?.thumbnail}
                        className="movie-more br30"
                        alt=""
                      />
                      <div className="trailor-duration">
                        <p className="white">{props?.modifyDuration(item?.duration)}</p>
                      </div>
                      <div className="trailor-play">
                        <button className="eps-play">
                          <img src={playLightbtn} alt="" className='img-fluid' />
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
      
      {
        show && <SeriesVideoPlayer show={show} setShow={setShow} video_url={video} />
      }

    </div>
  )
}

export default Trailor;