import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { favGrey, favRed, noChannelFound, search } from '../../../Constants/images';
import ReactLoader from '../../../ControlComponents/react-loader';
import apiHelper from '../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../../Helper/config';
import ROUTES from '../../../Helper/route-helper';
import '../category.css';

function EducationCategory() {
    const navigate = useNavigate();
    const [channelList, setChannelList] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [inputText, setInputText] = useState('')
    const [_, forceUpdate] = useState(0)
    const [tabSwitch, setTabSwitch] = useState(0)
    const location = useLocation()

    async function getAPI() {
        setIsLoading(true)
        let url;
        if (tabSwitch == 0) {
            url = "channel/channels-list?cat_id=" + location.state?.id
        }
        else if (tabSwitch == 2) {
            url = "channel/channels-list?filterSort=asc&cat_id=" + location?.state?.id
        }
        else if (tabSwitch == 1) {
            url = "channel/channels-list?cat_id=" + location?.state?.id
        }
        let result = await apiHelper.getRequest(url)
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            if (tabSwitch == 0 || tabSwitch == 2) {
                setChannelList(result?.data?.channels)
            }
            else if (tabSwitch == 1) {
                setChannelList(result?.data?.popularChannels)
            }
            setIsLoading(false)
        } else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    const handleChange = async (e) => {
        setInputText(e.target.value)
        let result = await apiHelper.getRequest(`channel/channels-list?cat_id=${location?.state?.id}&search=${e?.target?.value}`)
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            if(tabSwitch == 1 ){
                setChannelList(result?.data?.popularChannels)
            }
            else{
                setChannelList(result?.data?.channels)
            }
        }
    }

    async function addFavourites(item) {
        if (item.is_fav == 0) {
            item.is_fav = 1
        } else {
            item.is_fav = 0
        }
        let data = {
            "channel_id": item?.id,
            "type": "1"
        }
        forceUpdate(Math.random())
        let result = await apiHelper.postRequest("channel/add-favorites", data)
        if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {

        }
    }

    useEffect(() => {
        getAPI()
    }, [refresh, tabSwitch]);


    return (
        <div className="main-inner-content">
            {isLoading && <ReactLoader />}
            <div className="top-search-category">
                <section className="search-category">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <h3 className="fw-600">{location?.state?.catName}</h3>
                            </div>
                            <div className="col-lg-4 ">
                                <form>
                                    <div className="form-group search-filter-category ms-0 ms-lg-0 position-relative">
                                        <input onChange={handleChange} type="text" name="search" className="form-control" placeholder="Search" />
                                        <button type="button" className="icon-search-category">
                                            <img src={search} alt="" className="search-img" />
                                        </button>
                                        {/* <button type="button" className="filter-category"> <img
                                            src={filter} alt="" className="search-img" /></button> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="streaming py-6 response-padding">
                    <div className="container">
                        <div className="row">
                            <div className="stream-btn pt-3">
                                <button onClick={() => setTabSwitch(0)} className={tabSwitch == 0 ? "common-btn btn-blue" : "common-btn btn-white"}>All</button>
                                <button onClick={() => setTabSwitch(1)} className={tabSwitch == 1 ? "common-btn btn-blue" : "common-btn btn-white"}>Popular</button>
                                <button onClick={() => setTabSwitch(2)} className={tabSwitch == 2 ? "common-btn btn-blue" : "common-btn btn-white"}>Latest </button>
                            </div>
                        </div>
                    </div >
                </section>
            </div>
            {
                channelList.length > 0 &&
                <section className="category-list py-60">
                    <div className="container">
                        <div className="row">
                            {
                                channelList?.map((item) => {
                                    return (
                                        <div key={item?.id} className="col-12 col-sm-6 col-lg-6 col-xl-3">
                                            <div className='position-relative'>
                                                <div className="channels-box  mb-3 mb-lg-3 mb-xl-0"
                                                    style={{ cursor: "pointer" }} onClick={() => navigate(ROUTES.CHANNELS_DETAILS, { state: { item: item } })}>
                                                    <img src={item?.profile_pic} className="img-fluid channel-img" />
                                                    <h4 className="channel-text fw-600 my-2">{item?.name}</h4>
                                                    <p className="channel-para mb-0">{item?.category?.name}</p>

                                                </div>
                                                <div onClick={() => addFavourites(item)} className="like-icon">
                                                    {
                                                        item?.is_fav == 1 ?
                                                            <img src={favRed} className="img-fluid fill-icon" /> :
                                                            <img src={favGrey} className="img-fluid unfill-icon" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
            }


            {
                !isLoading &&
                <>
                    {
                        channelList?.length <= 0 &&
                        <div className='no-search-results'>
                            <img src={noChannelFound} />
                        </div>
                    }
                </>
            }


        </div>


    )
}

export default EducationCategory