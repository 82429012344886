import React, { useEffect, useState } from 'react';
import { four, homeBanner, mv, one, three, two } from '../../../../Constants/images';
import './dashHomeSlider.css';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import apiHelper from '../../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../../../Helper/config';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../Helper/route-helper';


const DashHomeSlider = (props) => {
    const [seriesList, setSeriesList] = useState([])
    const navigate = useNavigate()

    const [refresh, setRefresh] = useState(false)
    const [tags, setTags] = useState([])

    async function getAPI() {
        props?.setIsLoading(true)
        let result = await apiHelper.getRequest("series/series-list?channel_id=0")
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setSeriesList(result.data.data)
            props?.setIsLoading(false)
        } else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
           }
         }

    useEffect(() => {
        getAPI()
    }, [refresh]);

    return (
        <section>
            <div className='container'>
                <div className="epsiode-slider mt-2 episode-carousel-main">
                    <Carousel id="episode-owl"
                        autoPlay={false}
                        showArrows={true}
                        infiniteLoop={true}>
                        {
                            seriesList?.map((item, index) => {
                                return (
                                    <div  key={item.id} onClick={() => navigate(ROUTES.SERIES_DETAIL, { state: { id: item?.id } })}  className="epsoide-slider-wrapper position-relative">
                                        <img
                                            src={item?.thumbnail}
                                            className="img-fluid banner-video"
                                            alt=""
                                        />
                                        <div className='detail-banner-slide'>
                                            <div className="home-bottom-text">
                                                <h5 className="trending-text f16-size">Featured</h5>
                                                <h2 className="white d-inline">{item?.title}</h2> <span className="f18-size white ms-4">{item?.episodes_count} Episodes</span>
                                                    {
                                                    <div className=' gap-2 mt-3 button-home-banner'>                                            
                                                    <div className="align-items-center gap-2 mt-3 buttonflex">
                                                        {
                                                            item?.tags.split(",").map((item, index) => {
                                                                return (
                                                                    <div item={index} className="common-btn btn-white d-block"><p className='m-0'>{item}</p></div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </Carousel>
                </div>
            </div>
        </section>
    )
}

export default DashHomeSlider