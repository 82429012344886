import React, { useEffect, useState } from 'react'
import { challenge1, mv, play, playLight, playLightBtn, seeAllArrow } from '../../../../Constants/images'
import apiHelper from '../../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../../../Helper/config'
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../Helper/route-helper';
import './latestchallenge.css'
function LatestChallenge(props) {
   const [refresh, setRefresh] = useState(false)
   const [challengesList, setChallengesList] = useState([])
   const navigate = useNavigate()
   async function getAPI() {
      props?.setIsLoading(true)
      let url;
      if (props.category != 0) {
         url = "challenges/challenges-list?cat_id=" + props?.category + "&offset=0&limit=4&sort=z-a"
      } else {
         url = "challenges/challenges-list?offset=0&limit=4&sort=z-a"
      }
      let result = await apiHelper.getRequest(url)
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
         setChallengesList(result.data.data)
         props?.setIsLoading(false)
      } else if (result.code == 401) {
         let refreshToken = localStorage.getItem("refresh-token")
         let data = {
            "refresh_token": refreshToken,
            "scope": "refresh_token"
         }
         let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
         if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token)
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
            setRefresh(true)
         }

      }
   }

   useEffect(() => {
      getAPI()
   }, [props.category, refresh]);


   return (
      <div>
         {
            challengesList?.length > 0 &&
            <section className=" latest-challdata latest-challenge response-padding">
               <div className="container">
                  <div className="chanel-header">
                     <h3 className="fw-600 mb-0">Latest Challenges</h3>
                     <button onClick={() => navigate(ROUTES.CHALLENGES)} className="see-all fw-600 f18-size">See all <img src={seeAllArrow} alt="" className="ms-2" /></button>
                  </div>
                  <div className='row'>
                     {
                        challengesList?.map((challenges) => {
                           return (
                              <div key={challenges?.id} onClick={() =>{ navigate(ROUTES.CHALLENGES_DETAILS, { state: { item: challenges } })
                              localStorage.setItem("userChallengeId",challenges?.id)
                              }} className='col-12 col-md-6 col-lg-4 col-xl-3'>
                                 <div className='challenge-data mt-4 '>
                                    <div className='video-challenge position-relative'>
                                       <img src={challenges?.thumbnail} className="video-deatil-chall img-fluid" alt="" />
                                       <div className=" btn-play-video"><img src={playLightBtn} alt="" className='img-fluid play-icon-data' /></div>
                                       <div className="grid-data-detail">
                                          <div className='grid-circle-img'>
                                             <img src={challenges?.thumbnail} className="img-fluid grid-data-img " alt="" />
                                          </div>
                                          <div>
                                             <h6 className="white fw-500 mb-0">{challenges?.channel?.name}</h6>
                                             <p className=' mb-0'>{challenges?.category}</p>
                                          </div>
                                       </div>

                                    </div>
                                    <div className='challenge-video-title'>
                                       <p className='mb-0'>{challenges?.title}</p>
                                    </div>
                                 </div>

                              </div>
                           )

                        })
                     }
                  </div>
               </div>
            </section>
         }
      </div>
   )
}

export default LatestChallenge