import React, { useState } from 'react'
import ReactLoader from '../../ControlComponents/react-loader'
import Bites from '../DashboardHome/Components//Bites/Bites'
import LatestChallenge from '../DashboardHome/Components//LatestChallenge/LatestChallenge'
import NewTalent from '../DashboardHome/Components//NewTalent/NewTalent'
import Interactives from '../DashboardHome/Components/Intractives/Interactives'
import Categories from './Components/Categories/Categories'
import Channels from './Components/Channels/Channels'
import ComingSoon from './Components/ComingSoon/ComingSoon'
import DashHomeSlider from './Components/DashHomeSlider/DashHomeSlider'
import './dashboardHome.css'


const DashboardHome = () => {
    const [category, setCategory] = useState(0);
    const [isLoading, setIsLoading] = useState(false)


    return (

        <div className="main-inner-content">
            {isLoading && <ReactLoader />}
            <DashHomeSlider isLoading={isLoading} setIsLoading={setIsLoading} />
            <Categories isLoading={isLoading} setIsLoading={setIsLoading} setCategory={setCategory} category={category} />
            <Channels isLoading={isLoading} setIsLoading={setIsLoading} category={category} />
            <Bites isLoading={isLoading} setIsLoading={setIsLoading} category={category} />
            <NewTalent isLoading={isLoading} setIsLoading={setIsLoading} category={category} />
            <LatestChallenge isLoading={isLoading} setIsLoading={setIsLoading} category={category} />
            <Interactives isLoading={isLoading} setIsLoading={setIsLoading} category={category} />
            <ComingSoon category={category} />
        </div>

    )
}

export default DashboardHome