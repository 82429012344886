import React, { useEffect, useState } from 'react'
import ShopSeries from './shopSeries'
import ShopLives from './shopLives'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../../src/Helper/config"
import UpcomingLivesShop from './upcomingLive'
import Images from './images'
import Video from './video'
import Audio from './audio'
function UserShopDetail() {

    const [tabSwitch, setTabSwitch] = useState(0)
    const [detailList, setDetailList] = useState([])
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [channelName, setChannelName] = useState()
    const [channelImg, setChannelImg] = useState()
    const [channelId, setChannelId] = useState()
    const [audiosList, setAudiosList] = useState([])
    const [imagesList, setImagesList] = useState([])
    const [videosList, setVideosList] = useState([])
    async function getShopDetailApi() {
        setLoading(true)
        try {
            const result = await apiHelper.getRequest(`shop/channel-detail?type=${tabSwitch == 0 ? "1" : "2"}&creatorId=${1}`)
            if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
                setDetailList([])
            }
            else if (result.code == 401) {
                let refreshToken = localStorage.getItem("refresh-token")
                let data = {
                    "refresh_token": refreshToken,
                    "scope": "refresh_token"
                }
                let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
                if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                    localStorage.setItem("token", refreshTokenResult.data.token)
                    localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                    setRefresh(true)
                }
            }
        } catch (error) {
        }
        setLoading(false)
    }


    async function getImagesAPI() {
        setLoading(true)
        let result = await apiHelper.getRequest("shop/get-shop-media?shopType=image")
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setImagesList(result.data)
            setLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    async function getAudiosAPI() {
        setLoading(true)
        let result = await apiHelper.getRequest("shop/get-shop-media?shopType=audio")
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setAudiosList(result.data)
            setLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    async function getVideosAPI() {
        setLoading(true)
        let result = await apiHelper.getRequest("shop/get-shop-media?shopType=video")
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setVideosList(result.data)
            setLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getAudiosAPI()
        getImagesAPI()
        getVideosAPI()
        getShopDetailApi()
    }, [refresh, tabSwitch])

    return (
        
        <section className="streaming py-6 response-padding ">
            {
                tabSwitch == 0 ?
                    <ShopSeries channelImg={channelImg} channelId={channelId} channelName={channelName} setTabSwitch={setTabSwitch} detailList={detailList} loading={loading} setLoading={setLoading} getDetailApi={getShopDetailApi} check="shop" /> :
                    tabSwitch == 1 ?
                        <ShopLives setLoading={setLoading} setTabSwitch={setTabSwitch} detailList={detailList} loading={loading} check="shop" /> :
                        tabSwitch == 2 ?
                            <UpcomingLivesShop setTabSwitch={setTabSwitch} /> :
                            tabSwitch == 3 ?
                                <Images setTabSwitch={setTabSwitch} tabSwitch={tabSwitch}
                                imagesList={imagesList} 
                                /> :
                                tabSwitch === 4 ?
                                    <Audio setTabSwitch={setTabSwitch} tabSwitch={tabSwitch}
                                     audiosList={audiosList}
                                    /> :
                                    <Video setTabSwitch={setTabSwitch} tabSwitch={tabSwitch}
                                    videosList={videosList} 
                                    />
            }
        </section>
    )
}

export default UserShopDetail