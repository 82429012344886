import React, { useEffect, useRef, useState } from 'react'
import Episode from './Components/Episode/Episode'
import MoreMuvi from './Components/MoreMuvi/MoreMuvi'
import Trailor from './Components/Trailor/Trailor'
import VideoBanner from './Components/VideoBanner/VideoBanner'

import { useLocation } from 'react-router-dom'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from '../../Helper/config'

import ReactLoader from '../../ControlComponents/react-loader'
import './frame.css'
import UserSeriesChat from './userSeriesChat'
import BiteSeriesUser from './biteSeriesUser'

function Frame() {
  const location = useLocation()
  const ref = useRef()
  const [seriesDetail, setSeriesDetail] = useState(null)
  const [seriesDetailNew, setSeriesDetailNew] = useState()
  const [id, setId] = useState(location?.state?.id)
  const [chatOpen, setChatOpen] = useState(false)
  const [creatorName, setCreatorName] = useState(null)
  const [creatorImg, setCreatorImg] = useState(null)
  const [moreSeries, setMoreSeries] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [_, forceUpdate] = useState(0)
  async function getMyDetailsAPI() {
    let result = await apiHelper.getRequest("auth/get-my-detail")
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      setCreatorName(result?.data?.name);
      setCreatorImg(result.data?.profile_pic)
    } else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult?.data?.token)
        localStorage.setItem("refresh-token", refreshTokenResult?.data?.refresh_token)
        setRefresh(true)
      }
    }
  }


  async function getDetailsAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest("series/series-detail?id=" + id)
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      setSeriesDetail(result.data.series)
      setMoreSeries(result.data.moreSeries)
      setSeriesDetailNew(result.data)
      const trimmedName = result?.data?.series?.title?.replace(/\s/g, '');;
      const chatId = `${result?.data?.series?.id}${trimmedName}`;
      localStorage.setItem("userChatId", chatId)
      setChatOpen(true)
      setIsLoading(false)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  useEffect(() => {
    getDetailsAPI()
    getMyDetailsAPI()
  }, [refresh, id])

  function modifyDuration(duration) {
    const durationParts = duration?.split(':');
    if (durationParts && durationParts[0] === '00') {
        return durationParts.slice(1)?.join(':');
    }
    return duration;
}

async function addFavourites(item) {
  if (item.is_fav == 0) {
     item.is_fav = 1
  } else {
     item.is_fav = 0
  }
  let data = {
     "series_id": item?.id,
     "type": "2"
  }
  forceUpdate(Math.random())
  let result = await apiHelper.postRequest("channel/add-favorites", data)
  if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
  }
}

  return (
    <div ref={ref} className="main-inner-content">
      {isLoading && <ReactLoader />}
      <VideoBanner seriesDetail={seriesDetail} addFavourites={addFavourites} />
      <Episode seriesDetail={seriesDetail} modifyDuration={modifyDuration} chatOpen={chatOpen} creatorName={creatorName} creatorImg={creatorImg} />
      <Trailor seriesDetail={seriesDetail}  modifyDuration={modifyDuration}/>
      <BiteSeriesUser seriesDetail={seriesDetail}/>
      <MoreMuvi setId={setId} seriesNew={seriesDetail} moreSeries={moreSeries} />
      <UserSeriesChat chatOpen={chatOpen} creatorName={creatorName} creatorImg={creatorImg} />
    </div>
  )
}

export default Frame;