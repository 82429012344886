import React, { useState } from "react";
import "./socialFeeds.css";
import {
    Send_feed, dotsVertical, dotshorizontal, dummy, heartfill, postlike, message, heartlike, angry, sad, happy, shock, galleryImg
} from "../../Constants/images";
import ShareModalCreator from "./shareModalCreator";
import PostModal from "./postModal";
import DetailModal from "./detailModal";

const SocialFeeds = () => {
    const [shareModal, setShareModal] = useState(false);
    const [postModal, setPostModal] = useState(false);
    const [detailModal, setDetailModal] = useState(false);

    const initialFeeds = [
        { id: 1, user: 'John Smith', time: '15 hours ago', likes: 125, comments: 124, shares: 25, description: 'Sorem ipsum dolor sit amet, consectetur adipiscing elit.', currentReaction: heartfill, showReaction: false },
        { id: 2, user: 'Jane Doe', time: '10 hours ago', likes: 98, comments: 76, shares: 12, description: 'Etiam eu turpis molestie, dictum est a, mattis tellus.', currentReaction: heartfill, showReaction: false },
        { id: 3, user: 'Johnny', time: '13 hours ago', likes: 78, comments: 15, shares: 6, description: 'Etiam eu turpis molestie, dictum est a, mattis tellus.', currentReaction: heartfill, showReaction: false },
        { id: 4, user: 'David', time: '20 hours ago', likes: 94, comments: 36, shares: 14, description: 'Etiam eu turpis molestie, dictum est a, mattis tellus.', currentReaction: heartfill, showReaction: false },
    ];

    const [feeds, setFeeds] = useState(initialFeeds);

    const handleReactionClick = (id, reaction) => {
        setFeeds(feeds.map(feed =>
            feed.id === id ? { ...feed, currentReaction: feed.currentReaction === reaction ? heartfill : reaction, showReaction: false } : feed
        ));
    };

    const toggleReaction = (id) => {
        setFeeds(feeds.map(feed =>
            feed.id === id ? { ...feed, showReaction: !feed.showReaction } : feed
        ));
    };

    const handleCurrentReactionClick = (id) => {
        setFeeds(feeds.map(feed =>
            feed.id === id ? { ...feed, currentReaction: heartfill } : feed
        ));
    };

    return (
        <>
            <div>
                <div className="main-inner-content">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="center-content">
                                <div className="center-container py-4">
                                    <div className="social_feed_main">
                                        <div className="feed_input mb-4" >
                                            <div className="d-flex"  onClick={()=>setPostModal(true)} style={{cursor:"pointer"}}>
                                                <div className="feed-user-top">
                                                    <img src={dummy} className="userimg" alt="User" />
                                                </div>
                                                <div className="feed-img position-relative"> 
                                                    <input readOnly className="post-input" placeholder="What's on your mind?" />
                                                    <button className="post-btn">Post</button>
                                                    <div className="feed-upload" > <span className="upload-img"><img src={galleryImg} /></span> Image/Video</div>
                                                </div>
                                            </div>
                                            <div>
                                            </div>
                                        </div>

                                        {feeds.map(feed => (
                                            <div key={feed.id} className="feed_details mb-4">
                                                <div className="feed-user">
                                                    <a href="#" className="text-decoration-none text-black">
                                                        <img src={dummy} className="userimg" alt="User" />
                                                    </a>
                                                    <div>
                                                        <a href="#" className="text-decoration-none text-black">
                                                            <h4 className="fw-600 mb-2 lh-1">{feed.user}</h4>
                                                        </a>
                                                        <p className="fw-500 mb-0 side_text lh-1">{feed.time}</p>
                                                    </div>
                                                    <div className="post-options">
                                                        <img src={dotshorizontal} className="btn pl-2 pr-0 py-2" alt="Options" />
                                                    </div>
                                                </div>
                                                <div className="feed_image position-relative" style={{ cursor: "pointer" }}>
                                                    <img onClick={() => setDetailModal(true)} src={dummy} className="feed_inner_images" alt="Feed" />
                                                    {feed.showReaction &&
                                                        <div style={{ width: 270, height: 45, backgroundColor: '#fff', position: 'absolute', bottom: '-5px', left: '10px', borderRadius: '50px' }}>
                                                            <div className="d-flex py-1 px-2 gap-1">
                                                                <img
                                                                    src={postlike}
                                                                    className={`feed_inner_images reaction-icon-img ${feed.currentReaction === postlike ? 'active-reaction' : ''}`}
                                                                    onClick={() => handleReactionClick(feed.id, postlike)}
                                                                    alt="Like"
                                                                />
                                                                <img
                                                                    src={heartlike}
                                                                    className={`feed_inner_images reaction-icon-img ${feed.currentReaction === heartlike ? 'active-reaction' : ''}`}
                                                                    onClick={() => handleReactionClick(feed.id, heartlike)}
                                                                    alt="Love"
                                                                />
                                                                <img
                                                                    src={angry}
                                                                    className={`feed_inner_images reaction-icon-img ${feed.currentReaction === angry ? 'active-reaction' : ''}`}
                                                                    onClick={() => handleReactionClick(feed.id, angry)}
                                                                    alt="Angry"
                                                                />
                                                                <img
                                                                    src={shock}
                                                                    className={`feed_inner_images reaction-icon-img ${feed.currentReaction === shock ? 'active-reaction' : ''}`}
                                                                    onClick={() => handleReactionClick(feed.id, shock)}
                                                                    alt="Shock"
                                                                />
                                                                <img
                                                                    src={happy}
                                                                    className={`feed_inner_images reaction-icon-img ${feed.currentReaction === happy ? 'active-reaction' : ''}`}
                                                                    onClick={() => handleReactionClick(feed.id, happy)}
                                                                    alt="Happy"
                                                                />
                                                                <img
                                                                    src={sad}
                                                                    className={`feed_inner_images reaction-icon-img ${feed.currentReaction === sad ? 'active-reaction' : ''}`}
                                                                    onClick={() => handleReactionClick(feed.id, sad)}
                                                                    alt="Sad"
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="social_icon_feed">
                                                    <div onClick={() => feed.currentReaction === heartfill ? toggleReaction(feed.id) : handleCurrentReactionClick(feed.id)} className="like_feed d-flex align-items-center gap-2">
                                                        <img src={feed.currentReaction} alt="Current Reaction" />
                                                        <h5 className="total_like mb-0">{feed.likes}</h5>
                                                    </div>
                                                    <div className="like_feed d-flex align-items-center gap-2">
                                                        <img src={message} alt="Comment" />
                                                        <h5 className="total_like mb-0">{feed.comments}</h5>
                                                    </div>
                                                    <div className="like_feed d-flex align-items-center gap-2">
                                                        <img src={Send_feed} style={{ cursor: "pointer" }} onClick={() => setShareModal(true)} alt="Share" />
                                                        <h5 className="total_like mb-0">{feed.shares}</h5>
                                                    </div>
                                                </div>
                                                <div className="totallikecomment px-4">
                                                    <p> Liked by <span className="fw-600"> {feed.user} </span> and <span className="fw-600"> 123 others</span></p>
                                                </div>
                                                <div className="description_feed px-4">
                                                    <p className="mb-0 side-text">{feed.description}<span className="fw-600 blue-text"> More...</span></p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-4 mt-md-0">
                            <div className="right-aside following_tab">
                                <div className="following_heading">
                                    <h5 className="mb-0 fw-600">Following</h5>
                                </div>
                                <div>
                                    <ul className="following_listing ps-0">
                                        <li>
                                            <div className="following_main">
                                                <div className="following_profile">
                                                    <img src={dummy} alt="Profile" />
                                                </div>
                                                <div className="following_text">
                                                    <p className="mb-0 blue-text fw-500 f16-size">John Smith</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {shareModal && <ShareModalCreator setShareModal={setShareModal} />}
            {postModal && <PostModal setPostModal={setPostModal} />}
            {detailModal && <DetailModal setDetailModal={setDetailModal} />}
        </>
    );
};

export default SocialFeeds;
