import React, { useState } from 'react'
import {  playLightBtn, seeAllArrow } from '../../Constants/images'
import './seriesDetail.css'
import PlayBiteModal from '../Bites/playBite'
import ROUTES from '../../Helper/route-helper'
import { useNavigate } from 'react-router-dom'

function BitesSeries({ seriesDetail, creatorName, creatorImg }) {
    const [videoUrl, setVideoUrl] = useState("")
    const [biteModal, setBiteModal] = useState("")
    const [id, setId] = useState(null)
    const [item, setItem] = useState("")
    const [showBite, setShowBite] = useState(false)
    const [currentBiteIndex, setCurrentBiteIndex] = useState("")
    const handleBitePlay = (item) => {
        setVideoUrl(item.video)
        setShowBite(!showBite);
        // setCurrentBiteIndex(id);
        setId(item.id)
        setItem(item)
    }
    const navigate = useNavigate()
    
    return (
        <div>
            <section className=" latest-challdata latest-challenge response-padding">
                <div className="container">
                    {
                        seriesDetail?.reels?.length > 0 && <div className="chanel-header">
                            <h3 className="fw-600 mb-0">Bites</h3>
                            <button
                                onClick={() => navigate(ROUTES.CREATER_BITES)}
                                className="see-all fw-600 f18-size">See all <img src={seeAllArrow} alt="" className="ms-2" /></button>
                        </div>
                    }

                    <div className='row'>
                        {
                            seriesDetail?.reels?.map((item) => {
                                return (
                                    <div
                                        // key={challenges?.id} onClick={() => navigate(ROUTES.CHALLENGES_DETAILS, { state: { item: challenges } })} 
                                        className='col-12 col-md-6 col-lg-4 col-xl-3'>
                                        <div className='bites-seriesdetails mt-4 ' onClick={() => handleBitePlay(item)}>
                                            <div className='video-challenge position-relative'>
                                                <img src={item.thumbnail} className="video-deatil-chall img-fluid" alt="" />
                                                <div className=" btn-play-video"><img src={playLightBtn} alt="" className='img-fluid play-icon-data' /></div>
                                                <div class="grid-data-detail"><div class="grid-circle-img"><img src={creatorImg} class="img-fluid grid-data-img " alt="" /></div><div><h6 class="white fw-500 mb-0">{item.title} </h6><p class=" mb-0">{creatorName}</p></div></div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            {
                showBite &&
                <PlayBiteModal currentBiteIndex={currentBiteIndex} setCurrentBiteIndex={setCurrentBiteIndex} showBite={showBite} setShowBite={setShowBite} videoUrl={videoUrl} id={id} item={item} />
            }
        </div>

    )
}
export default BitesSeries