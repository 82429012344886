
const DEVELOPMENT_CONFIG = {
  base_url: process.env.REACT_APP_BASE_URL,
  statusCode: 200,
  unAuthorized: 401,
  noPermission: 403,
  internalServer: 500,
  internalServerError: 501,
  TOKEN: "token",
  OTP_TOKEN: "otpToken",
  REFRESH_TOKEN: "refeshtoken",
  LOGIN_TIME: "loginTime",
  TOKEN_EXPIRETIME: "accessTokenExpireTime",
  REFRESHTOKEN_EXPIRETIME: "refeshTokenExpireTime",
};

export default process.env.NODE_ENV === "development" ? DEVELOPMENT_CONFIG : DEVELOPMENT_CONFIG;
export const S3_CREDS = {
  AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  AWS_DEFAULT_REGION: process.env.REACT_APP_AWS_DEFAULT_REGION,
  AWS_BUCKET: process.env.REACT_APP_AWS_BUCKET,
}

export const AGORA_CRED = {
  APP_ID: process.env.REACT_APP_APP_ID,
  APP_KEY: process.env.REACT_APP_APP_KEY
}

export const FIREBASE_CRED = {
  API_KEY: process.env.REACT_APP_API_KEY,
  AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN,
  PROJECT_ID: process.env.REACT_APP_PROJECT_ID,
  STORAGE_BUCKET: process.env.REACT_APP_STORAGE_BUCKET,
  MESSAGING_SENDER_ID: process.env.REACT_APP_MESSAGING_SENDER_ID,
  APP_ID: process.env.REACT_APP_CHAT_APP_ID,
  MEASUREMENT_ID: process.env.REACT_APP_MEASUREMENT_ID
}

