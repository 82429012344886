import React, { useState } from 'react'
import { gallery, modalCross, plusBtn, plusgray, videoPlayButton } from '../../Constants/images'
import DatePickerValue from '../component/date-picker'
import uploadToS3 from '../component/fileUpload'
import ProgressBarComponent from '../../ControlComponents/progressBar'
import ERR_MESSAGE from '../../Helper/error-helper'
import DEVELOPMENT_CONFIG from "../../../src/Helper/config"
import apiHelper from '../../Helper/api-helper'
import { NotificationManager } from 'react-notifications'
import dayjs from 'dayjs'

const ScheduledModal = ({ setSheduledModal, setIsLoading, item, check, getLivesAPI }) => {
  const [progressLoading, setProgressLoading] = useState(false)
  const [errors, setErrors] = useState("")
  const [progress, setProgress] = useState(0);
  const [thumbnail, setThumbnail] = useState(item != undefined ? item.thumbnail : "")
  const [fields, setFields] = useState({
    title: item != undefined ? item.name : "",
    date: item != undefined ? getDateFromDateTimestring(item.date_time) : "",
    time: item != undefined ? formatTimeTo24Hour(item.date_time) : "",
    description: item != undefined ? item.description : "",
  });

  function getDateFromDateTimestring(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, '0')
    const day = String(dateTime.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function formatTimeTo24Hour(dateString) {
    const date = dayjs(dateString);
    const formattedTime = date.format("HH:mm");
    return formattedTime;
  }

  const handleChange = (e) => {
    setErrors("")
    setFields({
      ...fields,
      [e.target.name]: e.target.value
    })
  }
  const setDate = (newDate) => {
    setFields((prevFields) => ({
      ...prevFields,
      date: newDate,
    }));
  };

  const fileChange = (e) => {
    setErrors("")
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file) {
      if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
        const formData = new FormData();
        formData.append("image", file);
        fileUpload(file)
      }
    }
  };

  const fileUpload = async (file, thumb) => {
    const state = { videoLocation: null, thumbnailLocation: null };
    setProgressLoading(true)
    try {
      if (file) {
        await uploadToS3(thumb, file, state, setProgress);
        if (state.videoLocation) {
          setProgressLoading(false)
          setThumbnail(state.videoLocation)
        }
      }
    } catch (error) {
    }
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    const { title, date, time, description } = fields;
    if (!title || title.trim().length === 0) {
      formIsValid = false;
      errors["title"] = ERR_MESSAGE.TITLE_EMPTY
    }
    if (!date || date?.trim().length === 0) {
      formIsValid = false;
      errors["date"] = ERR_MESSAGE.EMPTY_DATE
    }
    else {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const selectedDate = new Date(date);
      selectedDate.setHours(0, 0, 0, 0);

      if (selectedDate < currentDate) {
        formIsValid = false;
        errors["date"] = ERR_MESSAGE.INVALID_DATE;
      }
    }
    if (!time || time.trim().length === 0) {
      formIsValid = false;
      errors["time"] = ERR_MESSAGE.EMPTY_TIME
    }
    if (!thumbnail) {
      formIsValid = false;
      errors["thumbnail"] = ERR_MESSAGE.THUMBNAIL_EMPTY
    }
    if (!description) {
      formIsValid = false;
      errors["description"] = ERR_MESSAGE.DESCRIPTION_EMPTY
    }
    setErrors(errors);
    return formIsValid;
  };

  async function handleSubmit(e) {
    e.preventDefault()
    if (handleValidation()) {
      let time = fields.time + ':00';
      let data = {
        "thumbnail": thumbnail,
        "name": fields.title,
        "date_time": `${fields.date} ${time}`,
        "description": fields.description,
        "status": parseInt("2")
      }
      setIsLoading(true)
      let result = await apiHelper.postService("creator/create-schedules", data)
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        setIsLoading(false)
        NotificationManager.success(result.message)
        setSheduledModal(false)
        getLivesAPI()
      }
      else {
        setIsLoading(false)
        NotificationManager.error(result.message)
      }
    }
  }

  async function handleEdit(e) {
    e.preventDefault();

    if (handleValidation()) {
      let time = fields.time + ':00';
      let data = {
        "id": item.id,
        "thumbnail": thumbnail,
        "name": fields.title,
        "date_time": `${fields.date} ${time}`,
        "description": fields.description,

      };
      setIsLoading(true);
      let result = await apiHelper.postService("creator/edit-schedules", data);
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        setIsLoading(false);
        NotificationManager.success(result.message);
        setSheduledModal(false);
        getLivesAPI();
      } else {
        setIsLoading(false);
        NotificationManager.error(result.message);
      }
    }
  }

  return (
    <div className="modal fade show modal-react" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
      <div className='loader'>
      </div>
      {progressLoading && <>
        <ProgressBarComponent progress={progress} />
      </>}
      <div className="modal-dialog modal-dialog-centered modal-schedule-width">
        <div className="modal-content modal-radius  border-0">
          <div className="modal-header forgot-header align-items-center pt-4">
            <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Edit {check == "past" ? "Recorded live":"Schedule"}</h3>
            <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img onClick={() => setSheduledModal(false)} style={{ cursor: "pointer" }} src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
          </div>
          <div className="modal-body  verify-modal-box px-2 px-sm-5">
            <form>
              <div className=''>
                <label className='fw-500 f18-size text-black pb-2'>Thumbnail</label>
                <div className="mb-3 schedule-thumb">
                  {/* <div className="video-upload video-select position-relative">
                    <video id='video' controls ></video>
                    <img src={videoPlayButton} alt="" className="img-fluid playbtn-image" />
                  </div>  */}
                  <div className="video-upload video-schedule position-relative">
                    {
                      thumbnail && <div className={thumbnail ? " episode-preview" : ""}><img src={thumbnail ? thumbnail : ""}></img></div>

                    }
                    {
                      thumbnail ? "" : <div className='d-flex m-auto justify-content-center align-items-center h-100 episode-preview-after'>
                        <div className=''>
                          <div className='text-center'>
                            <img src={gallery}></img>
                          </div>
                          <div className="d-flex m-auto justify-content-center align-items-center h-100">
                            <img src={plusgray} alt="" className="img-fluid me-1" />
                            <span className="Upload-text fw-500 create-text border-bottom ">Add Thumbnail</span>
                          </div>
                        </div>
                      </div>
                    }

                    <input type="file" style={{ cursor: "pointer" }} onChange={fileChange} accept=".jpg, .jpeg, .png" className="video-file-schedule" />
                    {errors.thumbnail && <span className="err_msg">{errors.thumbnail}</span>}
                  </div>
                </div>
                <div className='mb-3'>
                  <label className='fw-500 f18-size text-black pb-2'>Title</label>
                  <input type='text' placeholder='Enter here' value={fields.title} name='title' onChange={handleChange} className='border-edit  w-100'></input>
                  {errors.title && <span className="err_msg">{errors.title}</span>}

                </div>
                <div className='mb-3'>
                  <label className='fw-500 f18-size text-black pb-2'>Description</label>
                  <textarea class=" text-summary place-grey d-block w-100" value={fields.description} onChange={handleChange} placeholder="Enter Here" rows="6" name="description"></textarea>
                  {errors.description && <span className="err_msg">{errors.description}</span>}
                </div>
                <div className='row mb-3'>
                  <div className='col-md-6'>
                    <label className='fw-500 f18-size text-black pb-2'>Date</label>
                    {/* <input type='date' className='border-edit  w-100'></input> */}
                    <DatePickerValue
                      setErrors={setErrors}
                      value={fields.date}
                      setFn={setDate}
                      item={item}
                      check={check}
                      className="border-edit w-100"
                    />
                    {errors.date && <span className="err_msg">{errors.date}</span>}

                  </div>
                  <div className='col-md-6'>
                    <label className='fw-500 f18-size text-black pb-2'>Time</label>
                    <input type='time' value={fields.time} name='time' onChange={handleChange}
                      disabled={item != undefined && check == "past"} 
                      className='border-edit  w-100'></input>
                    {errors.time && <span className="err_msg">{errors.time}</span>}
                  </div>
                </div>
              </div>
              {
                item != undefined ? <div className=" my-4" onClick={handleEdit}>
                  <input type='submit' value="Submit" className="common-btn w-100" />
                </div> : <div className=" my-4" onClick={handleSubmit}>
                  <input type='submit' value="Save" className="common-btn w-100" />
                </div>
              }

            </form>
          </div>
        </div>
      </div>
    </div>

  )
}

export default ScheduledModal
