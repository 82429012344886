import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { favRed, favouriteLight, favouritesDark, noSeries, playLightBtn, profile_image_placeholder, search } from '../../Constants/images';
import ReactLoader from '../../ControlComponents/react-loader';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import ROUTES from '../../Helper/route-helper';
import AllChallengesFilter from '../AllChallenges/Components/AllChallengesFilter/AllChallengesFilter';
import './channelDetails.css';

const ChannelDetails = () => {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false)
  const location = useLocation()
  const [_, forceUpdate] = useState(0)
  const [featuredList, setFeaturedList] = useState([])
  const [seriesList, setSeriesList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const searchParams = new URLSearchParams(location.search);
  const channelDetail = searchParams.get('cd');
  console.log(location.state.item, "gggg")

  function getChannelName(channelName) {
    let name;
    switch (channelName) {
      case "favChannel":
        name = location.state.item.name;
        break;
      case "seriesChannel":
        name = location.state.item.name;
        break;
      case "shopChannel":
        name = location.state.item.channelDetail.name;
        break;
      case "moreMovie":
        name = location.state.item.channelDetail.name;
        break;
      case "latestChannel":
        name = location.state.item.name;
        break;
      case "allChannels":
        name = location.state.item.name;
        break;
      default:
        name = "category";
    }
    return name;
  }

  function getChannelCategory(channelName) {
    let category;
    switch (channelName) {
      case "favChannel":
        category = "category"
        break;
      case "shopChannel":
        category = location.state.item.category.name;
        break;
      case "moreMovie":
        category = location.state.item.category.name;
        break;
      case "latestChannel":
        category = location.state.item.category.name;
        break;
      case "allChannels":
        category = location.state.item.category.name;
        break;
      default:
        category = "name";
    }
    return category;
  }

  function getChannelImage(channelName) {
    let image;
    switch (channelName) {
      case "favChannel":
        image = location.state.item.profile_pic;
        break;
      case "seriesChannel":
        image = location.state.item.profile_pic;
        break;
      case "shopChannel":
        image = location.state.item.channelDetail.profile_pic;
        break;
      case "moreMovie":
        image = location.state.item.channelDetail.profile_pic;
        break;
      case "latestChannel":
        image = location.state.item.logo_url;
        break;
      case "allChannels":
        image = location.state.item.logo_url;
        break;
      default:
        image = profile_image_placeholder;
    }
    if (image == null) {
      image = profile_image_placeholder;
    }
    return image;
  }

  function getChannelId(channelName) {
    let id;
    switch (channelName) {
      case "favChannel":
        id = location.state.item.id;
        break;
      case "seriesChannel":
        id = location.state.item.id;
        break;
      case "shopChannel":
        id = location.state.item.creator_id;
        break;
      case "moreMovie":
        id = location.state.item.creator_id;
        break;
      case "latestChannel":
        id = location.state.item.creator_id;
        break;
      case "allChannels":
        id = location.state.item.creator_id;
        break;
      default:
        id = 0;
    }
    return id;
  }


  async function getAPI() {
    setIsLoading(true)
    let resultFeatured = await apiHelper.getRequest("series/series-list?channel_id=0")
    if (resultFeatured.code === DEVELOPMENT_CONFIG.statusCode) {
      setFeaturedList(resultFeatured.data.data)
      let result = await apiHelper.getRequest("series/series-list?channel_id=" + getChannelId(channelDetail))
      setSeriesList(result.data.data)
      // const url = new URL(window.location.href);
      // url.searchParams.delete("cd");
      // window.history.replaceState({}, '', url);
      setIsLoading(false)
    }
    else if (resultFeatured.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    } else {
      setIsLoading(false)
    }
  }


  async function addFavourites(item) {
    if (item.is_fav == 0) {
      item.is_fav = 1
    } else {
      item.is_fav = 0
    }
    let data = {
      "series_id": item?.id,
      "type": "2"
    }
    forceUpdate(Math.random())
    let result = await apiHelper.postRequest("channel/add-favorites", data)
    if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
    }
  }

  const handleChange = async (e) => {
    let result = await apiHelper.getRequest("series/series-list?channel_id=" + location.state?.item?.id + "&search=" + e.target.value)
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setSeriesList(result.data.data)
    }
  }

  useEffect(() => {
    getAPI()
  }, [refresh]);

  return (

    <div className="main-inner-content">
      <div className='container'>
        <div className="row">
          <div className="col-12 pt-3 pb-0 channel-detal-fav-icon">
            <h3 className="fw-600">Channel Detail</h3>
            <div className='fav-filter-top '>
              <button onClick={() => addFavourites(location?.state?.item.channel)} type="button" className="btn btn-filter">
                {
                  location?.state.item?.is_fav == 1 ?
                    <img src={favRed} className='pe-2' /> :
                    <img src={favouriteLight} className='like-light pe-2' />
                }Favourite</button>
            </div>
          </div>
          <div className="col-12 text-center">
            <div className="challenge-title challenge-pic-cus">
              <img
                src={getChannelImage(channelDetail)}
                className="img-fluid challenge-img"
              />
            </div>
          </div>
        </div>
        {isLoading &&
          <ReactLoader />
        }
        <section className="challenges channel-detail-custom position-relative">
          <div className="container">
            <div className='fav-filter fav-filter-sm'>
              {/* <button onClick={() => addFavourites(location?.state?.item)} type="button" className="btn btn-filter">
                {
                  location?.state.item?.is_fav == 1 ?
                    <img src={favRed} className='pe-2' /> :
                    <img src={favouriteLight} className='like-light pe-2' />
                }Favourite</button> */}
            </div>
            <div className="row justify-content-center">
              <div className="col-12 text-center py-5 pb-0 pb-lg-5 mt-4">
                <div className="challenge-head">
                  <h3 className="fw-600">{getChannelName(channelDetail)}</h3>
                  {/* <p className="py-2">
                    {getChannelCategory(channelDetail)}
                  </p> */}
                </div>
              </div>
              <div className="col-12 py-3">
                <section className="bite response-padding features-cnhl-detail">
                  <div className="container">
                    <div className="chanel-header">
                      <h3 className="fw-600 mb-0">Featured</h3>
                    </div>
                    <div className="chanel-wrapper mt-2">
                      <div className="row gy-4">
                        {
                          featuredList?.map((item) => {
                            return (
                              <div key={item?.id} className="col-lg-4 col-md-6 response-col position-relative">
                                <div className="chanel-content">
                                  <div style={{ cursor: "pointer" }} onClick={() => navigate(ROUTES.SERIES_DETAIL, { state: { id: item?.id } })} className="bite-relative">
                                    <img
                                      src={item?.thumbnail}
                                      className="bite-img reduce-height"
                                      alt=""
                                    />
                                  </div>

                                  <div style={{ cursor: "pointer" }} onClick={() => navigate(ROUTES.SERIES_DETAIL, { state: { id: item?.id } })} className="bite-wrapper">
                                    <h4>{item?.title}</h4>
                                    <div
                                      className="chanel-text mt-2  channel-data-like" >
                                      <div>
                                        <p className="m-0 mb-1 py-1">{item?.episodes_count} Episodes</p>
                                        <p className="m-0 py-1">{item?.year}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div onClick={() => addFavourites(item)} className="featured-like-btn">
                                  {
                                    item?.is_fav == 1 ?
                                      <img src={favRed} /> :
                                      <img src={favouriteLight} className='like-light' />
                                  }
                                </div>
                              </div>
                            )
                          })
                        }

                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <div className="col-12 py-2 py-lg-5">
                <section className="channel-detail">
                  <div className="container">
                    <div className='row'>
                      <div className="col-lg-8 pt-3 pb-4 d-flex align-items-center justify-content-between">
                        <h3 className="latest-vid-head fw-600">All Series</h3>
                        {/* <button type="button" className="btn btn-filter" data-bs-toggle="modal" data-bs-target="#Filter"><img src={whiteFilter} alt="" className='pe-1' /> Filter</button> */}
                      </div>
                      <div className="col-lg-4 px-0  ">
                        <form>
                          <div className="form-group search-filter-category ms-0 ms-lg-0 position-relative">
                            <input onChange={handleChange} type="text" name="search" className="form-control" placeholder="Search" />
                            <button type="button" className="icon-search-category">
                              <img src={search} className="search-img" />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div className="row mt-5 mt-lg-0">

                      {
                        seriesList?.map((series) => {
                          return (
                            <div key={series?.id} className="col-12 col-sm-6 col-lg-4 col-xl-4 pb-4">
                              <div className="latest-video mx-auto ">
                                <div className='position-relative'>
                                  <div
                                    className="latest-video-content  pt-2 pt-sm-3 px-2 px-sm-3 "
                                    style={{ cursor: "pointer" }} onClick={() => navigate(ROUTES.SERIES_DETAIL, { state: { id: series?.id } })}>
                                    <img
                                      src={series?.thumbnail}
                                      className="img-fluid latest-vid-img"
                                    />

                                  </div>
                                  <div onClick={() => addFavourites(series)} className="series-like-btn ">
                                    {
                                      series?.is_fav == 1 ?
                                        <img src={favRed} /> :
                                        <img src={favouritesDark} className='like-light' />
                                    }
                                  </div>
                                </div>
                                <div className="latest-video-cus px-3 py-3">
                                  <h4 className="fw-600">{series?.title}</h4>
                                  <div className="latest-series-name">
                                    <div className="video-tile">
                                      <p className='mb-0 fw-400'>{series?.episodes_count} episodes</p>
                                      <p className='mb-0 fw-500'>{series?.year}</p>
                                    </div>
                                    <button onClick={() => navigate(ROUTES.SERIES_DETAIL, { state: { id: series?.id } })} className="common-btn watch-btn">
                                      <span className='me-2'>Watch</span><img
                                        src={playLightBtn}
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }

                    </div>
                  </div >

                  {
                    !isLoading &&
                    <>
                      {
                        seriesList?.length <= 0 &&
                        <div style={{ flexDirection: 'column' }} className='no-search-results'>
                          <img src={noSeries} />
                        </div>
                      }
                    </>
                  }

                </section >
              </div >
            </div >
          </div >
        </section >

        <AllChallengesFilter />
      </div>
    </div>
  )
}

export default ChannelDetails;