import React, { useEffect, useState } from 'react'
import { embed, line, link, linkVideo, lives, modalCross, notification, plusgray, videoPlayButton } from '../../Constants/images'
import uploadToS3 from '../component/fileUpload'
import ERR_MESSAGE from '../../Helper/error-helper'
import { NotificationManager } from 'react-notifications'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../Helper/config"
import ROUTES from '../../Helper/route-helper'
import { Link, useNavigate } from 'react-router-dom'
import ReactPlayer from 'react-player'
const ChallengeModal = ({ setChallengeModal, setProgressLoading, setProgress, setIsLoading, getListAPI, challengeId }) => {
  const [steps, setSteps] = useState(1)
  const [thumbnailFileUrl, setThumbnailFileUrl] = useState()
  const [thumbnail, setThumbnail] = useState()
  const [thumbnail1, setThumbnail1] = useState()
  const [thumbnail2, setThumbnail2] = useState(null)
  const [embedDuration, setEmbedDuration] = useState(0)
  const [video, setVideo] = useState()
  const [video1, setVideo1] = useState()
  const [video2, setVideo2] = useState()
  const [link, setLink] = useState("")
  const [errors, setErrors] = useState("")
  const [embedCode, setEmbedCode] = useState("")
  const [refresh, setRefresh] = useState(false)
  const [category, setCategory] = useState([])
  const [duration, setDuration] = useState()
  const [errorMessage, setErrorMessage] = useState('');
  const naviagte = useNavigate()
  const [fields, setFields] = useState({
    title: "",
    category: "",
    description: "",
  })

  async function getChallengeAPI() {
    setIsLoading(true)
    if (challengeId != undefined) {
      let result = await apiHelper.getRequest("creator/my-challenge-details?id=" + challengeId)
      if (result.code == DEVELOPMENT_CONFIG.statusCode) {
        setIsLoading(false)
        setFields(result.data.challengesDetails)
        setFields({
          ...fields,
          title: result?.data?.challengesDetails.title,
          description: result?.data?.challengesDetails.description,
          category: result?.data?.challengesDetails.category.id
        });
        setThumbnailFileUrl(result.data.challengesDetails.thumbnail)
        if (result.data.challengesDetails.creatorVideo.video_type == 1) {
          setLink(result.data.challengesDetails?.creatorVideo.video_url)
        }
        else if (result.data.challengesDetails.creatorVideo.video_type == 2) {
          setEmbedCode(result.data.challengesDetails?.creatorVideo.video_url)
        }
        else if (result.data.challengesDetails.creatorVideo.video_type == 3) {
          setThumbnail2(result.data.challengesDetails.creatorVideo.thumbnail)
        }
      }
      else if (result.code == 401) {
        let refreshToken = localStorage.getItem("refresh-token")
        let data = {
          "refresh_token": refreshToken,
          "scope": "refresh_token"
        }
        let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
        if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
          localStorage.setItem("token", refreshTokenResult.data.token)
          localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
          setRefresh(true)
        }
      }
    }
  }

  useEffect(() => {
    getChallengeAPI()
  }, [refresh, challengeId])


  const fileChange = (e) => {
    setErrors("")
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file) {
      if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
        const formData = new FormData();
        formData.append("image", file);
        const imageURL = URL.createObjectURL(file);
        fileUpload(file)
      }
    }
  };

  const fileUpload = async (file, thumb) => {
    const state = { videoLocation: null, thumbnailLocation: null };
    setProgressLoading(true)
    try {
      if (file) {
        await uploadToS3(thumb, file, state, setProgress);
        if (state.videoLocation) {
          setProgressLoading(false)
          setThumbnailFileUrl(state.videoLocation)
        }
      }
    } catch (error) {
    }
  }

  const handleChange = (e) => {
    setErrors("");
    setErrorMessage("");
    const name = e.target.name;
    const value = e.target.value;

    setFields({
      ...fields,
      [name]: value,
    });

  };

  const handleEmbedCodeChange = (e) => {
    setErrorMessage("")
    setEmbedCode(e.target.value);
    if (e.target.value) {
      extractVideoAndThumbnailFromEmbedCode(e.target.value)
    }
  };

  const extractVideoAndThumbnailFromEmbedCode = async (embedCode) => {
    try {
      const urlRegex = /https?:\/\/[^\s]+/i;
      const urlMatch = embedCode.match(urlRegex);
      if (urlMatch && urlMatch[0]) {
        let videoUrl = urlMatch[0];
        videoUrl = videoUrl.replace(/"/g, '');
        setVideo1(embedCode)
        let thumbnailUrl;
        if (videoUrl.includes('youtube.com')) {
          const videoIdMatch = videoUrl.match(/(?:embed\/|v=)([^"&?\/\s]{11})/);
          if (videoIdMatch && videoIdMatch[1]) {
            const videoId = videoIdMatch[1];
            thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
            setThumbnail1(thumbnailUrl)
          } else {
            return { videoUrl, thumbnailUrl: null };
          }
        } else if (videoUrl.includes('vimeo.com')) {
          let oEmbedUrl = 'https://vimeo.com/api/oembed.json?url=' + encodeURIComponent(videoUrl);
          const response = await fetch(oEmbedUrl);
          if (response.ok) {
            const data = await response.json();
            if (data.thumbnail_url) {
              const thumbnailUrl = data.thumbnail_url;
              setThumbnail1(thumbnailUrl)
              setVideo1(embedCode)
              return { videoUrl, thumbnailUrl };
            } else {
              return { videoUrl, thumbnailUrl: null };
            }
          } else {
            return { videoUrl, thumbnailUrl: null };
          }
        } else {
          return { videoUrl, thumbnailUrl: null };
        }
      }
    } catch (error) {
    }
    return { videoUrl: null, thumbnailUrl: null };
  };

  const handleLinkChange = async (e) => {
    setErrorMessage("");
    const link = e.target.value;
    setLink(link);
    const videoExtensions = /\.(mp4|mov|avi|wmv|flv|mkv|webm)$/i;
    if (link && videoExtensions.test(link)) {
      try {
        setIsLoading(true)
        const blob = await fetch(link).then(response => response.blob());
        const fileName = link.substring(link.lastIndexOf('/') + 1);
        const file = new File([blob], fileName, { type: blob.type });
        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata';
        videoElement.src = URL.createObjectURL(file);
        videoElement.addEventListener('loadedmetadata', async () => {
          const durationInSeconds = videoElement.duration;
          const formattedDuration = formatDuration(durationInSeconds);
          const { thumbnailURL, fileThumbnail } = await generateThumbnailFromVideo(videoElement, durationInSeconds / 2, file);
          fileUploadLink(fileThumbnail, formattedDuration);
        });
      } catch (error) {
      }
    } else if (link && !videoExtensions.test(link)) {
      setErrorMessage("Invalid video link");
    }
  };



  const fileChange1 = async (e) => {
    setErrorMessage("")
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type.split('/')[0];
      if (fileType === 'video') {
        const files = e.target.files;
        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata';
        videoElement.addEventListener('loadedmetadata', async () => {
          const durationInSeconds = videoElement.duration;
          const formattedDuration = formatDuration(durationInSeconds);
          const { thumbnailURL, fileThumbnail } = await generateThumbnailFromVideo(videoElement, durationInSeconds / 2, files);
          const formData = new FormData();
          formData.append('mediaFile', file);
          const videoURL = URL.createObjectURL(file);
          fileUpload1(fileThumbnail, file, formattedDuration)
        });
        videoElement.src = URL.createObjectURL(file);
      }
    }
  };

  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    const hoursStr = hours < 10 ? `0${hours}` : `${hours}`;
    const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  };

  const formatDurationEmbed = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);
    return [h, m, s]
    .map(v => (v <10 ? `0${v}` : v))
    .join(':');
  };

  const generateThumbnail = async (videoElement, files) => {
    const canvas = document.createElement('canvas');
    const videoWidth = videoElement.videoWidth;
    const videoHeight = videoElement.videoHeight;
    canvas.width = videoWidth;
    canvas.height = videoHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(videoElement, 0, 0, videoWidth, videoHeight);
    const thumbnailURL = canvas.toDataURL('image/jpeg');
    const fileThumbnail = dataURLtoFile(thumbnailURL, files?.name?.split(".")[0]);
    return { thumbnailURL, fileThumbnail };
  };

  const generateThumbnailFromVideo = async (videoElement, timeInSeconds, files) => {
    return new Promise((resolve) => {
      const seekTime = Math.min(timeInSeconds, videoElement.duration - 0.1);
      videoElement.currentTime = seekTime;
      videoElement.addEventListener('seeked', async () => {
        const { thumbnailURL, fileThumbnail } = await generateThumbnail(videoElement, files);
        resolve({ thumbnailURL, fileThumbnail });
      });
    });
  };

  const fileUpload1 = async (thumb, file, formattedDuration) => {
    const state = { videoLocation: null, thumbnailLocation: null };
    setProgressLoading(true)
    try {
      if (file || thumb) {
        await uploadToS3(thumb, file, state, setProgress);
        if (state.videoLocation || state.thumbnailLocation) {
          setProgressLoading(false)
          setThumbnail2(state.thumbnailLocation)
          setVideo2(state.videoLocation)
          setDuration(formattedDuration)
        }
      }
    }
    catch (error) {
    }
  }

  const fileUploadLink = async (thumb, formattedDuration, file) => {
    const state = { videoLocation: null, thumbnailLocation: null };
    try {
      if (thumb) {
        await uploadToS3(thumb, file, state, setProgress);
        if (state.thumbnailLocation) {
          setIsLoading(false)
          setThumbnail(state.thumbnailLocation)
          setDuration(formattedDuration)
        }
      }
    }
    catch (error) {
    }
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const handleValidation1 = () => {
    let errors = {};
    let formIsValid = true;
    const { title, category, description } = fields;
    if (!title || title.trim().length === 0) {
      formIsValid = false;
      errors["title"] = ERR_MESSAGE.EMPTY_CHALLENGE
    }
    if (!category) {
      formIsValid = false;
      errors["category"] = ERR_MESSAGE.EMPTY_CATEORY
    }
    if (!description || description.trim().length === 0) {
      formIsValid = false;
      errors["description"] = ERR_MESSAGE.SUMMARY_EMPTY
    }
    if (!thumbnailFileUrl) {
      formIsValid = false;
      errors["thumbnailFileUrl"] = ERR_MESSAGE.THUMBNAIL_EMPTY
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleValidation2 = () => {
    if (!(link?.length > 0 || embedCode?.length > 0 || thumbnail2?.length > 0)) {
      setErrorMessage(ERR_MESSAGE.ENTER_OPTION);
      return false;
    }
    return true;
  };

  const handleSteps = (e) => {
    e.preventDefault()
    if (handleValidation1()) {
      setSteps(steps + 1)
    }
  }
  async function getDetailsAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest("creator/challenge-category")
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      setCategory(result?.data)
      setIsLoading(false)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }

      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  useEffect(() => {
    getDetailsAPI()
  }, [refresh])

  const extractVideoIdEmbedCode = (embedCode) => {
    try {
      const urlRegex = /https?:\/\/[^\s]+/i;
      const urlMatch = embedCode.match(urlRegex);
      if (urlMatch && urlMatch[0]) {
        const videoUrl = urlMatch[0].replace(/"/g, '');
        const regex = /\/embed\/([^?]+)/;
        const match = videoUrl.match(regex);
        if (match && match[1]) {
          return match[1];
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (handleValidation2()) {
      let videoType;
      let videoUrl;
      let thumbnailUrl;
      if (link) {
        videoType = 1;
        videoUrl = video ? video : link
        thumbnailUrl = thumbnail
      } else if (embedCode) {
        videoType = 2;
        videoUrl = video1 ? video1 : embedCode
        thumbnailUrl = thumbnail1
      } else if (video2) {
        videoType = 3;
        videoUrl = video2
        thumbnailUrl = thumbnail2
      }
      let data = {
        title: fields.title,
        description: fields.description,
        thumbnail: thumbnailFileUrl,
        is_featured: 1,
        is_active: 1,
        cat_id: parseInt(fields.category),
        challengeVideo: {
          video_type: videoType,
          video_url: videoUrl,
          thumbnail: thumbnailUrl,
          duration: videoType == 2 ? embedDuration : duration,
          video_id : extractVideoIdEmbedCode(embedCode)
        },
      };
      setIsLoading(true);
      let result = await apiHelper.postService("creator/create-challenge", data);
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        setIsLoading(false);
        setChallengeModal(false)
        setFields({
          ...fields,
          title: "",
          description: "",
          category: "",
        });
        setLink("")
        setEmbedCode("")
        setThumbnailFileUrl("")
        getListAPI()
        NotificationManager.success(result.message);
      } else {
        setIsLoading(false);
        NotificationManager.error(result.message);
      }
    }
  }

  async function handleEdit(e) {
    e.preventDefault();
    if (handleValidation2()) {
      let videoType;
      let videoUrl;
      let thumbnailUrl;
      if (link) {
        videoType = 1;
        videoUrl = link
        thumbnailUrl = thumbnail
      } else if (embedCode) {
        videoType = 2;
        videoUrl = embedCode
        thumbnailUrl = thumbnail1
      } else if (video2) {
        videoType = 3;
        videoUrl = video2
        thumbnailUrl = thumbnail2
      }
      let data = {
        "challengeId": challengeId,
        title: fields.title,
        description: fields.description,
        thumbnail: thumbnailFileUrl,
        is_featured: 1,
        is_active: 1,
        cat_id: parseInt(fields.category),
        challengeVideo: {
          video_type: videoType,
          video_url: videoUrl,
          thumbnail: thumbnailUrl,
          duration: videoType == 2 ? formatDuration : duration,
          video_id : extractVideoIdEmbedCode(embedCode)
        },
      };
      setIsLoading(true);
      let result = await apiHelper.postService("creator/edit-challenge", data);
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        setIsLoading(false);
        setChallengeModal(false)
        setFields({
          ...fields,
          title: "",
          description: "",
          category: "",
        });
        setLink("")
        setEmbedCode("")
        setThumbnailFileUrl("")
        getListAPI()
        naviagte(ROUTES.CREATER_CHALLENGES)
        localStorage.setItem("message", result.message)
      } else {
        setIsLoading(false);
        NotificationManager.error(result.message);
      }
    }
  }

  return (
    <div className="modal fade show modal-react" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
      <div className='loader'>
      </div>
      <div className="modal-dialog modal-dialog-centered modal-schedule-width">
        <div className="modal-content modal-radius  border-0">
          <div class="modal-header forgot-header align-items-center pt-4">
            <h3 class="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">{challengeId != undefined ? "Edit Challenge" : "Create Challenge"}</h3>
            <a class="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close">
              <img onClick={() => setChallengeModal(false)} style={{ cursor: "pointer" }} src={modalCross} alt="" className="img-fluid" /></a>
          </div>
          {steps === 1 && <div className="modal-body forgot-body verify-modal-box p-4 pt-4 p-md-0">
            <form >
              <div className='mt-3'>
                <div className="mb-3 schedule-thumb">
                  <div className="video-upload video-schedule position-relative">
                    {
                      thumbnailFileUrl && <div className={thumbnailFileUrl ? " challenge-preview  " : ""}><img src={thumbnailFileUrl ? thumbnailFileUrl : ""}></img></div>
                    }
                    {
                      thumbnailFileUrl ? "" : <div className='d-flex m-auto justify-content-center align-items-center h-100 challenge-preview-after'>
                        <div className=''>
                          <div className="d-flex m-auto justify-content-center align-items-center h-100">
                            <img src={plusgray} alt="" className="img-fluid me-1" />
                            <span className="Upload-text fw-500  create-text ">Upload Thumbnail</span>
                          </div>
                        </div>
                      </div>
                    }

                    <input type="file" className="video-file-schedule" style={{ cursor: "pointer" }} onChange={fileChange} accept=".jpg, .jpeg, .png"></input>
                    {errors.thumbnailFileUrl && <span className="err_msg">{errors.thumbnailFileUrl}</span>}
                  </div>
                </div>
              </div>
              <div className='mb-3'>
                <label className='fw-500 f18-size text-black pb-2'>Challenge Name</label>
                <input type='text' className='border-edit  w-100' name="title" value={fields.title} onChange={handleChange} placeholder='Enter Here' ></input>
                {errors.title && <span className="err_msg">{errors.title}</span>}

              </div>
              <div className='mb-3'>
                <label className='fw-500 f18-size text-black pb-2'>Category</label>
                <select className='form-select border-edit w-100' name="category" value={fields.category} onChange={handleChange}>
                  <option>Select Here</option>
                  {
                    category?.map((item) => (
                      <>
                        <option value={item.id}>{item.name}</option>
                      </>
                    ))
                  }
                </select>
                {errors.category && <span className="err_msg">{errors.category}</span>}
              </div>

              <div className='mb-3'>
                <label className='fw-500 f18-size text-black pb-2'>Summary</label>
                <textarea class=" text-summary place-grey d-block w-100" value={fields.description} onChange={handleChange} placeholder="Enter Here" rows="6" name="description"></textarea>
                {errors.description && <span className="err_msg">{errors.description}</span>}

              </div>
              <div className=" pt-4 mb-5 mt-5">
                <input type='submit' value="Next" onClick={handleSteps} className="common-btn w-100" />
              </div>
            </form>
          </div>}
          {steps === 2 && <div className="modal-body forgot-body verify-modal-box p-3 p-md-0 pt-4">
            <form>
              <div className='row pt-3'>
                <div className='col-3 col-sm-2'>
                  <div className='link-img'>
                    <img src={linkVideo}></img>
                  </div>
                </div>
                <div className='col-9 col-sm-10 ps-0 embed-main-input'>
                  <div className='link-input'>
                    <input type='text' value={link} onChange={handleLinkChange} name="link" className='border-edit input-bg  w-100 border-0' placeholder='Link paste here' disabled={embedCode || thumbnail2 !== null}
                    ></input>
                  </div>
                  <p className="or-bg mt-3 mb-2" style={{ backgroundImage: `url(${line})` }}><span>OR</span></p>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-3 col-sm-2'>
                  <div className='link-img'>
                    <img src={embed}></img>
                  </div>
                </div>
                <div className='col-9 col-sm-10 ps-0 embed-main-input'>
                  <div className='link-input embed-input'>
                    <textarea value={embedCode} onChange={handleEmbedCodeChange} name="embedCode" className='border-edit input-bg  w-100 border-0' placeholder='Embed code'
                      disabled={link || thumbnail2 !== null}
                    ></textarea>
                  </div>
                  <p className="or-bg mt-3 mb-2" style={{ backgroundImage: `url(${line})` }}><span>OR</span></p>
                </div>
              </div>
              <div className='mt-3'>
                <div className="mb-3 schedule-thumb">
                  <div className="video-upload video-schedule position-relative">
                    {
                      thumbnail2 && <div className={thumbnail2 ? " challenge-preview" : ""}><img src={thumbnail2 ? thumbnail2 : ""}></img>
                        <div class="play-bite"><img src={videoPlayButton} class="img-fluid img-bite" />
                        </div>
                      </div>
                    }
                    {
                      thumbnail2 ? "" : <div className='d-flex m-auto justify-content-center align-items-center h-100 challenge-preview-after'>
                        <div className=''>
                          <div className="d-flex m-auto justify-content-center align-items-center h-100">
                            <img src={plusgray} alt="" className="img-fluid me-1" />
                            <span className="Upload-text fw-500  create-text ">Upload Video</span>
                          </div>
                        </div>
                      </div>
                    }
                    <input type="file" className="video-file-schedule" style={{ cursor: "pointer" }} onChange={fileChange1} accept=".mp4, .mov, .avi" disabled={link || embedCode}></input>
                    {errorMessage && <span className="err_msg">{errorMessage}</span>}
                  </div>
                </div>
              </div>
              {
                challengeId != undefined ? <div className="pt-5 mb-5">
                  <input type='button' value="Submit" onClick={handleEdit} className="common-btn w-100" />
                </div> :
                  <div className="pt-5 mb-5">
                    <input type='button' value="Submit" onClick={handleSubmit} className="common-btn w-100" />
                  </div>
              }
            </form>
            {
              embedCode && <ReactPlayer
                url={embedCode.match(/src="([^"]+)"/) ? embedCode.match(/src="([^"]+)"/)[1] : ""}
                controls={false}
                playing={false}
                onDuration={(duration) => {
                  const formatDuration = formatDurationEmbed(duration)
                  setEmbedDuration(formatDuration);
                }}
                width="0%"
                style={{ position: 'absolute', top: 0, left: 0, opacity: 0 }}
                height="0%"
              />
            }

          </div>}
        </div>
      </div>
    </div>
  )
}

export default ChallengeModal
