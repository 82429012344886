import React, { useEffect, useState } from 'react';
import { live_icon, no_live, rightArrowLive } from '../../Constants/images';

function UserLivesSection({ lives, isLoading, handleClick, getApi, getrecordingApi }) {
    const [activeTab, setActiveTab] = useState(localStorage.getItem("activeTab") || "0")
    
    useEffect(() => {
        if (activeTab === "0") {
            getApi();
        } else {
            getrecordingApi();
        }
    }, [activeTab]);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex.toString());
        localStorage.setItem("activeTab", tabIndex.toString());
    };

    return (
        <div>
            <section className="schedule-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="stream-btn pt-3">
                            <button onClick={() => handleTabClick(0)} className={`common-btn btn-blue ${activeTab === "0" ? "active" : ""}`}>Lives</button>
                            <button onClick={() => handleTabClick(1)} className={`common-btn btn-white ${activeTab === "1" ? "active" : ""}`}>Recorded Lives</button>
                            <button onClick={() => handleTabClick(2)} className={`common-btn btn-white ${activeTab === "2" ? "active" : ""}`}>Upcoming Lives</button>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        {
                            lives?.map((item) => (
                                <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4">
                                    <div className="latest-video mx-auto" onClick={() => handleClick(item)}>
                                        <div className='position-relative'>
                                            <div
                                                className="latest-video-content  pt-2 pt-sm-3 px-2 px-sm-3 position-relative"
                                                style={{ cursor: "pointer" }}>
                                                <img
                                                    src={item?.profile_pic}
                                                    className="img-fluid latest-vid-img"
                                                />
                                                <div className='live-img-btn'>
                                                    <p className='f16-size mb-0 fw-600 d-flex align-items-center'>
                                                        <img src={live_icon} className='me-2' />Live</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="latest-video-cus px-3 py-3 schedule-grid">
                                            <div class="grid-data-detail-lives">
                                                <div>
                                                    <h6 className='fw-600 f20-size channel-text text-capitalize  me-2'> {item?.schedule?.name}</h6>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div class="live-circle-img live-name-profile"><img src={item.profile_pic}alt="" /></div>
                                                    <div><h6 class=" fw-600 mb-0 px-2 f16-size">{item.name} </h6></div>
                                                </div>
                                            </div>
                                            <div className='dotschedule bg-transparent'>
                                                <div className='dropdownschedule'>
                                                    <img src={rightArrowLive} className='img-fluid cursor-pointer' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            (isLoading == false && lives?.length == 0) && <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                <img src={no_live} alt="no live" />
                            </div>
                        }
                    </div>
                </div>
            </section >

        </div >
    );
}

export default UserLivesSection;
