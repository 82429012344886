import React, { useState, useEffect } from 'react'
import { dummy, noBites, playLight } from '../../../Constants/images';
import apiHelper from '../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../../Helper/config'
import ReactLoader from '../../../ControlComponents/react-loader';
import BiteModal from '../../../Components/Bites';


function AllBites() {
   const [bitesList, setBitesList] = useState([])
   const [bitesList2, setBitesList2] = useState([])
   const [showBite, setShowBite] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [refresh, setRefresh] = useState(false);
   const [index, setIndex] = useState(0);
   const [offset, setOffset] = useState(0);
   const [limit, setLimit] = useState(4);
   const [currentBiteIndex, setCurrentBiteIndex] = useState(0);

   const handleAPIError = async (result) => {
      if (result.code === 401) {
         const refreshToken = localStorage.getItem("refresh-token");
         const data = {
            refresh_token: refreshToken,
            scope: "refresh_token",
         };

         const refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data);

         if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token);
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token);
            setRefresh(true);
         }
      }
   };

   // Function to fetch bites list
   const fetchBitesList = async () => {
      setIsLoading(true);
      try {
         const result = await apiHelper.getRequest("bites/bites-list");
         if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setBitesList(result?.data);
         } else {
            handleAPIError(result);
         }
      } catch (error) {
         console.error("Error fetching bites list:", error);
      } finally {
         setIsLoading(false);
      }
   };



   const fetchBiteDetails = async (index) => {
      setIsLoading(true);
      try {
         const url = `bites/bites-detail?id=${index}`;
         const url2 = `bites/bites-list?offset=${offset}&limit=${limit}`;

         const result = await apiHelper.getRequest(url);
         let result2;

         if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            result2 = await apiHelper.getRequest(url2);
            const newArr = [result.data, ...result2.data.data];
            setBitesList2(
               newArr.filter((obj, index, self) => index === self.findIndex((o) => o.id === obj.id))
            );
            setOffset(offset + 4);
         } else {
            handleAPIError(result);
         }
      } catch (error) {
         console.error("Error fetching bite details:", error);
      } finally {
         setIsLoading(false);
      }
   };
   
   // Function to handle bite play
   const handleBitePlay = (index) => {
      setShowBite(!showBite);
      setCurrentBiteIndex(index);
   };

   useEffect(() => {
      fetchBitesList();
   }, [refresh]);

   return (
      <div className='main-inner-content'>
         {isLoading && <ReactLoader />}
         <section className="featured response-padding py-60">
            <div className="container">
               {
                  bitesList?.length > 0 &&   <div className="chanel-header">
                  <h3 className="fw-600">Featured</h3>
               </div>
               }
             
               <div className="challene-wrapper mt-4">
                  <div className=" row">
                     {
                        bitesList?.slice(0, 3).map((bite) => {
                           return (
                              <div onClick={() => handleBitePlay(index)} 
                                 className="featured-box-cus position-relative col-lg-4 mb-4 mb-lg-0">
                                 <div className="video-bg video-wrapr">
                                    <div className="video-content">
                                       <img
                                          src={bite.thumbnail}
                                          className="img-video img-fluid"
                                          alt=""
                                       />
                                    </div>
                                    <div className='content-video-feat'>
                                       <h4 className="mt-3">{bite?.title}</h4>
                                       <div className="video-detail">
                                          <div>
                                             <img
                                                src={bite?.biteChannel?.profile_pic}
                                                className="bite-inner-img chllge-img"
                                                alt=""
                                             />
                                          </div>
                                          <div>
                                             <h5 className="mb-0 fw-600">{bite?.biteChannel?.name}</h5>
                                             <p className="mb-0">{bite?.category.name}</p>
                                          </div>
                                       </div>
                                    </div>

                                 </div>

                              </div>
                           )

                        }, [])
                     }
                  </div>
               </div>
            </div>
         </section>
         <section className="bite response-padding">
            <div className="container px-0 px-md-2">
               <div className="chanel-header">
                  <h3 className="fw-600 mb-0">Bites</h3>
               </div>
               <div className="chanel-wrapper mt-4">
                  <div className="row gy-2">
                     {bitesList?.map((bite, index) =>
                        <div onClick={() => handleBitePlay(index)}  key={bite?.id} className="col-xl-4 col-xxl-3 col-lg-4 col-md-6  response-col">
                           <div className="channel-bites-content">
                              <img src={bite.thumbnail} className="channelbite-img" alt="" />
                              <div className="bite-wrapper">
                                 <h4>{bite?.title}</h4>
                                 <div className="chanel-text d-flex gap-3 align-items-center">
                                    <img src={bite?.biteChannel?.profile_pic} className="bite-inner-img" alt="" />
                                    <div>
                                       <h6 className='fw-600 mb-0'>{bite?.biteChannel?.name}</h6>
                                       <p className='mt-1'>{bite.category?.name}</p>
                                    </div>
                                 </div>
                                 <button onClick={() => handleBitePlay(index)} className="common-btn"><img src={playLight} alt="" />Play</button>
                              </div>
                           </div>
                        </div>
                     )}
                  </div>
               </div>
               {
                  showBite &&
                  <BiteModal currentBiteIndex={currentBiteIndex} setCurrentBiteIndex={setCurrentBiteIndex} index={index} bitesList={bitesList} showBite={showBite} setShowBite={setShowBite} />
               }

            </div>
         </section>

         {
            !isLoading &&
            <>
               {
                  bitesList?.length == 0 &&
                  <div className='no-search-results'>
                     <img src={noBites} alt='No Bites Found' />
                  </div>
               }
            </>
         }

      </div>
   )
}

export default AllBites;