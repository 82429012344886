import React from 'react'
import { noChannelFound, profile_image_placeholder } from '../../Constants/images'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../Helper/route-helper'

function ShopList({ channelList, loading}) {
    const navigate = useNavigate()
    
    const handleNavigate = (item) => {
        navigate(ROUTES.USER_SHOP_DETAIL, { state: { item: item } })
    }

  return (
        <div className="container-fluid">
            <div className="row">
               
                <div className="row mt-3">
                    {
                        channelList?.length == 0 && !loading ? (
                            <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                <img src={noChannelFound} />
                            </div>
                        ) :
                            channelList?.map((item) => (
                                <div className="col-12 col-sm-6 col-lg-6 col-xl-3 mb-4 position-relative" key={item.id}>
                                    <div className="channels-box  mb-3 mb-lg-3 mb-xl-0" style={{ cursor: 'pointer' }} onClick={() => handleNavigate(item)}>
                                        <img src={item.profile_pic ? item.profile_pic : profile_image_placeholder} className="img-fluid channel-img" />
                                        <h4 className="channel-text fw-600 my-2">{item.name}</h4>
                                    </div>

                                    {/* {
                                            item.is_fav == 1 ? <div className="like-icon " onClick={() => addFavourites(item)} style={{ cursor: "pointer" }}>
                                                <img src={favRed} className="img-fluid fill-icon " />
                                            </div> : <div className="like-icon " style={{ cursor: 'pointer' }} onClick={() => addFavourites(item)}>
                                                <img src={favouriteLight} className="img-fluid fill-icon " />
                                            </div>
                                        } */}

                                </div>
                            ))
                    }

                </div>
            </div>
        </div >



    )
}

export default ShopList