import React, { useState, useEffect, useRef } from 'react';
import AgoraRTC, {
  ICameraVideoTrack,
  IRemoteVideoTrack,
  IAgoraRTCClient,
  IRemoteAudioTrack,
} from "agora-rtc-sdk-ng";
import { AGORA_CRED, S3_CREDS } from '../../Helper/config';
import ReactLoader from '../../ControlComponents/react-loader';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../Helper/config"
import "./liveVideo.css"
import { liveAudio, liveVideo, mute_audio, mute_video, profile_image_placeholder, send, smile } from '../../Constants/images';
import ROUTES from '../../Helper/route-helper';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { collection, addDoc, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from '../../firebase';
import { v4 as uuidv4 } from 'uuid';
import EmojiPicker from 'emoji-picker-react';

const StartLive = () => {
  const [expandedComments, setExpandedComments] = useState([]);
  const location = useLocation()
  const chatId = localStorage.getItem("chatId")
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [cameraTrack, setCameraTrack] = useState(null);
  const [microphoneTrack, setMicrophoneTrack] = useState(null);
  const [cameraTrack1, setCameraTrack1] = useState(null);
  const [microphoneTrack1, setMicrophoneTrack1] = useState(null);
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [liveTime, setLiveTime] = useState("00:00:00");
  const [rtcToken, setRtcToken] = useState();
  const [channelName, setChannelName] = useState();
  const [agoraClient, setAgoraClient] = useState(null);
  const [creatorName, setCreatorName] = useState(null)
  const [creatorImg, setCreatorImg] = useState(null)
  const [sid, setSid] = useState(null)
  const [uid, setUid] = useState(null)
  const [resourceId, setResourceId] = useState(null)
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [creatorUid, setCreatorUid] = useState(null)
  const [elapsedTimeInSeconds, setElapsedTimeInSeconds] = useState(0);
  const messageListRef = useRef();

  async function getDetailsAPI() {
    let result = await apiHelper.getRequest("auth/get-my-detail")
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      setCreatorName(result?.data?.name);
      setCreatorImg(result?.data?.profile_pic)
    } else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult?.data?.token)
        localStorage.setItem("refresh-token", refreshTokenResult?.data?.refresh_token)
        setRefresh(true)
      }
    }
  }

  async function initializeAgora() {
    let result = await apiHelper.getRequest(`creator/generate-token?user_id=${localStorage.getItem("creator_id")}`)
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      setChannelName(result.body?.channel)
      setRtcToken(result?.body?.token)
      connectToRtc(result?.body?.token, result.body?.channel)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  async function acquireRecordingResource(channelName, uid, token) {
    let data = JSON.stringify({
      "cname": `${channelName}`,
      "uid": `${uid}`,
      "clientRequest": {}
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://api.agora.io/v1/apps/${AGORA_CRED.APP_ID}/cloud_recording/acquire`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic Yjg0Yzk5ODM0ODY4NDY4MzlkOGNhZWMzNWIyZDRlZWY6Njg0YzkxNGQwNTNiNDExM2IyNTA3M2ExYTBjNDZiNTc='
      },
      data: data
    };
    axios.request(config)
      .then((response) => {
        setResourceId(response.data.resourceId)
        startRecording(channelName, uid, token, response.data.resourceId)
      })
      .catch((error) => {
      });
  }
  async function startRecording(channelName, uid, token, resourceId) {
    let data = JSON.stringify({
      "cname": `${channelName}`,
      "uid": `${uid}`,
      "clientRequest": {
        "token": `${token}`,
        "recordingConfig": {
          "maxIdleTime": 30,
          "channelType": 0,
          "streamTypes": 2,
          "streamMode": "standard",
          "videoStreamType": 1,
          "subscribeUidGroup": 0,
        },
        "recordingFileConfig": {
          "avFileType": [
            "hls"
          ]
        },
        "storageConfig": {
          "vendor": 1,
          "region": 14,
          "bucket": `${S3_CREDS.AWS_BUCKET}`,
          "accessKey": `${S3_CREDS.AWS_ACCESS_KEY_ID}`,
          "secretKey": `${S3_CREDS.AWS_SECRET_ACCESS_KEY}`,
          "fileNamePrefix": [
            "recordedFiles"
          ]
        }
      }
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://api.agora.io/v1/apps/${AGORA_CRED.APP_ID}/cloud_recording/resourceid/${resourceId}/mode/individual/start`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic Yjg0Yzk5ODM0ODY4NDY4MzlkOGNhZWMzNWIyZDRlZWY6Njg0YzkxNGQwNTNiNDExM2IyNTA3M2ExYTBjNDZiNTc='
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        setSid(response.data.sid)
      })
      .catch((error) => {
      });
  }

  async function stopRecording() {
    let data = JSON.stringify({
      "cname": `${channelName}`,
      "uid": `${uid}`,
      "clientRequest": {}
    })
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://api.agora.io/v1/apps/${AGORA_CRED.APP_ID}/cloud_recording/resourceid/${resourceId}/sid/${sid}/mode/individual/stop`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic Yjg0Yzk5ODM0ODY4NDY4MzlkOGNhZWMzNWIyZDRlZWY6Njg0YzkxNGQwNTNiNDExM2IyNTA3M2ExYTBjNDZiNTc='
      },
      data: data
    };
    axios.request(config)
      .then((response) => {
        const matchedFile = response.data.serverResponse.fileList.find(file => file.uid == creatorUid);
        if (matchedFile) {
          const extractedData = {
            uid: matchedFile.uid,
            isPlayable: matchedFile.isPlayable,
            url: `https://techangouts.s3.ap-south-1.amazonaws.com/${matchedFile.fileName}`,
            trackType: matchedFile.trackType,
            mixedAllUser: matchedFile?.mixedAllUser
          };
          saveRecordedVideo(channelName, extractedData.uid, extractedData.url, extractedData.isPlayable, extractedData.trackType, extractedData.mixedAllUser, resourceId, sid);
        }
      })
      .catch((error) => {
      });
  }

  async function saveRecordedVideo(channelName, uid, url, isPlayable, trackType, mixedAllUser, resourceId, sid) {
    let data = {
      "channel_id": channelName,
      "creator_id": localStorage.getItem("creator_id"),
      "uid": uid,
      "recording_url": url,
      "is_playable": isPlayable,
      "start_time": localStorage.getItem("dateTime"),
      "resource_id": resourceId,
      "sid": sid,
      "mixed_all_user": mixedAllUser,
      "track_type": trackType,
      "schedule_id": location.state.id
    };
    setLoading(true);
    let result = await apiHelper.postService("creator/save-recording", data);
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false);
      localStorage.removeItem("dateTime")
    }
    else {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDetailsAPI()
    initializeAgora()
    return () => {
      if (cameraTrack && microphoneTrack && agoraClient) {
        cameraTrack.stop()
        microphoneTrack.stop()
        agoraClient.leave()
        localStorage.removeItem("chatId")
        onlineCreators(0)
        agoraClient.removeAllListeners()
        cameraTrack.close()
        microphoneTrack.close()
        setStartTime(null)
        setCameraTrack(null)
        setMicrophoneTrack(null)
        stopRecording()
        setCameraTrack1(null)
        setMicrophoneTrack1(null)
        cameraTrack1.stop()
        microphoneTrack1.stop()
        cameraTrack1.close()
        microphoneTrack1.close()
        localStorage.removeItem("value")
      }
    };
  }, [refresh])


  async function onlineCreators(type, remoteUid) {
    let queryParams = `type=${type}`;
    if (type == 1 && remoteUid) {
      queryParams += `&remote_uid=${remoteUid}`;
    }
    let result = await apiHelper.getRequest(`creator/online-offline?${queryParams}`)

    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
    } else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token");
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      };
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data);
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token);
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token);
      }
    }
  }

  const connectToRtc = async (rtcToken, channelName) => {
    if (rtcToken) {
      setLoading(true);
      const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
      setAgoraClient(client);
      await client.join(AGORA_CRED.APP_ID, channelName, rtcToken, null);
      const [camera, microphone] = await Promise.all([
        AgoraRTC?.createCameraVideoTrack(),
        AgoraRTC?.createMicrophoneAudioTrack()
      ])
      setStartTime(new Date());
      setLoading(false);
      setCameraTrack(camera);
      setMicrophoneTrack(microphone);
      await client.publish([camera, microphone]);
      setCreatorUid(client.uid)
      if (client.uid) {
        onlineCreators(1, client.uid)
      }
      connectToRtc2(rtcToken, channelName)
    }
  }

  const connectToRtc2 = async (rtcToken, channelName) => {
    if (rtcToken) {
      const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
      setAgoraClient(client);
      await client.join(AGORA_CRED.APP_ID, channelName, rtcToken, null);
      setUid(client.uid)
      const [camera, microphone] = await Promise.all([
        AgoraRTC?.createCameraVideoTrack(),
        AgoraRTC?.createMicrophoneAudioTrack()
      ])
      setCameraTrack1(camera);
      setMicrophoneTrack1(microphone);
      await client.publish([camera, microphone]);
      acquireRecordingResource(channelName, client.uid, rtcToken)
    }
  }

  const handleAudioToggle = () => {
    if (microphoneTrack) {
      microphoneTrack.setMuted(!isAudioMuted);
      setIsAudioMuted(!isAudioMuted);
    }
  }

  const handleVideoToggle = () => {
    if (cameraTrack) {
      cameraTrack.setMuted(!isVideoMuted);
      setIsVideoMuted(!isVideoMuted);
    }
  }

  const handleEndLive = async () => {
    if (cameraTrack && microphoneTrack && agoraClient) {
      cameraTrack.stop()
      microphoneTrack.stop()
      cameraTrack1.stop()
      microphoneTrack1.stop()
      cameraTrack1.close();
      microphoneTrack1.close();
      await agoraClient.leave()
      localStorage.removeItem("chatId")
      agoraClient.removeAllListeners()
      cameraTrack.close();
      microphoneTrack.close();
      onlineCreators(0)
      setStartTime(null)
      setCameraTrack1(null);
      setMicrophoneTrack1(null);
      setCameraTrack(null);
      setMicrophoneTrack(null);
      let message = "creator live"
      localStorage.setItem("message", message)
      navigate(ROUTES.CREATER_LIVES)
      stopRecording(channelName)
      localStorage.removeItem("value")

    }
  }


  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messageListRef.current) {
      const { scrollHeight, clientHeight } = messageListRef.current;
      messageListRef.current.scrollTop =( scrollHeight - clientHeight) + 2
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (startTime) {
        const currentTime = new Date();
        const elapsedTimeInMilliseconds = currentTime - startTime;
        const elapsedTimeInSeconds = Math.floor((currentTime - startTime) / 1000);
        const hours = Math.floor(elapsedTimeInSeconds / 3600);
        const minutes = Math.floor((elapsedTimeInSeconds % 3600) / 60);
        const seconds = elapsedTimeInSeconds % 60;
        const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        setLiveTime(formattedTime)
        setElapsedTimeInSeconds(elapsedTimeInMilliseconds)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [startTime])


  const sendMessage = async (e) => {
    e.preventDefault();
    const commentUid = uuidv4();

    if (message != "") {
      try {
        await addDoc(collection(db, "LiveStreamCollection", chatId, "Comments"), {
          name: creatorName,
          comment: message,
          createdAt: new Date(Date.now()).toString(),
          timeStamp: elapsedTimeInSeconds,
          profile_pic: creatorImg,
          uuid: commentUid,

        })
        setMessage("")
        localStorage.setItem("value", " ")
      } catch (e) {
        console.error("Error adding message: ", e);
      }
    }
  }

  useEffect(() => {
    const q = query(collection(db, "LiveStreamCollection", chatId, "Comments"), orderBy("createdAt", "asc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedMessages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setMessages(fetchedMessages);
    });

    return () => unsubscribe()
  }, [])

  function getTimeAgo(createdAt) {
    const currentDate = new Date();
    const createdAtDate = new Date(createdAt);
    const timeDifference = currentDate.getTime() - createdAtDate.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      return days + (days === 0 || days === 1 ? " day ago" : " days ago");
    } else if (hours > 0) {
      return hours + (hours === 0 || hours === 1 ? " hour ago" : " hours ago");
    } else if (minutes > 0) {
      return minutes + (minutes === 0 || minutes === 1 ? " min ago" : " mins ago");
    } else {
      return (seconds >= 0 ? seconds : 0) + " sec ago";
    }
  }

  const renderCommentText = (text, uuid) => {
    const isExpanded = expandedComments.includes(uuid);

    if (text.length > 200 && !isExpanded) {
      return (
        <>
          {`${text.substring(0, 200)}...`}
          <span className="read-more" style={{ cursor: "pointer" }} onClick={() => handleExpandComment(uuid)}> Read More</span>
        </>
      );
    } else if (isExpanded) {
      return (
        <>
          {text}
          <span className="read-more" style={{ cursor: "pointer" }} onClick={() => handleCollapseComment(uuid)}> ...Read Less</span>
        </>
      );
    } else {
      return text;
    }
  };

  const handleExpandComment = (uuid) => {
    setExpandedComments(prevState => [...prevState, uuid]);
  };

  const handleCollapseComment = (uuid) => {
    setExpandedComments(prevState => prevState.filter(item => item !== uuid));
  };

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleEmojiSelect = (emoji) => {
    const valueMessage = localStorage.getItem("value")
    setMessage(valueMessage + emoji.emoji)
    localStorage.setItem("value", valueMessage + emoji.emoji)
  }

  return (
    <div>
      {loading && <ReactLoader />}
      <div className="main-inner-content">
        <div className='row'>
          <div className='col-lg-8'>
            <VideoPlayer
              videoTrack={cameraTrack}
              audioTrack={microphoneTrack}
              handleAudioToggle={handleAudioToggle}
              handleVideoToggle={handleVideoToggle}
              liveTime={liveTime}
              handleEndLive={handleEndLive}
              isAudioMuted={isAudioMuted}
              isVideoMuted={isVideoMuted}
              creatorName={creatorName}
            />
          </div>
          <div className='col-lg-4 mt-4 mt-lg-0'>
            <div className='chat-right position-relative'>
              <div className='chat-heading'>
                <h4 className='mb-0'>Chats</h4>
              </div>
              <div className='chat-message new-chat-message' >
                <div className='startlive-container' ref={messageListRef}>
                  {
                    messages.map((msg, index) => (
                      <div className='chat-wrapper border-bottom pb-3' key={index} >
                        <div className='chat-listing py-3'>
                          <div className='profile-chat'>
                            {
                              msg.profile_pic ? <img src={msg.profile_pic}></img>
                                :
                                <img src={profile_image_placeholder} />
                            }
                          </div>
                          <div className='d-flex align-items-start'>
                            <p className='mb-0 lh-sm f16-size  fw-500  channel-text text-capitalize me-2'>{msg.name}{" "}:</p>
                            <p className='mb-0 channel-text f16-size fw-600 user-side-comment'> {renderCommentText(msg.comment, msg.uuid)}</p>
                          </div>
                          <p className='user-side-day'>{getTimeAgo(msg.createdAt)}</p>
                          {/* <div className=''>
                            <img src={dotshorizontal}></img>
                          </div> */}
                        </div>
                      </div>
                    ))
                  }
                </div>
                <form onSubmit={sendMessage}>
                  <div className='chat-bottom live-chat-bottom'>
                    <img src={creatorImg ? creatorImg : profile_image_placeholder} className='profile-bottom'></img>
                    <input
                      autoComplete='off'
                      id="input-message"
                      className="input-message w-100 border-0"
                      type="text"
                      placeholder="Type a Message"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value)
                        localStorage.setItem("value", e.target.value)
                      }
                      }
                      onKeyDown={(e) => {
                        if (e.key == "Enter" || e.key == "numpndEnter") {
                          sendMessage(e)
                        }
                        if (e.code == "Backspace") {
                          const valueMessage = localStorage.getItem("value")
                          if (valueMessage?.length >= 0) {
                            setMessage(valueMessage.slice(0, -1))
                          }
                        }
                      }}
                    />
                    <img src={send}
                      onClick={sendMessage}
                    ></img>
                    <img src={smile} onClick={toggleEmojiPicker} ></img>
                    {showEmojiPicker && (
                      <EmojiPicker
                        onEmojiClick={handleEmojiSelect}
                        disableAutoFocus={true}
                        className='custome-emoji'
                        pickerStyle={{
                          position: 'absolute',
                          bottom: '50px',
                        }}
                      />
                    )}
                  </div>
                </form>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default StartLive;

export const VideoPlayer = ({ videoTrack, handleEndLive, handleAudioToggle, handleVideoToggle, liveTime, isAudioMuted, isVideoMuted, creatorName }) => {
  const ref = useRef(null);
  useEffect(() => {
    const playerRef = ref.current;
    if (!videoTrack) return;
    if (!playerRef) return;
    videoTrack.play(playerRef)
    return () => {
      videoTrack.stop()
    };
  }, [videoTrack])

  function getFirstNameLastNameAbbreviation(fullName) {
    const nameParts = fullName.split(" ");
    let firstNameInitial = nameParts[0].charAt(0).toUpperCase();
    let lastNameInitial = '';
    if (nameParts.length > 1) {
      lastNameInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();
    }
    return firstNameInitial + lastNameInitial
  }


  return (
    <>
      <div ref={ref} className={isVideoMuted ? 'live-video mutevideo' : 'live-video '} >
        <div className='miccameramain'>
          <div className='micmain' style={{ cursor: "pointer" }}
            onClick={handleVideoToggle}
          >
            {isVideoMuted ? <img src={mute_video} className='img-fluid' />
              : <img src={liveVideo} className='img-fluid' />
            }
          </div>
          <div className='micmain'
            onClick={handleAudioToggle} style={{ cursor: "pointer" }}
          >
            {isAudioMuted ? <img src={mute_audio} className='img-fluid' />
              : <img src={liveAudio} className='img-fluid' />
            }
          </div>
        </div>
        <div className='endbutton'>
          <button className='endinnerbutton'
            onClick={handleEndLive}
          >
            End Live
          </button>
        </div>
        <div className='livetime-button'>
          Live {""}
          {liveTime}
        </div>
      </div>
      {
        isVideoMuted == true && <>
          <div className='live-video'>
            <div className='mutedvideo'>
              <p className='mb-0'>{getFirstNameLastNameAbbreviation(creatorName)}</p>
            </div>
            <div className='miccameramain'>
              <div className='micmain'
                onClick={handleVideoToggle}
              >
                {isVideoMuted ? <img src={mute_video} className='img-fluid' />
                  : <img src={liveVideo} className='img-fluid' />
                }
              </div>
              <div className='micmain'
                onClick={handleAudioToggle}
              >
                {isAudioMuted ? <img src={mute_audio} className='img-fluid' />
                  : <img src={liveAudio} className='img-fluid' />
                }
              </div>
            </div>
            <div className='endbutton'>
              <button className='endinnerbutton'
                onClick={handleEndLive}
              >
                End Live
              </button>
            </div>
            <div className='livetime-button'>
              Live {""}
              {liveTime}
            </div>
          </div>
        </>
      }
    </>
  )
}
