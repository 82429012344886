import React, { useEffect, useState } from 'react';
import { noChallenges, profile_image_placeholder } from '../../Constants/images';

import { useNavigate } from 'react-router-dom';
import ReactLoader from '../../ControlComponents/react-loader';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import ROUTES from '../../Helper/route-helper';
import './allchallenges.css';

function AllChallenges() {
  const navigate = useNavigate()
  const [allChallenges, setAllChallenges] = useState([])
  const [catList, setCatList] = useState([])
  const [tags, setTags] = useState([]);
  const [featuredList, setFeaturedList] = useState([]);
  const [refresh, setRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  async function getDetailsAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest("challenges/challenges-list")
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      setAllChallenges(result.data.allChallenge);
      setFeaturedList(result.data.featured);
      let response = await apiHelper.getRequest("auth/category-list")
      setCatList(response.data)
      setIsLoading(false)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  useEffect(() => {
    getDetailsAPI()
  }, [refresh, tags])


  const handleChange = async (e) => {
    let result = await apiHelper.getRequest("challenges/challenges-list?search=" + e.target.value)
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      setAllChallenges(result.data.data);
      setIsLoading(false)
    }
  }

  const handleNavigate = (item) => {
    localStorage.setItem("userChallengeId", item.id)
    localStorage.setItem("challengeItem", JSON.stringify(item))
    navigate(ROUTES.CHALLENGES_DETAILS, { state: { item } })
  }


  return (
    <div className="main-inner-content">
      {
        isLoading && <ReactLoader />
      }
      <section className="favourite-cus bite response-padding ">
        <section className="featured response-padding ">
          <div className="container">
            <div className="chanel-header">
              <h3 className="fw-600">Featured</h3>
            </div>
            <div className="challene-wrapper mt-4">
              <div className="row">
                {
                  featuredList.slice(0, 3).map((element, index) => {
                    return (
                      <div
                        onClick={() => handleNavigate(element)}
                        className="featured-box-cus position-relative col-lg-4 mb-4 mb-lg-0"
                        key={index}
                      >
                        <div className="video-bg video-wrapr" style={{ cursor: "pointer" }}>
                          <div className="video-content">
                            <img
                              src={element?.thumbnail}
                              className="img-video img-fluid"
                              alt=""
                            />
                          </div>
                          <div className='content-video-feat'>
                            <h4 className="mt-3">{element.title}</h4>
                            <div className="video-detail">
                              <div>
                                <img
                                  src={element?.channelData?.profile_pic ? element?.channelData?.profile_pic : profile_image_placeholder}
                                  className="bite-inner-img chllge-img"
                                  alt=""
                                />
                              </div>
                              <div>
                                <h5 className="mb-0 fw-600">{element?.channelData?.name}</h5>
                                <p className="mb-0">{element?.category.name}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>

          </div>
        </section>

        <div className="container">
          <div className="chanel-header d-flex align-items-center justify-content-between">
            <h3 className="fw-600 mb-0">Challenges</h3>

            {/* <div className="col-lg-4 ">
              <form>
                <div className="form-group search-filter-category ms-0 ms-lg-0 position-relative">
                  <input onChange={handleChange} type="text" name="search" className="form-control" placeholder="Search" />
                  <button type="button" className="icon-search-category">
                    <img src={search} className="search-img" />
                  </button>
                </div>
              </form>
            </div> */}
          </div>

          <div className="chanel-wrapper mt-4">
            <div className="row gy-2">
              {
                allChallenges?.map((element, index) => {
                  return (
                    <div key={element?.id} className="col-12  col-lg-6 col-md-6 col-xxl-4">
                      <div className="chanel-content">
                        {element?.thumbnail != null && <img src={element?.thumbnail} className="bite-img-fav img-width" alt="" />}
                        <div className="bite-wrapper">
                          <h4 className='mb-0'>{element.title}</h4>
                          <div className="chanel-text my-2 d-flex gap-2 align-items-center">
                            {element?.channelData?.profile_pic != null && <img src={element?.channelData?.profile_pic} className="bite-inner-img" alt="" />}
                            <div>
                              <p className='fw-600 mb-0'>{element?.channelData?.name}</p>
                              <p className=''>{element?.category?.name}</p>
                            </div>
                          </div>
                          <button className="common-btn" style={{ cursor: "pointer" }}
                            onClick={() => handleNavigate(element)}
                          >View Detail</button>
                        </div>
                      </div>
                    </div>
                  )

                })
              }
              {
                !isLoading &&
                <>
                  {allChallenges?.length == 0 &&
                    <div className='no-search-results'>
                      <img src={noChallenges} />
                    </div>

                  }
                </>
              }
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AllChallenges;