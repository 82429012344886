import React, { useState } from 'react';
import { favRed, favouritesDark, playS, profile_image_placeholder, seeAllArrow } from '../../Constants/images';
import './featured.css';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';

function Featured(props) {

  const navigate = useNavigate()
  return (
    <div>
      <section className="featured response-padding py-60">
        <div className="container">
          <div className="chanel-header">
            <h3 className="fw-600">Featured</h3>
          </div>
          <div className="challene-wrapper mt-4">
            <div className=" row">
              {
                props?.featuredList?.data?.slice(0,3)?.map((featured) => {
                  return (
                    <div className="featured-box-cus position-relative col-lg-4 mb-4" style={{ cursor: 'pointer' }} onClick={() => navigate(ROUTES.SERIES_DETAIL, { state: { id: featured?.id } })}  >
                      <div className="video-bg video-wrapr">
                        <div className="video-content" >
                          <img
                            src={featured?.thumbnail}
                            className="img-video img-fluid"
                            alt=""
                          />
                        </div>
                        <div className='content-video-feat'>
                          <h4 className="mt-3" onClick={() => navigate(ROUTES.SERIES_DETAIL, { state: { id: featured?.id } })} >{featured?.title}</h4>
                          <div className="video-detail"  >
                            <div>
                              <img
                                onClick={() => navigate(ROUTES.SERIES_DETAIL, { state: { id: featured?.id } })}
                                src={featured?.channelDetail?.profile_pic ? featured?.channelDetail?.profile_pic : profile_image_placeholder}
                                className="bite-inner-img chllge-img"
                                alt=""
                              />
                            </div>
                            <div onClick={() => navigate(ROUTES.SERIES_DETAIL, { state: { id: featured?.id } })} >
                              <h5 className="mb-0 fw-600">{featured?.channelDetail?.name}</h5>
                              <p className="mb-0">{featured?.category?.name}</p>
                            </div>

                          </div>
                        </div>

                      </div>
                      {/* <div className="play-icon">
                        <img src={playS} alt='' />
                      </div> */}
                    </div>
                  )

                }, [])
              }
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Featured;