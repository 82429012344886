import React, { useState } from 'react';
import { crosstheme, modalCross } from '../../Constants/images';

const TagModal = ({ setTagModal, tags,setTags,setErrors}) => {
  const [currentTag, setCurrentTag] = useState('');
  const handleTagChange = (e) => {
    setCurrentTag(e.target.value);
  };

  const handleAddTag = () => {
    if (currentTag.trim() !== '' && !tags.includes(currentTag)) {
      setTags((prevTags) => [...prevTags, currentTag]);
      setCurrentTag('');
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };

  const handleSave = () => {
    setErrors("")
    setTagModal(false);
  };

  return (
    <div className="modal fade show modal-cast" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
      <div className='loader'></div>
      <div className="modal-dialog modal-dialog-centered modal-schedule-width">
        <div className="modal-content modal-radius  border-0">
          <div className="modal-header forgot-header align-items-center pt-4">
            <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Add Tags</h3>
            <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close">
              <img
                style={{ cursor: "pointer" }}
                src={modalCross}
                alt=""
                className="img-fluid btn-cloesed"
                onClick={() => {
                    setErrors("")
                    setTags([]) 
                    setTagModal(false)}}
              />
            </a>
          </div>
          <div className="modal-body  verify-modal-box px-2 px-sm-5">
            <form>
              <label className='fw-500 f18-size text-black pb-2'>Tags</label>
              <div className='row'>
                <div className='col-sm-10'>
                  <input
                    type='text'
                    className='border-edit w-100'
                    placeholder='Enter Here'
                    value={currentTag}
                    onChange={handleTagChange}
                  />
                  <div className="d-flex align-items-center flex-wrap my-2 gap-2 mt-3">
                    {tags.map((tag, index) => (
                      <div key={index}>
                        <span className="input-suggestion d-flex align-items-center gap-3 f16-size fw-500 ">
                          {tag}
                          <img
                            src={crosstheme}
                            alt="cross"
                            onClick={() => handleDeleteTag(tag)}
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='col-sm-2 mt-4 mt-sm-0 ps-sm-0'>
                  <button type="button" className='tag-add' onClick={handleAddTag}>
                    Add
                  </button>
                </div>
              </div>
              <div className=" my-4 pt-5">
                <input type='button' value="Save" className="common-btn w-100" onClick={handleSave} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagModal;
