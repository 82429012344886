import React, { useState } from "react";
import "../../Pages/UserSocialFeeds/userSocialFeeds.css"
import { Send_feed, dotsVertical, dotshorizontal, dummy, heartfill, like, message } from "../../Constants/images";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../Helper/route-helper";
import ShareModal from "./shareModal";
import UserDetailModal from "./userDetailModal";
const UserSocialFeeds = () => {
    const navigate = useNavigate()
    const [shareModal, setShareModal] = useState(false)
    const [detailModal, setDetailModal] = useState(false)

    return (
        <>
            <div>
                <div className="main-inner-content">
                    <div className="row">
                        <div className="col-md-9 ">
                            <div className="center-content">
                                <div className="center-container py-4">
                                    <div className="social_feed_main">
                                        <div className="feed_details mb-4" >
                                            <div class="feed-user" >
                                                <a href="" class=" text-decoration-none text-black">
                                                    <img src={dummy} class="userimg" />
                                                </a>
                                                <div className="">
                                                    <a href="" class=" text-decoration-none text-black">
                                                        <h4 class="fw-600 mb-2 lh-1">John Smith
                                                        </h4>
                                                    </a>
                                                    <p class="fw-500  mb-0 side_text lh-1">15 hours ago</p>
                                                </div>
                                                <div class="post-options">
                                                    <img src={dotshorizontal} class="btn pl-2 pr-0 py-2" type="button" id="postdrop" />
                                                </div>
                                            </div>
                                            <div className="feed_image" style={{ cursor: "pointer" }} onClick={() => setDetailModal(true)}>
                                                <img src={dummy} className="feed_inner_images" />
                                            </div>
                                            <div className="social_icon_feed">
                                                <div className="like_feed d-flex align-items-center gap-2">
                                                    <img src={heartfill} />
                                                    <h5 className="total_like mb-0">125</h5>
                                                </div>
                                                <div className="like_feed d-flex align-items-center gap-2">
                                                    <img src={message} />
                                                    <h5 className="total_like mb-0">124</h5>
                                                </div>
                                                <div className="like_feed d-flex align-items-center gap-2" onClick={() => setShareModal(true)}>
                                                    <img src={Send_feed} />
                                                    <h5 className="total_like mb-0">25</h5>
                                                </div>
                                            </div>
                                            <div className="totallikecomment px-4">
                                                <p className="">Liked by <span className="fw-600"> John Smith </span> and <span className="fw-600"> 123 </span>others</p>
                                            </div>
                                            <div className="description_feed px-4">
                                                <p className="mb-0 side-text ">Sorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Seddignis
                                                    sim, metus nec fringilla hgdcu accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus<span className="fw-600 blue-text   "> More...</span></p>
                                            </div>
                                        </div>
                                        <div className="feed_details">
                                            <div class="feed-user">
                                                <a href="" class=" text-decoration-none text-black">
                                                    <img src={dummy} class="userimg" />
                                                </a>
                                                <div className="">
                                                    <a href="" class=" text-decoration-none text-black">
                                                        <h4 class="fw-600 mb-2 lh-1">John Smith
                                                        </h4>
                                                    </a>
                                                    <p class="fw-500  mb-0 side_text lh-1">15 hours ago</p>
                                                </div>
                                                <div class="post-options">
                                                    <img src={dotshorizontal} class="btn pl-2 pr-0 py-2" type="button" id="postdrop" />
                                                </div>
                                            </div>
                                            <div className="feed_image">
                                                <img src={dummy} className="feed_inner_images" />
                                            </div>
                                            <div className="social_icon_feed">
                                                <div className="like_feed d-flex align-items-center gap-2">
                                                    <img src={heartfill} />
                                                    <h5 className="total_like mb-0">125</h5>
                                                </div>
                                                <div className="like_feed d-flex align-items-center gap-2">
                                                    <img src={message} />
                                                    <h5 className="total_like mb-0">124</h5>
                                                </div>
                                                <div className="like_feed d-flex align-items-center gap-2">
                                                    <img src={Send_feed} />
                                                    <h5 className="total_like mb-0">25</h5>
                                                </div>
                                            </div>
                                            <div className="totallikecomment px-4 pt-3 d-flex align-items-center gap-2">
                                                <div className="">
                                                    <img src={heartfill} className=""></img>
                                                    <img src={heartfill} className="img_like"></img>
                                                    <img src={heartfill} className=""></img>
                                                </div>
                                                <p className="mb-0">Liked by <span className="fw-600"> John Smith </span> and <span className="fw-600"> 123 </span>others</p>
                                            </div>
                                            <div className="description_feed px-4">
                                                <p className="mb-0 side-text ">Sorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Seddignis
                                                    sim, metus nec fringilla hgdcu accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus<span className="fw-600 blue-text   "> More...</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="col-md-3 mt-4 mt-md-0 ">
                            <div class=" right-aside following_tab">
                                <div className="following_heading">
                                    <h5 className="mb-0 fw-600">Following</h5>
                                </div>
                                <div className="">
                                    <ul className="following_listing ps-0">
                                        <li>
                                            <div className="following_main">
                                                <div className="following_profile">
                                                    <img src={dummy}></img>
                                                </div>
                                                <div className="following_text">
                                                    <p className="mb-0 blue-text fw-500 f16-size">John Smith</p>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {shareModal && <ShareModal setShareModal={setShareModal} />}
            {detailModal && <UserDetailModal setDetailModal={setDetailModal}/>}
        </>


    )

}
export default UserSocialFeeds