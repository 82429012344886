import React, { useState } from 'react';
import { favRed, favouriteLight, seeAllArrow } from '../../../../Constants/images';
import apiHelper from '../../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../../../Helper/config';
import ROUTES from '../../../../Helper/route-helper';
import { useNavigate } from 'react-router-dom';


function MoreMuvi(props) {
  const navigate = useNavigate()
  const [_, forceUpdate] = useState(0)

  async function addFavourites(item) {
    if (item.is_fav == 0) {
      item.is_fav = 1
    } else {
      item.is_fav = 0
    }
    let data = {
      "series_id": item?.id,
      "type": "2"
    }
    forceUpdate(Math.random())
    let result = await apiHelper.postRequest("channel/add-favorites", data)
    if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
    }
  }

  const handleClick = (item) => {
    props?.setId(item)
  }


  return (
    <div>
      <section className="more-movies mb-5">
        <div className="container">
          {
             props?.moreSeries?.length > 0 && <div className="chanel-header">
             <h3 className="fw-600 mb-0">More From {props?.seriesNew?.channelDetail?.name}</h3>
             <button onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=moreMovie", { state: { item: props?.seriesNew } })} className="see-all fw-600 f18-size">
               See all
               <img src={seeAllArrow} alt="" className="ms-2" />
             </button>
           </div>
 
          }
          
          <div className="muvi-slider-main mt-5">
            {
              props?.moreSeries?.map((item) => {
                return (
                  <div key={item?.id} className="item" style={{ cursor: "pointer" }}>
                    <div className="muvi-slider-slider positon-relative"  >
                      <img
                        src={item?.thumbnail}
                        className="muvi-slid-img br30"
                        alt=""
                        onClick={() => handleClick(item?.id)} />
                      <div className="muvi-slider-title" onClick={() => handleClick(item?.id)}>
                        <h5 className="white mb-0 fw-600">{item?.title}</h5>
                        <p className="white mb-0 fw-500">{item?.episodes_count}episodes</p>
                      </div>
                      <div onClick={() => addFavourites(item)} className="like-btn-muvi">
                        {
                          item?.is_fav == 1 ?
                            <img src={favRed} /> :
                            <img src={favouriteLight} />
                        }
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
    </div>
  )
}

export default MoreMuvi;