import React, { useState } from 'react'
import { dummy, live_calender, live_clock, no_live } from '../../Constants/images'
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';
import PriceTag from '../../CreaterDashboard/component/price-tag';
import BuyModal from './buyModal';

function ShopLives({ setTabSwitch, loading, detailList, setLoading, getDetailApi, check }) {
    const navigate = useNavigate()
    const [buyModal, setBuyModal] = useState(false)
    const [item, setItem] = useState({})

    const dateFunction = (date) => {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) {
            return "";
        }
        const formattedDate = parsedDate.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        }).replace(/\//g, '-');
        return formattedDate;
    }

    function addTime(originalDateTimeString) {
        let originalDateTime = new Date(originalDateTimeString);
        // originalDateTime.setHours(originalDateTime.getHours() + 5);
        // originalDateTime.setMinutes(originalDateTime.getMinutes() + 30);
        let modifiedTime = originalDateTime.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        return modifiedTime;
    }

    const handleClick = (item) => {
        console.log(item, "item")
        localStorage.setItem("item", JSON.stringify(item))
        if (check == "shop") {
            navigate(ROUTES.USER_RECORDED_VIDEO_SHOP)
        }
        else if (check == "download"
        ) {
            navigate(ROUTES.USER_RECORDED_VIDEO_DOWNLOAD)

        }
    }

    const handleOpen = (item) => {
        setItem(item)
        setBuyModal(true)
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="stream-btn pt-3 pb-4">
                        <button className="common-btn btn-white left-corner" onClick={() => {
                            setTabSwitch(0)
                        }}>Series</button>
                        <button className="common-btn btn-blue left-corner" onClick={() => setTabSwitch(1)}>Recorded Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(2)}>Upcoming Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(3)}> Images</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(4)}>Audios</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(5)}>Videos</button>
                    </div>
                    <div className='row mt-4'>
                        {detailList?.length > 0 ? (
                            <>
                                {detailList?.map((item) => (
                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4"
                                        key={item?.id}
                                    >
                                        <div className="latest-video mx-auto" >
                                            <div className='position-relative'
                                            >
                                                <div
                                                    className="latest-video-content  pt-2 pt-sm-3 px-2 px-sm-3"
                                                    style={{ cursor: "pointer" }}>
                                                    <img
                                                        src={item.thumbnail}
                                                        className="img-fluid latest-vid-img new-latest-video"
                                                        alt="thumbnail"
                                                    />
                                                    {
                                                        item?.is_purchase == 0 && <PriceTag classes={"price_tag"} firstValue={`$${item.price}`} secondValue={"00"} />
                                                    }

                                                </div>
                                            </div>
                                            <div className="latest-video-cus px-3 py-3 schedule-grid">
                                                <div className=''>
                                                    <h4 className="schedule-title">{item.name} </h4>
                                                    <div className="timing-flex">
                                                        <div className='mb-0 timing-inner-flex'>
                                                            <img src={live_calender} alt="calendar" />
                                                            <span className='ps-1'>
                                                                {dateFunction(item.start_time)}

                                                            </span>
                                                        </div>
                                                        <div className='mb-0 timing-inner-flex'>
                                                            <img src={live_clock} alt="clock" />
                                                            <span className='ps-1'>
                                                                {addTime(item.start_time)}

                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='dotschedule bg-transparent'>
                                                    <div className='dropdownschedule'>
                                                        {
                                                            item.is_purchase == 0 ? <button onClick={() => handleOpen(item)} className="common-btn watch-btn">
                                                                <span className='me-2'>Buy Now</span>
                                                            </button> : <button onClick={() => handleClick(item)} className="common-btn watch-btn">
                                                                <span className='me-2'>View</span>
                                                            </button>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : <>
                            {
                                loading == false &&
                                (
                                    <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                        <img src={no_live} alt="no live" />
                                    </div>
                                )
                            }
                        </>
                        }

                        {
                            buyModal && <BuyModal item={item} setLoading={setLoading} getDetailApi={getDetailApi} setBuyModal={setBuyModal} check="live" />
                        }
                    </div>

                </div>
            </div>

        </>
    )
}

export default ShopLives