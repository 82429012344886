import AWS from 'aws-sdk';
import { S3_CREDS } from "../../Helper/config";

AWS.config.update({
    accessKeyId: S3_CREDS.AWS_ACCESS_KEY_ID,
    secretAccessKey: S3_CREDS.AWS_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3({
    params: { Bucket: S3_CREDS.AWS_BUCKET },
    region: S3_CREDS.AWS_DEFAULT_REGION,
});

const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};

const generateFileNameWithTimestamp = (file) => {
    const timestamp = Date.now();
    const randomString = generateRandomString(8);
    const fileExtension = file.name === 'undefined' ? "jpg" : file.name.split('.').pop();
    return `${randomString}_${timestamp}.${fileExtension}`;
};

const uploadToS3 = async (thumbnailFile, videoFile, stateToUpdate, setProgress) => {
    try {
        setProgress(0);

        const uploadFileToS3 = async (file, type) => {
            const fileName = generateFileNameWithTimestamp(file);
            const params = {
                Bucket: S3_CREDS.AWS_BUCKET,
                Key: fileName,
                Body: file,
                ContentType: file.type,
            };

            const upload = s3.upload(params).on("httpUploadProgress", (evt) => {
                const percentage = Math.round((evt.loaded / evt.total) * 100);
                setProgress(percentage);
            }).promise();

            await upload.then((data) => {
                const uploadUrl = data.Location;
                if (type === 'video') {
                    stateToUpdate.videoLocation = uploadUrl;
                } else if (type === 'thumbnail') {
                    stateToUpdate.thumbnailLocation = uploadUrl;
                }
            }).catch((err) => {
                console.error("Error uploading to S3:", err);
            });
        };
        if (videoFile) {
            await uploadFileToS3(videoFile, 'video');
        }

        if (thumbnailFile) {
            await uploadFileToS3(thumbnailFile, 'thumbnail');
        }
        setProgress(100);
    } catch (error) {
        console.error("Error uploading to S3:", error);
    }
};

export default uploadToS3;
