import React, { useState } from 'react'
import { playLight, image1, videoPlayButton, favouriteLight, playLightBtn, favRed, favGrey } from '../../../../../Constants/images';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../../Helper/route-helper';
import apiHelper from '../../../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../../../../Helper/config';
import '../../../../ChannelDetails/channelDetails.css'


function SearchSeries(props) {
  const navigate = useNavigate()
  const [_, forceUpdate] = useState(0)
  async function addFavourites(item) {
    if (item.is_fav == 0) {
      item.is_fav = 1
    } else {
      item.is_fav = 0
    }
    let data = {
      "series_id": item?.id,
      "type": "2"
    }
    forceUpdate(Math.random())
    let result = await apiHelper.postRequest("channel/add-favorites", data)
    if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
    }
  }

  return (
    <div className="row" style={{ marginTop: 20 }}>
      {
        props?.seriesList?.map((item) => {
          return (
            <div key={item?.id} className="col-12 col-sm-6 col-lg-4 col-xl-4 pb-4">
              <div className="latest-video mx-auto ">
                <div className='position-relative'>
                  <div
                    className="latest-video-content  pt-2 pt-sm-3 px-2 px-sm-3 "
                    style={{ cursor: "pointer" }} onClick={() => navigate(ROUTES.SERIES_DETAIL, { state: { id: item?.id } })}>
                    <img
                      src={item?.thumbnail}
                      className="img-fluid latest-vid-img img-width"
                    />

                  </div>
                  <div onClick={() => addFavourites(item)} className="series-like-btn ">
                    {
                      item?.is_fav == 1 ?
                        <img src={favRed} /> :
                        <img src={favGrey} className='like-light' />
                    }
                  </div>
                </div>
                <div className="latest-video-cus px-3 py-3">
                  <h4 className="fw-600">{item?.title}</h4>
                  <div className="latest-series-name series-latest-name">
                    <div className="video-tile">
                      <p className='mb-0 fw-400'>{item?.episodes_count} episodes</p>
                      <p className='mb-0 fw-500'>{item?.year}</p>
                    </div>
                    <button onClick={() => navigate(ROUTES.SERIES_DETAIL, { state: { id: item?.id } })} className="common-btn watch-btn series-watch">
                      <span className='me-2'>Watch</span><img
                        src={playLightBtn}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }

    </div>
  )
}

export default SearchSeries;