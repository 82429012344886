import React from 'react';
import { useNavigate } from 'react-router-dom';
import { favRed, profile_image_placeholder } from '../../Constants/images';
import ROUTES from '../../Helper/route-helper';
import './favChannels.css'
import apiHelper from '../../Helper/api-helper';

function AllFavChannels({ setTabSwitch, favChannels, getDetailsAPI, setIsLoading }) {
    const navigate = useNavigate()
    async function addFavourites(item) {
        setIsLoading(true)
        let data = {
            "channel_id": item?.channel_id,
            "type": 1
        }
        let result = await apiHelper.postRequest("channel/add-favorites", data)
        if (result == undefined) {
            setIsLoading(false)
            getDetailsAPI()
        }
    }

    return (
        <div>
            <section className="streaming py-6 response-padding ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="">
                            <h3 className="fw-600">Favourites</h3>
                        </div>
                        <div className="stream-btn pt-3">
                            <button onClick={() => setTabSwitch(0)} className="common-btn btn-white">Series</button>
                            <button onClick={() => setTabSwitch(1)} className="common-btn btn-blue">Channel</button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-60  favchannel-main-cus">
                <div className="container-fluid">
                    <div className="row">
                        {
                            favChannels?.map((channels) => {
                                return (
                                    <div key={channels?.channel_id} className="col-12 col-sm-6 col-lg-6 col-xl-3 mb-4 position-relative">
                                        <div className="channels-box  mb-3 mb-lg-3 mb-xl-0" style={{ cursor: 'pointer' }} onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=favChannel", { state: { item: channels } })}>
                                            <img src={channels?.profile_pic ? channels?.profile_pic : profile_image_placeholder} className="img-fluid channel-img" />
                                            <h4 className="channel-text fw-600 my-2">{channels?.name}</h4>
                                            {/* <p className="channel-para mb-0">{channels?.channel?.category?.name}</p> */}
                                        </div>
                                        <div className="like-icon " onClick={() => addFavourites(channels)}>
                                            <img src={favRed} className="img-fluid fill-icon " />
                                        </div>
                                    </div>
                                )
                            })
                        }


                    </div>
                </div>
            </section>

        </div>
    )
}

export default AllFavChannels;