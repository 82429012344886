import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import ERR_MESSAGE from '../../Helper/error-helper';
import { NotificationManager } from 'react-notifications';
import { eye, closeEye, google, signRight, signCircle, signCloud, squareSign, vectorSign, bodySign, signBg, line, modalForgot, facebook1, logoPurple } from './../../Constants/images';
import apiHelper from '../../Helper/api-helper';
import ReactLoader from '../../ControlComponents/react-loader';
import DEVELOPMENT_CONFIG from '../../Helper/config'
import ForgotPassword from './Components/ForgotPassword/Forgot';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import VerifyEmail from './Components/VerifyEmail/VerifyEmail';
import VerifyEmail2 from '../Signup/components/VerifyEmail2';
import './signin.css'
import ROUTES from '../../Helper/route-helper';

const SignIn = () => {
    const [errors, setErrors] = useState("");
    const [forgot, setForgot] = useState(false);
    const [verifyEmail, setVerifyEmail] = useState(false);
    const [verifyEmail2, setVerifyEmail2] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);
    const [getEmail, setGetEmail] = useState("");
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [fields, setFields] = useState({
        email: "",
        password: "",
    });

    const handleChange = (e) => {
        setErrors("")
        const name = e.target.name;
        const value = e.target.value;
        setFields({
            ...fields,
            [name]: value,
        });
    };

    function handleForgot() {
        setForgot(!forgot);
    }

    function getEmailFn(email) {
        setGetEmail(email);
    }

    const [type, setType] = useState("password");
    const [eye_icon, setEye_icon] = useState(closeEye);
    const [msg, setMsg] = useState("reveal password")

    const show = () => {
        if (type === "password") {
            setType("text");
            setEye_icon(eye);
            setMsg("hide password")
        }
        else {
            setType("password");
            setEye_icon(closeEye);
        }
    }



    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        const { email, password } = fields;
        if (!email || email.trim().length === 0) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_EMPTY
        }
        else if (reg.test(email.toLowerCase()) === false) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_INVALID
        }
        if (!password || password.trim().length === 0) {
            formIsValid = false;
            errors["password"] = ERR_MESSAGE.PASSWORD_EMPTY
        }
        setErrors(errors);
        return formIsValid;
    };

    async function postAPI(e) {
        e.preventDefault();
        if (handleValidation()) {
            let data = JSON.stringify({
                "email": fields.email,
                "password": fields.password
            });
            setLoading(true);
            let result = await apiHelper.postRequest("auth/log-in", data)
            if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", result?.data?.token);
                localStorage.setItem("refresh-token", result?.data?.refresh_token);
                getDetailsAPI()
                setLoading(false)
            } else {
                setLoading(false)
                NotificationManager.error(result?.message)
            }
        }
    }

    async function getDetailsAPI() {
        if (handleValidation()) {
            setLoading(true);
            let result = await apiHelper.getRequest("auth/get-my-detail")
            if (result?.data?.is_verified !== 1) {
                setLoading(false)
                setVerifyEmail2(true)
            }
            else if (result.code === DEVELOPMENT_CONFIG.statusCode && result?.data?.is_verified === 1) {
                setLoading(false)
                if (result?.data?.role === 2) {
                    navigate(ROUTES.CREATER_DASHBOARD)
                }
                else {
                    navigate(ROUTES.HOME)
                }
                localStorage.setItem("role", result?.data?.role)
                localStorage.setItem("creator_id", result?.data?.id);
            } else {
                setLoading(false)
            }
        }
    }

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className='loader'>
                {isLoading && <ReactLoader />}
            </div>
            <section className="login-wrapper signin-form" style={{ backgroundImage: `url(${bodySign})` }}>
                <div className="container">
                    <div className="row">
                        <div className=" col-12 col-lg-10 m-auto">
                            <div className="login-width" style={{ backgroundImage: `url(${signBg})` }}>
                                <div className='logo-sign text-center d-block d-lg-none'>
                                    <img src={logoPurple} className='img-fluid'></img>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-5 px-2 px-lg-0">
                                        <div className="sign-form  position-relative">
                                            <div id="exTab1" className="container">
                                                <ul className="nav nav-pills list-styled d-flex justify-content-around">
                                                    <li className="active">
                                                        <a className=" fw-600">SIGN IN </a>
                                                    </li>
                                                    <li><Link to="/sign-up" className=" fw-600">SIGN UP</Link>
                                                    </li>
                                                </ul>
                                                <div className="tab-content clearfix">
                                                    <div className="tab-pane active pt-0 pt-lg-5" id="signin">
                                                        <form onSubmit={postAPI}>
                                                            <div className="form-group mt-2">
                                                                <input type="text" name='email' value={fields.email} onChange={handleChange} error={errors.email} className="form-control input-login fw-500" placeholder="Email" />
                                                                <span className="err_msg">{errors.email}</span>
                                                            </div>
                                                            <div className="form-group mt-4 position-relative fwef">
                                                                <div className="eye-closed">
                                                                    <img src={eye_icon} onClick={show} className="img-fluid" title={msg} />
                                                                </div>
                                                                <input type={type} name='password' value={fields.password} onChange={handleChange} error={errors.password} className="form-control input-login fw-500 " placeholder="Password" />
                                                                <span className="err_msg">{errors.password}</span>
                                                            </div>
                                                            <div className="text-end mt-2 forgot-btn">
                                                                <a onClick={handleForgot} className="blue-text fw-500">Forgot Password?</a>
                                                                {/* <a className="blue-text fw-500" data-bs-toggle="modal" data-bs-target="#Forgot">Forgot Password?</a> */}

                                                            </div>

                                                            <div className="login-btn mt-4 mt-lg-5">
                                                                {/* <a href="" className="common-btn w-100" >Login</a> */}
                                                                <input className="common-btn w-100" type='submit' value="Sign In" />

                                                            </div>
                                                            <div className="footer-login text-center">
                                                                <p className="or-bg mt-3 mb-2" style={{ backgroundImage: `url(${line})` }}><span>OR</span></p>
                                                                <a className="blue-text fw-500" href="">Sign in with</a>
                                                                <div className="social-link mt-3 d-flex justify-content-center align-items-center gx-3">
                                                                    <span><img src={google} className="img-fluid" /><span>Google</span></span>
                                                                    <span><img src={facebook1} className="img-fluid" /><span>Facebook</span></span>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-7 px-0 d-none d-lg-block">
                                        <div className="h-100 position-relative">
                                            <img src={signRight} className="right-login" />
                                            <img src={squareSign} className="square-box" />
                                            <img src={signCloud} className="cloud-sign" />
                                            <img src={vectorSign} className="vector-sign" />
                                            <img src={signCircle} className="circle-sign" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {forgot && <ForgotPassword handleForgot={handleForgot} setVerifyEmail={setVerifyEmail} getEmailFn={getEmailFn} />}
                {verifyEmail && <VerifyEmail setVerifyEmail={setVerifyEmail} setResetPassword={setResetPassword} getEmail={getEmail} />}
                {resetPassword && <ResetPassword setResetPassword={setResetPassword} getEmail={getEmail} />}
                {verifyEmail2 && <VerifyEmail2 setVerifyEmail2={setVerifyEmail2} email={fields.email} />}
            </section>
        </>
    )
}

export default SignIn;