import React, { useState } from 'react'
import { SCHEDULE_DOT, no_images, no_live } from '../../Constants/images'
import { Dropdown } from 'react-bootstrap'
import PriceTag from '../component/price-tag'
import ReactLoader from '../../ControlComponents/react-loader'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../Helper/config"
import DeleteShopModal from './deleteModal'
import EditModal from './editModal'
import { NotificationManager } from 'react-notifications'
import ROUTES from '../../Helper/route-helper'
import AddDetailModal from './detailImageModal'
import AddImageModal from './addImageModal'
function Images({ setTabSwitch, imagesList, getImagesAPI }) {
    const [loading, setIsLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deltId, setDeltId] = useState(null)
    const [detailOpen, setDetailOpen] = useState(false)
    const [addImageModal, setAddImageModal] = useState(false)

    const handleEdit = (item) => {
        setDeltId(item.id)
        setAddImageModal(true)
    }

    async function getDeleteAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("shop/delete-shop?shop_id=" + deltId + "&shop_type=image")
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setIsLoading(false)
            setDeleteModal(false)
            if (result.message == "Shop deleted successfully") {
                NotificationManager.success("Image deleted successfully")
            }
            else {
                NotificationManager.success(result.message)
            }
            getImagesAPI()
            setDetailOpen(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
        else {
            NotificationManager.error(result.message)

        }
    }



    const handleDelete = (id) => {
        setDeltId(id)
        setDeleteModal(true)
    }


    const hanldeClick = (item) => {
        setDeltId(item.id)
        setDetailOpen(true)
    }

    return (
        <>
            {loading && <ReactLoader />}

            <div className="container-fluid">
                <div className="row">
                    <div className="stream-btn pt-3 pb-4">
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(0)}>Series</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(1)}>Recorded Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(2)}>Upcoming Lives</button>
                        <button className="common-btn btn-blue left-corner" onClick={() => setTabSwitch(3)}> Images</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(4)}>Audios</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(5)}>Videos</button>

                    </div>
                    <div className='row mt-4'>
                        {imagesList?.length > 0 ? (
                            <>
                                {
                                    imagesList?.map((item) => (
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4"
                                            key={item?.id}
                                        >
                                            <div className="latest-video mx-auto" >
                                                <div className='position-relative'
                                                >
                                                    <div
                                                        onClick={() => hanldeClick(item)}
                                                        className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3"
                                                        style={{ cursor: "pointer" }}>
                                                        <img
                                                            src={item.shop_media[0]?.media_url}
                                                            className="img-fluid latest-vid-img new-latest-video"
                                                            alt="thumbnail"
                                                        />
                                                        <PriceTag classes={"price_tag"} firstValue={`$${item?.price}`} secondValue={"00"} />

                                                    </div>
                                                    <div className='dotschedule bg-transparent images-dropdown'>
                                                        <div className='dropdownschedule'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle id="custom-dropdown-toggle">
                                                                    <img src={SCHEDULE_DOT} className='img-fluid' alt="dot" />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu className='record-card-dropdown'>
                                                                    <Dropdown.Item
                                                                        onClick={() => handleEdit(item)}
                                                                    >Edit</Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        onClick={() => handleDelete(item.id)}
                                                                    >Delete</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="latest-video-cus px-3 py-3 schedule-grid" onClick={() => hanldeClick(item)}
                                                >
                                                    <div className=''
                                                    >
                                                        <h4 className="schedule-title">{item.title} </h4>
                                                        <h6 className="images-count">
                                                            {item.shop_media.length} {item.shop_media.length == 1 ? "Image" : "Images"}
                                                        </h6>
                                                        <p className="images-description" >
                                                            {item.description}                                                  </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </>

                        ) : <>
                            {
                                loading == false &&
                                (

                                    <div style={{ flexDirection: 'column' }} className='no-search-results position-relative'>
                                        <img src={no_images} alt="no image" />
                                        <h6 className='position-absolute' style={{ bottom: 6 }}>No Images yet</h6>
                                    </div>
                                )
                            }
                        </>
                        }
                    </div>
                </div>
                {
                    deleteModal && <DeleteShopModal setDeleteModal={setDeleteModal} getDeleteAPI={getDeleteAPI} check="image" />
                }
                {
                    addImageModal && <AddImageModal setAddImageModal={setAddImageModal} setTabSwitch={setTabSwitch} deltId={deltId} getImagesAPI={getImagesAPI} />
                }
                {
                    detailOpen && <AddDetailModal setTabSwitch={setTabSwitch} setDetailModal={setDetailOpen} deltId={deltId} getDeleteAPI={getDeleteAPI} setDeleteModal={setDeleteModal} deleteModal={deleteModal} />
                }
            </div>
        </>
    )
}

export default Images