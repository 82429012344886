import React, { useState, useEffect } from 'react'
import { clinet_demo, dummy, modalCross, noBites, playLightBtn } from '../../Constants/images'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../Helper/config"
import ReactLoader from '../../ControlComponents/react-loader'
import VideoPlayModal from '../../CreaterDashboard/Shop/videoPlay'

const AddDetailVideoModal = ({ setAddDetailVideoModal, deltId }) => {
    const [detailList, setDetailList] = useState()
    const [loading, setIsLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [videoPlay, setVideoPlay] = useState(false)
    const [url, setUrl] = useState("")
    
    async function getDetailAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`shop/get-shop-media?shopType=video&shop_id=${deltId}`)
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setIsLoading(false)
            setDetailList(result.data)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getDetailAPI()
    }, [refresh])

    return (
        <>
            {
                loading && <ReactLoader />
            }
            <div className="modal fade show modal-cast" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
                <div className='loader'>
                </div>
                <div className="modal-dialog modal-lg  modal-dialog-centered" style={{ justifyContent: "center" }}>
                    <div className="modal-content modal-radius  border-0">
                        <div className="modal-header forgot-header align-items-center pt-4">
                            <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Detail</h3>
                            <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setAddDetailVideoModal(false)
                                }}
                                src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                        </div>
                        <div className="modal-body d-flex flex-column justify-center align-items-center gap-3  verify-modal-box px-2 px-sm-5">
                            <form className='price-form-input'>
                                <div className='d-flex justify-content-between'>
                                    <div>                                <h4>{detailList?.title}</h4>
                                        <h6 className="images-count">
                                            {detailList?.shop_media?.length} {detailList?.shop_media?.length == 1 ? "Audio" : "Audios"}
                                        </h6>
                                    </div>
                                    <div className='detail-main'><span className='first-price-detail'>${detailList?.price}.</span><span className='second-price-detail'>00</span></div>
                                </div>
                                <p className="image-detail-description" >
                                    {detailList?.description}
                                </p>
                                <h4>Videos</h4>
                                <div className='d-flex align-items-start gap-3 flex-wrap overflow-scroll justify-content-center justify-content-sm-start' style={{ maxHeight: 350,cursor:"pointer"}}>


                                    {
                                        detailList?.shop_media?.length > 0 && detailList?.shop_media?.map((item) => (
                                            <div className='position-relative' onClick={() => {
                                                setVideoPlay(true)
                                                setUrl(item.media_url)
                                            }}
                                            style={{cursor:"pointer"}}>
                                                <div className='multi-images '>
                                                    <img src={item.media_thumbnail}></img>
                                                </div>
                                                <img className='detail-video-play' src={playLightBtn} />
                                            </div>

                                        ))
                                    }

                                </div>

                              
                            </form>
                        </div>
                     
                        {
                            videoPlay && <VideoPlayModal setVideoPlay ={setVideoPlay } url={url}/>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddDetailVideoModal
