import React from 'react'
import { profile_image_placeholder } from '../../Constants/images';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';

function MostPopular(props) {
const navigate = useNavigate()
    return (
        <div>
            <section className="recoomended-main-cus recomended response-padding">
                <div className="container">
                    <div className="chanel-header">
                        <h3 className="fw-600 mb-0">Most Popular</h3>
                    </div>
                    <div className="chanel-wrapper mt-4">
                        <div className="row">
                            {
                                props?.featuredList?.mostpopular?.map((featured) => {
                                    return (
                                        <div onClick={() => navigate(ROUTES.SERIES_DETAIL, { state: { id: featured?.id } })}  key={featured?.id} style={{ cursor: "pointer" }} className=" col-12 col-md-6 col-xl-4  col-xxl-3 response-col">
                                            <div className="chanel-content dis-recommend-cus d-block">
                                                <div className="bite-relative">
                                                    <img
                                                        src={featured?.thumbnail}
                                                        className="bite-img popular-img"
                                                        alt=""
                                                    />
                                                </div>
                                                <h5 style={{position:'absolute',top:135,left:25,color:"white",}}>{featured?.channelDetail?.name}</h5>

                                                <div className="bite-wrapper mt-2">
                                                    <h4>{featured?.title}</h4>
                                                    <div className="chanel-text d-flex gap-3 align-items-center p-height">

                                                        <div>
                                                            <p className=''>{featured?.category?.name}</p>
                                                            <p className=''>
                                                                {featured?.episodes_count} {featured.episodes_count == 1 ? "Episode" : "Epsiodes"}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='bites-recoomend-bottom'>
                                                    <img
                                                        src={featured?.channelDetail?.profile_pic ? featured?.channelDetail?.profile_pic : profile_image_placeholder}
                                                        className="bite-inner-img"
                                                        alt=""
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    )

                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MostPopular;