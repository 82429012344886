import React, { useEffect, useState } from 'react';
import { live_calender, live_clock, no_live } from '../../Constants/images';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../Helper/config"
import ReactLoader from '../../ControlComponents/react-loader';
function ScheduleCreator({getApi}) {
    const [loading, setIsLoading] = useState(false)
    const [scheduleLives, setScheduleLives] = useState([])
    const [refresh, setRefresh] = useState(false)
    const location = useLocation()
    const [activeTab, setActiveTab] = useState(localStorage.getItem("activeTab") || "0");

    useEffect(() => {
        if (activeTab === "0") {
            navigate(ROUTES.LIVES)
        } 
        else if(activeTab === "1") {
            getApi()
            navigate(ROUTES.LIVES)
        }
        else {
            getScheduleApi();
        }
    }, [activeTab])

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex.toString());
        localStorage.setItem("activeTab", tabIndex.toString());
    };

    async function getScheduleApi() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("live/live-creator-list")
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setScheduleLives(result?.data.upcomingSchedules)
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }


    useEffect(() => {
        getScheduleApi()
    }, [refresh])

    const navigate = useNavigate()
    const dateFunction = (date) => {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) {
            return "";
        }
        const formattedDate = parsedDate.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        }).replace(/\//g, '-');
        return formattedDate;
    }

    function addTime(originalDateTimeString) {
        let originalDateTime = new Date(originalDateTimeString);
        originalDateTime.setHours(originalDateTime.getHours() + 5);
        originalDateTime.setMinutes(originalDateTime.getMinutes() + 30);
        let modifiedTime = originalDateTime.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        return modifiedTime;
    }
    
    const handleClick = (item) => {
        localStorage.setItem("item", JSON.stringify(item))
        navigate(ROUTES.USER_RECORDED_VIDEO)
    }

    return (
        <div>
            {loading && <ReactLoader />}
            
                    <section className="schedule-wrapper">
                        <div className="container-fluid">
                            <section className="schedule-wrapper">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="stream-btn pt-3">
                                            <button onClick={() => handleTabClick(0)} className={`common-btn btn-white ${activeTab === "0" ? "active" : ""}`}>Lives</button>
                                            <button onClick={() => handleTabClick(1)} className={`common-btn btn-white ${activeTab === "1" ? "active" : ""}`}>Recorded Lives</button>
                                            <button onClick={() => handleTabClick(1)} className={`common-btn btn-blue ${activeTab === "2" ? "active" : ""}`}>Upcoming Lives</button>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        {
                                            scheduleLives?.map((item) => (
                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4" key={item.id}>
                                                    <div className="latest-video mx-auto"
                                                        onClick={() => handleClick(item)}
                                                    >
                                                        <div className='position-relative'>
                                                            <div
                                                                className="latest-video-content  pt-2 pt-sm-3 px-2 px-sm-3"
                                                                style={{ cursor: "pointer" }}>
                                                                <img
                                                                    src={item.thumbnail}
                                                                    className="img-fluid latest-vid-img"
                                                                    alt="thumbnail"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="latest-video-cus px-3 py-3 schedule-grid">
                                                            <div className=''>
                                                                <h4 className="schedule-title">{item.name}</h4>
                                                                <div className="timing-flex">
                                                                    <div className='mb-0 timing-inner-flex'>
                                                                        <img src={live_calender} alt="calendar" />
                                                                        <span className='ps-1'>{dateFunction(item.date_time)}</span>
                                                                    </div>
                                                                    <div className='mb-0 timing-inner-flex'>
                                                                        <img src={live_clock} alt="clock" />
                                                                        <span className='ps-1'>{addTime(item.date_time)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        {
                                            (loading == false && scheduleLives?.length == 0) && <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                                <img src={no_live} alt="no live" />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </section >

                        </div>
                    </section>
               
        </div>);

}

export default ScheduleCreator;
