import React from 'react';
import ReactPlayer from 'react-player';
import EpisodeSeriesChat from './episodeChat';

const EpisodeSeriesVideoPlayer = (props) => {
    const videoUrl = props?.video_url;
    const matchedUrl = videoUrl ? videoUrl.match(/src="([^"]+)"/) : videoUrl;
    const embedUrl = matchedUrl ? matchedUrl[1] : videoUrl;
    const handleClick = () => {
        if (props.embededVedio) {
            props.setEmbededVedio(false);
        }
        props?.setShow(!props?.show)
    }

    return (
        <div className="modal-cus modalvideoplayer epispdePlayer " id="videoplayer">
            <div className="container-fluid">
                <a onClick={() => handleClick()} style={{ cursor: "pointer" }} className="m-0 px-0 video-back-btn">
                    <i className="fa fa-chevron-left video-back-control-episode" />
                </a>
                <div className="custom-videoplayer position-relative p-0">
                    {props.embededVedio && embedUrl ? (
                        <div className='video_wrapper'>
                            <ReactPlayer
                                url={embedUrl}
                                controls
                                playing
                                width="100%"
                                height="100%"
                            />
                        </div>
                    ) : (
                        <video playsInline={true} autoPlay={true} src={props?.video_url} controls></video>
                    )}
                    <EpisodeSeriesChat chatOpen={props.chatOpen} creatorName={props.creatorName} creatorImg={props.creatorImg} />
                </div>
                
            </div>
        </div>
    );
};

export default EpisodeSeriesVideoPlayer;
