import React, { useRef, useEffect, useState } from 'react';
import dashjs from 'dashjs';
// import ChatComponent from './chatComponent';
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from '../firebase';
// import FullRecordedVideo from './fullRecordedVideo';
import DEVELOPMENT_CONFIG from "../Helper/config"
import apiHelper from '../Helper/api-helper';
import ReactLoader from '../ControlComponents/react-loader';
import ChatComponent from '../CreaterDashboard/component/chatComponent';

const UserRecordedVideoPlayer = () => {
    const [loading, setIsLoading] = useState(false)
    const item = JSON.parse(localStorage.getItem("item"))
    const [chatId, setChatId] = useState("1")
    const [recordingUrl, setRecordingUrl] = useState(null)
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const videoRef = useRef(null);
    const [videoTimestamp, setVideoTimestamp] = useState(0);
    const [messages, setMessages] = useState([])
    const [video_url, setVideoUrl] = useState()
    const [show, setShow] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [check, setCheck] = useState(false)
    async function getDetailAPI() {
        setIsLoading(true);
        let result = await apiHelper.getRequest("creator/schedule-details?id=" + item.schedule_id);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setIsLoading(false);
            setRecordingUrl(result?.data?.recording?.recording_url)
            const trimmedName = result?.data?.name?.replace(/\s/g, '');
            const id = result?.data?.id
            const chatId = `${id}${trimmedName}`;
            setChatId(chatId)
            setTitle(trimmedName)
            setDescription(result?.data?.description)
            if (result?.data?.recording?.recording_url == null) {
                setCheck(true)
            }
          } else if (result.code === 401) {
            let refreshToken = localStorage.getItem("refresh-token");
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postService("auth/refresh-token", data);
            if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token);
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token);
                setRefresh(true);
            }
        } else {
        }
    }

    useEffect(() => {
        getDetailAPI()
    }, [refresh, check])

    useEffect(() => {
        const q = query(collection(db, "LiveStreamCollection", chatId, "Comments"), orderBy("createdAt", "asc"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const fetchedMessages = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            setMessages(fetchedMessages)
        })
        return () => {
            unsubscribe()
        }
    }, [chatId])

    useEffect(() => {
        const player = dashjs.MediaPlayer().create();
        player.initialize(videoRef.current, recordingUrl, true)
        return () => {
            if (player) {
                player.reset()
            }
        }
    }, [recordingUrl])

    const handleVideoProgress = () => {
        const currentTime = videoRef.current.currentTime;
        setVideoTimestamp(currentTime * 1000);

    }

    const handleClick = () => {
        setVideoUrl(recordingUrl)
        setShow(true)
    }

    useEffect(() => {
        return () => {
            localStorage.removeItem("item");
        };
    }, []);

    return (
        <>
            {loading && <ReactLoader />}

            <div className="main-inner-content">
                <div className='row'>
                    <div className='col-lg-8 first-order record-video'>
                        <video
                            ref={videoRef}
                            controls
                            autoPlay
                            onClick={handleClick}
                            onTimeUpdate={handleVideoProgress}
                        />
                         {/* <div className="livechattitle pt-4">
                            <h3 className='fw-600 text-capitalize'>{title}</h3>
                        </div>
                        <div className='livechatdescription'>
                            <p className='fw-500 f18-size'>{description}</p>
                        </div> */}
                        
                    </div>
                    <div className='col-lg-4 third-order'>
                        <div className='series-chat challenge-chat h-100 cus-series-chat new-cus-chat'>
                            <div className='chat-right position-relative'>
                                <div className='chat-heading rounded-0 btn-blue px-4 '>
                                    <h4 className='mb-0 text-white fw-600'>Live Chat</h4>
                                </div>
                                <div className='chat-message new-chat-msg cusnew-chat-msg recorded-message' >
                                    <ChatComponent videoTimestamp={videoTimestamp} messages={messages} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 second-order">
                        <div className="livechattitle pt-4">
                            <h3 className='fw-600 text-capitalize'>{title}</h3>
                        </div>
                        <div className='livechatdescription'>
                            <p className='fw-500 f18-size'>{description}</p>
                        </div>
                    </div>
                </div>
                {/* <div className="row">
                  
                    <div className="col-md-4">
                        <p>demo text</p>
                    </div>
                </div> */}
                {/* {
                    show && <FullRecordedVideo video_url={video_url} show={show} setShow={setShow} />
                } */}
            </div>
        </>
    )
}

export default UserRecordedVideoPlayer;
