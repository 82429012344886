import DEVELOPMENT_CONFIG from "./config";
import axios from "axios";

export default {
  postRequest: function (url, data) {
    const token = localStorage.getItem("token");
    var config = {
      method: "post",
      url: DEVELOPMENT_CONFIG.base_url + url,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        'api-key': process.env.REACT_APP_API_KEY,
        'accept': 'application/json',
        'app-version': '1.0.1'
      }, 
      data: data,
    };
    
    const response = axios(config)
      .then(async (response) => {
        if (response.data.code === DEVELOPMENT_CONFIG.statusCode) {
          return response?.data
        }
      })
      .catch((error) => {
        return error?.response?.data
      });
    return response;
  },

  postService: async function (url, data) {
    const token = localStorage.getItem("token");
    var config = {
      method: "post",
      url: DEVELOPMENT_CONFIG.base_url + url,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        'api-key': process.env.REACT_APP_API_KEY,
        'accept': 'application/json',
        'app-version': '1.0.1'
      },
      data: data
    };
    try {
      const response = await axios.request(config);
      return response?.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        let refreshToken = localStorage.getItem("refresh-token");
        let refreshData = {
          "refresh_token": refreshToken,
          "scope": "refresh_token"
        };
        try {
          let refreshTokenResult = await this.postRequest("auth/refresh-token", refreshData);
          if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token)
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
            config.headers.Authorization = 'Bearer ' + refreshTokenResult.data.token;
            const retryResponse = await axios.request(config);
            return retryResponse?.data;
          }
        } catch (refreshError) {
          return refreshError;
        }
      }
      return error;
    }
  },
 
  getRequest: function (url, data) {
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: DEVELOPMENT_CONFIG.base_url + url,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        'api-key': process.env.REACT_APP_API_KEY,
        'accept': 'application/json',
        'app-version': '1.0.1'
      },
      data: data,
    };
    
    const response = axios(config)
      .then(async (response) => {
        if (response.data.code === DEVELOPMENT_CONFIG.statusCode) {
          return response?.data
        }
      })
      .catch((error) => {
        return error?.response?.data
      });
    return response;
  },

  postRequest1: function (url, data,token) {
    var config = {
      method: "post",
      url: DEVELOPMENT_CONFIG.base_url + url,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        'api-key': process.env.REACT_APP_API_KEY,
        'accept': 'application/json',
        'app-version': '1.0.1'
      }, 
      data: data,
    };
    
    const response = axios(config)
      .then(async (response) => {
        if (response.data.code === DEVELOPMENT_CONFIG.statusCode) {
          return response?.data
        }
      })
      .catch((error) => {
        return error?.response?.data
      });
    return response;
  },
   
  getRequest1: function (url, token,data) {
    var config = {
      method: "get",
      url: DEVELOPMENT_CONFIG.base_url + url,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        'api-key': process.env.REACT_APP_API_KEY,
        'accept': 'application/json',
        'app-version': '1.0.1'
      },
      data: data,
    };
    
    const response = axios(config)
      .then(async (response) => {
        if (response.data.code === DEVELOPMENT_CONFIG.statusCode) {
          return response?.data
        }
      })
      .catch((error) => {
        return error?.response?.data
      });
    return response;
  },
}