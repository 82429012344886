import React from 'react'
import { frame208, logoWhite } from '../../../../Constants/images';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function ComingSoon() {
   return (
      <div>
         <section className="comming-soon py-60 response-padding">
            <div className="container">
               <div className="chanel-header">
                  <h3 className="fw-600 mb-3">Coming Soon</h3>
               </div>
               <div className='coming-nft-img position-relative'>
                  <img src={frame208} alt="" className='w-100 img-fluid' />
               </div>
            </div>
         </section>
      </div>
   )
}

export default ComingSoon;