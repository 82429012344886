import React, { useState, useEffect, useRef } from 'react'
import { audio_icon, audio_pause, modalCross,play_btn } from '../../Constants/images'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../Helper/config"
import ReactLoader from '../../ControlComponents/react-loader'
import AudioPlayModal from '../../CreaterDashboard/Shop/audioPlay'

const AddDetailAudioModal = ({ setAddDetailAudioModal, deltId }) => {
    const [detailList, setDetailList] = useState()
    const [loading, setIsLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [audioPlay, setAudioPlay] = useState(false)
    const [audioDirectPlay, setAudioDirectPlay] = useState(false)
    const [url, setUrl] = useState("")
    const [currentUrl, setCurrentUrl] = useState(null);

    const audioRef = useRef(new Audio());

    const handleAudioPlayPause = (url) => {
        if (currentUrl === url) {
            if (audioDirectPlay) {
                audioRef.current.pause();
                setAudioDirectPlay(false);
            } else {
                audioRef.current.play();
                setAudioDirectPlay(true);
            }
        } else {
            setCurrentUrl(url);
            audioRef.current.src = url;
            audioRef.current.play();
            setAudioDirectPlay(true);
        }
    };


    async function getDetailAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`shop/get-shop-media?shopType=audio&shop_id=${deltId}`)
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setIsLoading(false)
            setDetailList(result.data)

        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getDetailAPI()
    }, [refresh])
    
    const hanldeOpen = (item) => {
        setAudioPlay(true)
        setUrl(item.media_url)
    }
    return (
        <>
            {
                loading && <ReactLoader />
            }
            <div className="modal fade show modal-cast" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
                <div className='loader'>
                </div>
                <div className="modal-dialog modal-lg  modal-dialog-centered" style={{ justifyContent: "center" }}>
                    <div className="modal-content modal-radius  border-0">
                        <div className="modal-header forgot-header align-items-center pt-4">
                            <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Detail</h3>
                            <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setAddDetailAudioModal(false)
                                }}
                                src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>

                        </div>
                        <div className="modal-body d-flex flex-column justify-center align-items-center gap-3  verify-modal-box px-2 px-sm-5">
                            <form className='price-form-input'>
                                <div className='d-flex justify-content-between'>
                                    <div>                                <h4>{detailList?.title}</h4>
                                        <h6 className="images-count">
                                            {detailList?.shop_media?.length} {detailList?.shop_media?.length == 1 ? "Audio" : "Audios"}
                                        </h6>
                                    </div>
                                    <div className='detail-main'><span className='first-price-detail'>${detailList?.price}.</span><span className='second-price-detail'>00</span></div>
                                </div>
                                <p className="image-detail-description" >
                                    {detailList?.description}
                                </p>
                                <h4 class="mb-2">Audios</h4>
                                <div className='flex-wrap overflow-scroll' style={{ height: 350 }}>
                                    <div className='audio-list-main p-2 mb-3 mx-1'>
                                        {detailList?.shop_media?.length > 0 && detailList?.shop_media?.map((item, index) => (
                                            <div key={index} className="d-flex align-items-center gap-2" style={{ cursor: "pointer" }}>
                                                <img src={audio_icon} alt="audio-icon" onClick={() => {
                                                    setAudioPlay(true)
                                                    setUrl(item.media_url)
                                                }} />

                                                <div className='audio-list d-flex justify-content-between align-items-center w-100 mx-2' onClick={() => {
                                                    hanldeOpen(item)
                                                }}>
                                                    <h4 className="fw-500 f20-size mb-0">{item?.media_url?.split('/').pop()}</h4>
                                                    <img
                                                        src={currentUrl === item.media_url && audioDirectPlay ? audio_pause : play_btn}
                                                        alt="play-pause-btn"
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            handleAudioPlayPause(item.media_url)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                              
                            </form>
                        </div>
                        
                        {
                            audioPlay && <AudioPlayModal setAudioPlay={setAudioPlay} url={url} />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddDetailAudioModal
