import React, { useEffect, useState } from 'react';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import AllFavChannels from './AllFavChannels';
import AllFavSeries from './AllFavSeries';
import './favChannels.css';
import './favSeries.css';
import ReactLoader from '../../ControlComponents/react-loader'
import { noFavourites } from '../../Constants/images';

const Favourites = () => {
    const [tabSwitch, setTabSwitch] = useState(0);
    const [favChannels, setFavChannels] = useState([])
    const [favSeries, setFavSeries] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    async function getDetailsAPI() {
        setIsLoading(true)
        let type = tabSwitch === 0 ? 2 : 1;
        let result = await apiHelper.getRequest("channel/get-favorites-list?type=" + type);
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            if (tabSwitch == 0) {
                setFavSeries(result.data)
            } else {
                setFavChannels(result.data)
            }
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }

        }
    }

    useEffect(() => {
        getDetailsAPI()
    }, [tabSwitch, refresh])

    return (
        <div>
            {isLoading && <ReactLoader />}
            <div className="main-inner-content">
                {tabSwitch === 0 ?
                    <>
                        <AllFavSeries favSeries={favSeries} setIsLoading={setIsLoading} setTabSwitch={setTabSwitch} getDetailsAPI={getDetailsAPI} />
                        {
                            !isLoading &&
                            <>
                                {
                                    favSeries.length <= 0 &&
                                    <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                        <img src={noFavourites} />
                                    </div>
                                }
                            </>
                        }
                    </>
                    :
                    <>
                        <AllFavChannels favChannels={favChannels} setTabSwitch={setTabSwitch} setIsLoading={setIsLoading} getDetailsAPI={getDetailsAPI} />
                        {
                            !isLoading &&
                            <>
                                {
                                    favChannels.length <= 0 &&
                                    <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                        <img src={noFavourites} />
                                    </div>
                                }
                            </>
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default Favourites;