import React, { useState } from 'react'
import { no_audio, show_audio } from '../../Constants/images'
import ReactLoader from '../../ControlComponents/react-loader'
import PriceTag from '../../CreaterDashboard/component/price-tag'
import AddDetailAudioModal from './detailAudioModal'

function Audio({ setTabSwitch, audiosList }) {
    const [loading, setIsLoading] = useState(false)
    const [deltId, setDeltId] = useState(null)
    const [addDetailAudioModal, setAddDetailAudioModal] = useState(false)


    return (
        <>
            {loading && <ReactLoader />}

            <div className="container-fluid">
                <div className="row">
                    <div className="stream-btn pt-3 pb-4">
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(0)}>Series</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(1)}>Recorded Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(2)}>Upcoming Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(3)}> Images</button>
                        <button className="common-btn btn-blue left-corner" onClick={() => setTabSwitch(4)}>Audios</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(5)}>Videos</button>
                    </div>

                    <div className='row mt-4'>
                        {audiosList?.length > 0 ? (
                            <>
                                {
                                    audiosList?.map((item) => (
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4"
                                            key={item?.id}
                                        >
                                            <div className="latest-video mx-auto" >
                                                <div className='position-relative'
                                                >
                                                    <div
                                                        onClick={() => {
                                                            setDeltId(item.id)
                                                            setAddDetailAudioModal(true)
                                                        }}
                                                        className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3"

                                                        style={{ cursor: "pointer" }}>
                                                        <div className='show-audio'><img
                                                            src={show_audio}
                                                        /></div>
                                                        <PriceTag classes={"price_tag"} firstValue={`$${234}`} secondValue={"00"} />
                                                    </div>
                                                    <div className=" px-3 py-3 schedule-grid"
                                                        onClick={() => {
                                                            setDeltId(item.id)
                                                            setAddDetailAudioModal(true)
                                                        }}
                                                    >
                                                        <div className=''
                                                        >
                                                            <h4 className="schedule-title">
                                                                {item.title}
                                                            </h4>
                                                            <h6 className="images-count">
                                                                3 Audios
                                                            </h6>
                                                            <h6 className="images-count">
                                                                {item?.shop_media?.length}  {item?.shop_media?.length == 1 ? "Audios" : "Audios"}
                                                            </h6>
                                                            <p className="images-description" >
                                                                {item.description}
                                                            </p>
                                                            <button style={{ cursor: 'pointer' }} className="common-btn watch-btn buy-btn-shop">
                                                                <span className='me-2'>Buy Now</span>
                                                            </button>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    ))}
                            </>

                        ) : <>
                            {
                                loading == false &&
                                (
                                    <div style={{ flexDirection: 'column' }} className='no-search-results position-relative'>
                                        <img src={no_audio} alt="no live" />
                                        <h6 className='position-absolute' style={{ bottom: 0 }} >No Audios yet</h6>
                                    </div>
                                )
                            }
                        </>
                        }
                    </div>
                </div>
                {
                    addDetailAudioModal && <AddDetailAudioModal setAddDetailAudioModal={setAddDetailAudioModal} deltId={deltId} />
                }
            </div>
        </>
    )
}

export default Audio