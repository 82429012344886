import React, { useEffect, useState } from 'react'
import { playLight, seeAllArrow } from '../../../../Constants/images'
import ROUTES from '../../../../Helper/route-helper';
import { useNavigate } from 'react-router-dom';
import apiHelper from '../../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../../../Helper/config';
import '../Bites/bites.css'
import BiteModal from '../../../../Components/Bites';

function NewTalent(props) {
   const [talentList, setTalentList] = useState([])
   const [talentList2, setTalentList2] = useState([])
   const [refresh, setRefresh] = useState(false)
   const [showBite, setShowBite] = useState(false)
   const [index, setIndex] = useState(0)
   const [offset, setOffset] = useState(0);
   const [limit, setLimit] = useState(4);
   const [currentBiteIndex, setCurrentBiteIndex] = useState(0);

   const navigate = useNavigate()

   async function getAPI() {
      props?.setIsLoading(true)
      let url
      if (props.category != 0) {
         url = "bites/new-talent-list?cat_id=" + props.category + "&sort=z-a"
      } else {
         url = "bites/new-talent-list?sort=z-a"
      }
      let result = await apiHelper.getRequest(url)
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
         setTalentList(result.data.reels)
         props?.setIsLoading(false)
      } else if (result.code == 401) {
         let refreshToken = localStorage.getItem("refresh-token")
         let data = {
            "refresh_token": refreshToken,
            "scope": "refresh_token"
         }
         let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
         if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token)
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
            setRefresh(true)
         }

      }
   }

   async function getDetailAPI(index) {
      props?.setIsLoading(true)
      let url = "bites/bites-detail?id=" + index
      let url2 = "bites/bites-list?" + "offset=" + offset + "&limit=" + limit
      let result = await apiHelper.getRequest(url)
      let result2;
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
         result2 = await apiHelper.getRequest(url2);
         let newArr = [result.data, ...result2.data.data]
         setTalentList2(newArr.filter(
            (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
         ));
         props?.setIsLoading(false)
         setOffset(offset + 4);
      } else if (result.code == 401) {
         let refreshToken = localStorage.getItem("refresh-token")
         let data = {
            "refresh_token": refreshToken,
            "scope": "refresh_token"
         }
         let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
         if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token)
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
            setRefresh(true)
         }
      }
   }

   useEffect(() => {
      getAPI()
   }, [props.category, refresh]);

   const handlePlayButton = (index) => {
      setShowBite(!showBite);
      setCurrentBiteIndex(index);
   }
   return (
      <div className='bites-home'>
         {
            talentList?.length > 0 &&
            <section className="bite response-padding">
               <div className="container">
                  <div className="chanel-header">
                     <h3 className="fw-600 mb-0">New Talent</h3>
                     <button onClick={() => navigate(ROUTES.NEW_TALENT)} className="see-all fw-600 f18-size">See all <img src={seeAllArrow} alt="" className="ms-2" /></button>
                  </div>
                  <div className="chanel-wrapper mt-4">
                     <div className="row" >
                        {
                           talentList?.slice(0, 3).map((talent, index) =>
                              <div key={talent.id} onClick={() => handlePlayButton(index)}   className="col-lg-4  col-md-6  response-col">
                                 <div className="chanel-content">
                                    <div className="talent-relative">
                                       <img src={talent?.thumbnail} className="bite-img" alt="" />
                                    </div>
                                    <div className="bite-wrapper">
                                       <h4>{talent?.title}</h4>
                                       <div className="chanel-text d-flex gap-3 align-items-center">
                                          <img src={talent?.biteChannel?.profile_pic} className="bite-inner-img" alt="" />
                                          <div>
                                             <h5>{talent?.biteChannel?.name}</h5>
                                             <p>{talent?.category?.name}</p>
                                          </div>
                                       </div>
                                       <button onClick={() => handlePlayButton(index)} className="common-btn"><img src={playLight} alt="" />Play</button>
                                    </div>
                                 </div>
                              </div>
                           )
                        }
                     </div>
                  </div>
               </div>
               {showBite &&
                  <BiteModal currentBiteIndex={currentBiteIndex} setCurrentBiteIndex={setCurrentBiteIndex} index={index} bitesList={talentList} showBite={showBite} setShowBite={setShowBite} />
               }
            </section>
         }
      </div>
   )
}

export default NewTalent