import React from 'react';
import { useNavigate } from 'react-router-dom';
import { addbucket, biteImg, bucketlist, dummy, leftArrow, pinkgallery } from '../../Constants/images';
import ROUTES from '../../Helper/route-helper';
import './bucket.css'

function BucketList({ favSeries }) {
    const navigate = useNavigate()

    return (
        <div>
            <section className="favchannel-main-cus">
                <div className="container-fluid">
                    <div className='row'>
                        <div className="chanel-wrapper mt-4">
                            <div className="row gy-2">
                                <div className="col-xl-4 col-xxl-3 col-lg-4 col-md-6  response-col" onClick={() => navigate(ROUTES.CREATER_BUCKET_ACCESS)}>
                                    <div className='bucket-wrapper text-center'>
                                          <div className='img-bucket-inner'>
                                               <img src={bucketlist} className='img-fluid img-top-bucket'></img>
                                          </div>
                                          <h2 className='text-pink pt-4'>$150.<span className='fw-600 bucket-price'>00</span></h2>
                                          <h3 className=' fw-600'>Get Access</h3>
                                          <div className='d-flex align-items-center justify-content-center gap-3 bucket-btn-month pt-2'>
                                               <p className='text-pink mb-0'>3 Months</p>
                                               <p className='text-pink mb-0'>10 series</p>
                                          </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-xxl-3 col-lg-4 col-md-6  response-col" onClick={() => navigate(ROUTES.CREATER_BUCKET_ACCESS)}>
                                    <div className='bg-white addbucket-wrapper text-center'>
                                          <div className='img-bucket-inner'>
                                               <img src={addbucket} className='img-fluid'></img>
                                          </div>
                                          <h3 className=' fw-600 text-pink pt-4'>Add Bucket </h3>
                                           <img src={leftArrow} ></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default BucketList;