import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../Helper/config"
import ShopSeries from '../UserShop/shopSeries'
import ShopList from '../UserShop/shopList'
import ShopLives from '../UserShop/shopLives'
import UpcomingLivesShop from '../UserShop/upcomingLive'
import Images from '../UserShop/images'
import Video from '../UserShop/video'
import Audio from '../UserShop/audio'


function DownloadDetail() {

    const [tabSwitch, setTabSwitch] = useState(0)
    const [detailList, setDetailList] = useState([])
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [channelName, setChannelName] = useState()
    const [channelImg, setChannelImg] = useState()
    const [channelId, setChannelId] = useState()
    const [audiosList, setAudiosList] = useState([])
    const [imagesList, setImagesList] = useState([])
    const [videosList, setVideosList] = useState([])
    async function getImagesAPI() {
        setLoading(true)
        let result = await apiHelper.getRequest("shop/get-shop-media?shopType=image")
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setImagesList(result.data)
            setLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    async function getAudiosAPI() {
        setLoading(true)
        let result = await apiHelper.getRequest("shop/get-shop-media?shopType=audio")
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setAudiosList(result.data)
            setLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    async function getVideosAPI() {
        setLoading(true)
        let result = await apiHelper.getRequest("shop/get-shop-media?shopType=video")
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setVideosList(result.data)
            setLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    async function getPurchaseDetailApi() {
        setLoading(true)
        try {
            const result = await apiHelper.getRequest(`shop/my-series-recording?Id=${1}&type=${tabSwitch == 0 ? 1 : 2}`)
            if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
                setDetailList(result?.data)
            }
            else if (result.code == 401) {
                let refreshToken = localStorage.getItem("refresh-token")
                let data = {
                    "refresh_token": refreshToken,
                    "scope": "refresh_token"
                }
                let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
                if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                    localStorage.setItem("token", refreshTokenResult.data.token)
                    localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                    setRefresh(true)
                }
            }
        } catch (error) {
        }
        setLoading(false)
    }

    useEffect(() => {
        getAudiosAPI()
        getVideosAPI()
        getImagesAPI()
        getPurchaseDetailApi()
    }, [refresh, tabSwitch])

    return (
        <section className="streaming py-6 response-padding ">
            {
                tabSwitch == 0 ?
                    <ShopSeries channelImg={channelImg} channelId={channelId} channelName={channelName} setTabSwitch={setTabSwitch} detailList={detailList} loading={loading} setLoading={setLoading} check="download" /> :

                    tabSwitch == 1 ?
                        <ShopLives setLoading={setLoading} setTabSwitch={setTabSwitch} detailList={detailList} loading={loading} getDetailApi={getPurchaseDetailApi} check="download" /> :
                        tabSwitch == 2 ?
                            <UpcomingLivesShop setTabSwitch={setTabSwitch} /> :
                            tabSwitch == 3 ?
                                <Images setTabSwitch={setTabSwitch} tabSwitch={tabSwitch}
                                    imagesList={imagesList}
                                /> :
                                tabSwitch === 4 ?
                                    <Audio setTabSwitch={setTabSwitch} tabSwitch={tabSwitch}
                                        audiosList={audiosList}
                                    /> :
                                    <Video setTabSwitch={setTabSwitch} tabSwitch={tabSwitch}
                                        videosList={videosList}
                                    />
            }
        </section>

    )
}

export default DownloadDetail