import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { no_live, profile_image_placeholder } from '../../Constants/images';
import '../../CreaterDashboard/TeamMember/team-series.css'
import ROUTES from '../../Helper/route-helper';
function LiveCreatorList({ getApi, recordedLives, isLoading }) {
    const [activeTab, setActiveTab] = useState(localStorage.getItem("activeTab") || "0");
    const navigate = useNavigate()

    useEffect(() => {
        if (activeTab === "0") {
            getApi();
        } else if (activeTab === "2") {
            navigate(ROUTES.LIVES)
        }
    }, [activeTab])

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex.toString());
        localStorage.setItem("activeTab", tabIndex.toString());
    };
    const handleOpen = (item) => {
        const id = item.id
        navigate(ROUTES.RECORDING_LIVES, { state: { id } });
    }

    return (
        <div>
            <section className="schedule-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="stream-btn pt-3">
                            <button onClick={() => handleTabClick(0)} className={`common-btn btn-white ${activeTab === "0" ? "active" : ""}`}>Lives</button>
                            <button onClick={() => handleTabClick(1)} className={`common-btn btn-blue ${activeTab === "1" ? "active" : ""}`}>Recorded Lives</button>
                            <button onClick={() => handleTabClick(2)} className={`common-btn btn-white ${activeTab === "2" ? "active" : ""}`}>Upcoming Lives</button>
                        </div>
                    </div>
                    <section className="py-60  favchannel-main-cus">
                        <div className="container-fluid">
                            <div className="row">
                                {

                                    recordedLives.map((item) => (
                                        <div className="col-12 col-sm-6 col-lg-6 col-xl-3 mb-4 position-relative"
                                            onClick={() => handleOpen(item)} key={item.id}
                                        >
                                            <div className="channels-box  mb-3 mb-lg-3 mb-xl-0" style={{ cursor: 'pointer' }}>
                                                <img src={item.profile_pic ? item.profile_pic : profile_image_placeholder} className="img-fluid channel-img" />
                                                <h4 className="channel-text fw-600 mt-3 mb-1">{item.name}</h4>
                                            </div>
                                        </div>

                                    ))
                                }
                                {
                                    (isLoading == false && recordedLives?.length == 0) && <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                        <img src={no_live} alt="no live" />
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </div>
    )
}

export default LiveCreatorList;