import React, { useState, useEffect } from 'react'
import { crossthemebgpink, dummy, modalCross, playLightBtn, selectVideo } from '../../Constants/images'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ReactLoader from '../../ControlComponents/react-loader';
import ProgressBarComponent from '../../ControlComponents/progressBar';
import ERR_MESSAGE from '../../Helper/error-helper';
import uploadToS3 from '../component/fileUpload';
import { NotificationManager } from 'react-notifications';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../Helper/config"

const AddVideoModal = ({ setAddVideoModal, setTabSwitch, deltId, getVideosAPI, setAddDetailVideoModal }) => {
    const [progressLoading, setProgressLoading] = useState(false)
    const [progress, setProgress] = useState();
    const [thumbnailFileUrl, setThumbnailFileUrl] = useState([]);
    const [errors, setErrors] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [price, setPrice] = useState("");
    const [refresh, setRefresh] = useState(false)

    const [info, setInfo] = useState({
        title: "",
        description: "",
    })

    async function getDetailAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`shop/get-shop-media?shopType=video&shop_id=${deltId}`)
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setIsLoading(false)
            setInfo(result.data)
            setPrice(result?.data?.price)
            const filterArray = result?.data?.shop_media?.map((item) => {
                return {
                    media_url: item.media_url,
                    media_thumbnail: item.media_thumbnail
                };
            });
            setThumbnailFileUrl(filterArray)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        if (deltId != undefined) {
            getDetailAPI()
        }
    }, [refresh])

    function changeHandler(e) {
        setErrors("")
        const newValue = e.target.value?.slice(0, 3);
        setPrice(newValue);
    }

    const handleChange = (e) => {
        setErrors("")
        const name = e.target.name
        const value = e.target.value
        setInfo({
            ...info,
            [name]: value
        })
    }

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        const { title, description } = info;
        if (thumbnailFileUrl?.length === 0) {
            formIsValid = false;
            errors["thumbnailFileUrl"] = ERR_MESSAGE.ADD_VIDEO
        }
        if (!title || title.trim().length === 0) {
            formIsValid = false;
            errors["title"] = ERR_MESSAGE.TITLE_EMPTY
        }
        if (!description || description.trim().length === 0) {
            formIsValid = false;
            errors["description"] = ERR_MESSAGE.SUMMARY_EMPTY
        }

        if (!price) {
            formIsValid = false;
            errors["price"] = ERR_MESSAGE.EMPTY_PRICE
        }
        setErrors(errors);
        return formIsValid;
    }

    const generateThumbnail = async (videoElement, files) => {
        const canvas = document.createElement('canvas');
        const videoWidth = videoElement.videoWidth;
        const videoHeight = videoElement.videoHeight;
        canvas.width = videoWidth;
        canvas.height = videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(videoElement, 0, 0, videoWidth, videoHeight);
        const thumbnailURL = canvas.toDataURL('image/jpeg');
        const fileThumbnail = dataURLtoFile(thumbnailURL, files[0].name.split(".")[0]);
        return { thumbnailURL, fileThumbnail };
    };

    const generateThumbnailFromVideo = async (videoElement, timeInSeconds, files) => {
        return new Promise((resolve) => {
            const seekTime = Math.min(timeInSeconds, videoElement.duration - 0.1);
            videoElement.currentTime = seekTime;
            videoElement.addEventListener('seeked', async () => {
                const { thumbnailURL, fileThumbnail } = await generateThumbnail(videoElement, files);
                resolve({ thumbnailURL, fileThumbnail });
            });
        });
    };

    const formatDuration = (durationInSeconds) => {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = Math.floor(durationInSeconds % 60);
        const hoursStr = hours < 10 ? `0${hours}` : `${hours}`;
        const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${hoursStr}:${minutesStr}:${secondsStr}`;
    };

    const fileChangeVideo = async (e) => {
        setErrors("")
        if (e.target.files.length === 0) {
            return;
        }
        const file = e.target.files[0];
        if (file) {
            const fileType = file.type.split('/')[0];
            if (fileType === 'video') {
                const files = e.target.files;
                const videoElement = document.createElement('video');
                videoElement.preload = 'metadata';
                videoElement.addEventListener('loadedmetadata', async () => {
                    const durationInSeconds = videoElement.duration;
                    const formattedDuration = formatDuration(durationInSeconds);
                    const { thumbnailURL, fileThumbnail } = await generateThumbnailFromVideo(videoElement, durationInSeconds / 2, files);
                    const formData = new FormData();
                    formData.append('mediaFile', file);
                    const videoURL = URL.createObjectURL(file);
                    fileUpload2(fileThumbnail, file, formattedDuration)
                });
                videoElement.src = URL.createObjectURL(file);
            }
        }
    };


    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }
    const fileUpload2 = async (thumb, file, formattedDuration) => {
        const state = { videoLocation: null, thumbnailLocation: null };
        setProgressLoading(true)
        try {
            if (file || thumb) {
                await uploadToS3(thumb, file, state, setProgress);
                if (state.videoLocation || state.thumbnailLocation) {
                    setProgressLoading(false)
                    setThumbnailFileUrl(prevState => [
                        ...prevState,
                        {
                            media_url: state.videoLocation,
                            media_thumbnail: state.thumbnailLocation
                        }
                    ]);
                }
                ;
            }
        }
        catch (error) {
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (handleValidation()) {
            setIsLoading(true)
            let data = {
                "title": info.title,
                "shop_type": "video",
                "description": info.description,
                "price": parseInt(typeof price === 'string' ? price?.slice(0, 3) : String(price)?.slice(0, 3)),
                "media": thumbnailFileUrl
            }
            try {
                const result = await apiHelper.postRequest("shop/add-shop-media", data);
                if (result.code == DEVELOPMENT_CONFIG.statusCode) {
                    setIsLoading(false)
                    if (result.message == "shop add successfully") {
                        NotificationManager.success("Video added successfully")
                    }
                    else {
                        NotificationManager.success(result.message)
                    }
                    getVideosAPI()
                    setAddVideoModal(false);
                    setTabSwitch(5)
                }
            } catch (error) {
                setIsLoading(false)
            }
        }
    }

    const handleRemoveVideo = (index) => {
        setThumbnailFileUrl((prevData) => {
            const updatedClipData = [...prevData];
            updatedClipData.splice(index, 1);
            return updatedClipData;
        });
    }
    const handleEdit = async (e) => {
        e.preventDefault()
        if (handleValidation()) {
            setIsLoading(true)
            let data = {
                "shop_id": deltId,
                "title": info.title,
                "shop_type": "video",
                "description": info.description,
                "price": parseInt(typeof price === 'string' ? price?.slice(0, 3) : String(price)?.slice(0, 3)),
                "media": thumbnailFileUrl
            }
            try {
                const result = await apiHelper.postRequest("shop/edit-shop-media", data);
                if (result.code == DEVELOPMENT_CONFIG.statusCode) {
                    setIsLoading(false)
                    if (result?.message == "Shop media updated successfully") {
                        NotificationManager?.success("Video updated successfully")
                    }
                    else {
                        NotificationManager?.success(result?.message)
                    }
                    getVideosAPI()
                    setAddVideoModal(false);
                    setAddDetailVideoModal(false)
                    setTabSwitch(5)
                }
            } catch (error) {
                setIsLoading(false)
            }
        }
    }

    return (
        <>
            {isLoading && <ReactLoader />}
            {progressLoading && <>
                <ProgressBarComponent progress={progress} />
            </>
            }              <div className="modal fade show modal-cast" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
                <div className='loader'>
                </div>
                <div className="modal-dialog modal-lg  modal-dialog-centered" style={{ justifyContent: "center" }}>
                    <div className="modal-content modal-radius  border-0">
                        <div className="modal-header forgot-header align-items-center pt-4">
                            <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">{deltId != undefined ? "Edit" : "Add"} Videos</h3>
                            <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setAddVideoModal(false)
                                }}
                                src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                        </div>
                        <div className="modal-body d-flex flex-column justify-center align-items-center gap-3  verify-modal-box px-2 px-sm-5">
                            <form className='price-form-input'>
                                <div className='mb-3 mt-5'>
                                    <label className='fw-500 f18-size text-black mx-2'>Videos</label>
                                    <div className="d-flex my-1">
                                        <div className="mx-2 position-relative" style={{ cursor: "pointer" }}>
                                            <span className="py-5 px-4 create-post-select-span " >
                                                <img src={selectVideo} className="create-post-select-img" />
                                            </span>
                                            <input type='file' style={{ cursor: "pointer" }} accept=".mp4, .mov, .avi" onChange={fileChangeVideo} className='input-file-image' />
                                        </div>
                                        <div className="d-flex justify-content-between overflow-scroll">
                                            {
                                                thumbnailFileUrl?.length > 0 && thumbnailFileUrl?.map((item, index) => (
                                                    <div className="mx-2 position-relative">
                                                        <img src={item.media_thumbnail} className="create-post-img" />
                                                        <img src={crossthemebgpink} className="create-post-cross-btn" />
                                                        <img src={playLightBtn} onClick={() => handleRemoveVideo(index)} className='play-btn-add-video' />
                                                    </div>
                                                ))
                                            }

                                        </div>
                                    </div>
                                    {errors.thumbnailFileUrl && <span className="err_msg">{errors.thumbnailFileUrl}</span>}

                                </div>
                                <div className='mb-3'>
                                    <label className='fw-500 f18-size text-black pb-2'>Title</label>
                                    <input type='text' className='border-edit  w-100' name="title"
                                        value={info?.title} onChange={handleChange}
                                        placeholder='Enter Here' ></input>
                                    {errors.title && <span className="err_msg">{errors.title}</span>}
                                </div>

                                <div className='mb-3'>
                                    <label className='fw-500 f18-size text-black pb-2'>Description</label>
                                    <textarea className=" text-summary place-grey d-block w-100"
                                        value={info?.description} onChange={handleChange}
                                        placeholder="Enter Here" rows="6" name="description"></textarea>
                                    {errors.description && <span className="err_msg">{errors.description}</span>}

                                </div>
                                <div className='mb-3'>
                                    <label className='fw-500 f18-size text-black pb-2'>Price</label>
                                    <TextField
                                        className='price-input'
                                        type='number'
                                        min="1"
                                        value={price}
                                        onChange={changeHandler}
                                        placeholder='Enter Price'
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                    />
                                    {errors.price && <span className="err_msg">{errors.price}</span>}

                                </div>
                                {
                                    deltId != undefined ? <button type='submit' className='price-submit-btn' onClick={handleEdit}>Save</button> : <button type='submit' className='price-submit-btn' onClick={handleSubmit}>Save</button>
                                }

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddVideoModal
