import React, { useEffect, useState } from 'react'
import { no_videos, playLightbtn } from '../../Constants/images'
import ReactLoader from '../../ControlComponents/react-loader'
import PriceTag from '../../CreaterDashboard/component/price-tag'
import AddDetailVideoModal from './detailVideoModal'
import { components } from 'react-select'
import Sidebar from '../../Components/Sidebar/Sidebar'

function Video({ setTabSwitch, videosList }) {
    const [loading, setIsLoading] = useState(false)
    const [deltId, setDeltId] = useState(null)
    const [addDetailVideoModal, setAddDetailVideoModal] = useState(false)

    const hanldeClick = (item) => {
        setDeltId(item.id)
        setAddDetailVideoModal(true)
    }

    
    return (
        <>
            {loading && <ReactLoader/>}
            <div className="container-fluid">
                <div className="row">
                    <div className="stream-btn pt-3 pb-4">
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(0)}>Series</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(1)}>Recorded Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(2)}>Upcoming Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(3)}> Images</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(4)}>Audios</button>
                        <button className="common-btn btn-blue left-corner" onClick={() => setTabSwitch(5)}>Videos</button>
                    </div>
                    <div className='row mt-4'>
                        {videosList?.length > 0 ? (
                            <>
                                {
                                    videosList?.map((item) => (
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4"
                                            key={item?.id}
                                        >
                                            <div className="latest-video mx-auto" >
                                                <div className='position-relative'

                                                >
                                                    <div
                                                        onClick={() => hanldeClick(item)}

                                                        className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3"
                                                        style={{ cursor: "pointer" }}>

                                                        <img
                                                            src={item.shop_media[0].media_thumbnail}
                                                            className="img-fluid latest-vid-img new-latest-video"
                                                            alt="thumbnail"
                                                        />
                                                        <PriceTag classes={"price_tag"} firstValue={`$${item.price}`} secondValue={"00"} />
                                                        <img src={playLightbtn} className='play-btn-video' />
                                                    </div>
                                                </div>
                                                <div className=" px-3 py-3 schedule-grid" onClick={() => hanldeClick(item)}
                                                >
                                                    <div className=''
                                                    >
                                                        <h4 className="schedule-title">{item.title} </h4>
                                                        <h6 className="images-count">
                                                            {item.shop_media.length} {item.shop_media.length == 1 ? "Image" : "Images"}
                                                        </h6>
                                                        <p className="images-description" >
                                                            {item.description}                                                  </p>
                                                        <button style={{ cursor: 'pointer' }} className="common-btn watch-btn buy-btn-shop">
                                                            <span className='me-2'>Buy Now</span>
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </>
                        ) : <>
                            {
                                loading == false &&
                                (
                                    <div style={{ flexDirection: 'column' }} className='no-search-results position-relative'>
                                        <img src={no_videos} alt="no live" />
                                        <h6 className='position-absolute' style={{ bottom: 21 }}>No Videos yet</h6>
                                    </div>
                                )
                            }
                        </>
                        }
                    </div>
                </div>

                {
                    addDetailVideoModal && <AddDetailVideoModal setAddDetailVideoModal={setAddDetailVideoModal} setTabSwitch={setTabSwitch} deltId={deltId} />
                }

            </div>
        </>
    )
}

export default Video