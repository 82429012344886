import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dotsbg } from '../../Constants/images';
import './createrChallenges.css'
import ROUTES from '../../Helper/route-helper';
import { Dropdown } from 'react-bootstrap';
import ChallengeModal from './createChallengeModal';
import DeleteChallengeModal from './deleteChallengeModal';
import DEVELOPMENT_CONFIG from "../../../src/Helper/config"
import { NotificationManager } from 'react-notifications';
import apiHelper from '../../Helper/api-helper';

function MyChallenges({ challenges, filterList, tabSwitch, tagsSelected, setProgressLoading, setIsLoading, setRefresh, setProgress, getDetailsAPI,check}) {
   const navigate = useNavigate()
   const [deleteModal, setDeleteModal] = useState(false)
   const [challengeModal, setChallengeModal] = useState(false)
   const [id, setId] = useState(null)
   const handleDetail = (id) => {
      localStorage.setItem("challenge_id", id)
      navigate(ROUTES.CREATER_CHALLENGES_DETAIL)
   }

   const handleOpen = (id) => {
      setId(id)
      setChallengeModal(!challengeModal)
   }

   const handleDeleteOpen = (id) => {
      setId(id)
      setDeleteModal(!deleteModal)
   }

   async function getDeleteAPI() {
      setIsLoading(true)
      let result = await apiHelper.getRequest("creator/delete-challenge?id=" + id)
      if (result.code == DEVELOPMENT_CONFIG.statusCode) {
         setIsLoading(false)
         setDeleteModal(false)
         navigate(ROUTES.CREATER_CHALLENGES)
         localStorage.setItem("message", result.message)
         getDetailsAPI()
      }
      else if (result.code == 401) {
         let refreshToken = localStorage.getItem("refresh-token")
         let data = {
            "refresh_token": refreshToken,
            "scope": "refresh_token"
         }
         let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
         if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token)
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
            setRefresh(true)
         }
      }
      else {
         NotificationManager.error(result.message)
      }
   }
   return (
      <div>
         <section className="favchannel-main-cus createchallenge-wrapper">
            <div className="container-fluid">
               <div className='row'>
                  <div className="chanel-wrapper mt-4">
                     <div className="row gy-2">
                        {
                           check == true ? <> {filterList.map((item) => (
                              <div className="col-xl-4 col-xxl-3 col-lg-4 col-md-6  response-col" key={item.id} >
                                 <div className="channel-bites-content">
                                    <div className='position-relative'>
                                       <img src={item.thumbnail} className="channelbite-img" alt="" />
                                       <div className='dotsmain'>
                                          <Dropdown>
                                             <Dropdown.Toggle id="custom-dropdown-toggle">
                                                <img src={dotsbg} className='img-fluid'></img>
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleOpen(item.id)} >Edit</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleDeleteOpen(item.id)}> Delete</Dropdown.Item>
                                             </Dropdown.Menu>
                                          </Dropdown>
                                       </div>
                                    </div>
                                    <div className="bite-wrapper">
                                       <h4>{item.title}</h4>
                                       <div className="chanel-text d-flex gap-3 align-items-center">
                                          <img src={item.channelData.profile_pic} className="bite-inner-img" alt="" />
                                          <div>
                                             <h6 className='fw-600 mb-0'>{item.channelData.name}</h6>
                                             <p className='mt-1'>{item.category.name}</p>
                                          </div>
                                       </div>
                                       <button onClick={() => handleDetail(item.id)} className="common-btn">View Details</button>
                                    </div>
                                 </div>
                              </div>
                           ))

                           }</> : <> {challenges.map((item) => (
                              <div className="col-xl-4 col-xxl-3 col-lg-4 col-md-6  response-col" >
                                 <div className="channel-bites-content">
                                    <div className='position-relative'>
                                       <img src={item.thumbnail} className="channelbite-img" alt="" />
                                       <div className='dotsmain'>
                                          <Dropdown style={{ cursor: "pointer" }}>
                                             <Dropdown.Toggle id="custom-dropdown-toggle">
                                                <img src={dotsbg} className='img-fluid'></img>
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleOpen(item.id)} >Edit</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleDeleteOpen(item.id)}> Delete</Dropdown.Item>
                                             </Dropdown.Menu>
                                          </Dropdown>
                                       </div>
                                    </div>                                    <div className="bite-wrapper">
                                       <h4>{item.title}</h4>
                                       <div className="chanel-text d-flex gap-3 align-items-center">
                                          <img src={item.channelData.profile_pic} className="bite-inner-img" alt="" />
                                          <div>
                                             <h6 className='fw-600 mb-0'>{item.channelData.name}</h6>
                                             <p className='mt-1'>{item.category.name}</p>
                                          </div>
                                       </div>
                                       <button onClick={() => handleDetail(item.id)} className="common-btn">View Details</button>
                                    </div>
                                 </div>
                              </div>
                           ))

                           }</>
                        }
                     </div>
                  </div>
               </div>
            </div>
         </section>
         {deleteModal && <DeleteChallengeModal setDeleteModal={setDeleteModal} getDeleteAPI={getDeleteAPI} />}
         {challengeModal && < ChallengeModal setIsLoading={setIsLoading} setChallengeModal={setChallengeModal} challengeId={id} setProgressLoading={setProgressLoading} setProgress={setProgress} getListAPI={getDetailsAPI} />}
      </div>
   )
}

export default MyChallenges;