import React, { useState } from 'react'
import { channelCase, channelEasy, channelFashion, channelGfx, channelHealth, channelTech, channelUnacademy, favGrey, favRed, mv, profile_image_placeholder } from './../../../../../Constants/images';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../../Helper/route-helper';
import apiHelper from '../../../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../../../../Helper/config';



function ChannelSearch(props) {

    const navigate = useNavigate()
    const [_, forceUpdate] = useState(0)

    async function addFavourites(item) {
        if (item.channelDetails.is_fav == 0) {
            item.channelDetails.is_fav = 1
        } else {
            item.channelDetails.is_fav = 0
        }
        let data = {
            "channel_id": item?.creator_id,
            "type": "1"
        }
        forceUpdate(Math.random())
        let result = await apiHelper.postRequest("channel/add-favorites", data)
        if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
        }
    }

    return (
        <div className="row" style={{ marginTop: 20 }}>
            {
                props?.channelList?.map((item) => {
                    return (
                        <div key={item?.id} style={{ cursor: "pointer" }} className="col-12 col-sm-6 col-lg-6 col-xl-3">
                            <div className="channels-box position-relative mb-3 mb-lg-3 mb-xl-0">
                                <img src={item?.channelDetails.profile_pic ? item?.channelDetails.profile_pic:profile_image_placeholder} className="img-fluid channel-img" />
                                <h4 className="channel-text fw-600 my-2">{item?.channelDetails.name}</h4>
                                <p className="channel-para mb-0">{item?.category?.channelDetails}</p>
                                <div onClick={() => addFavourites(item)} className="like-icon">
                                    {
                                        item?.channelDetails.is_fav == 1 ?
                                            <img src={favRed} className="img-fluid fill-icon" /> :
                                            <img src={favGrey} className="img-fluid unfill-icon" />
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
            }


        </div>
    )
}

export default ChannelSearch;