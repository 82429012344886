import React, { useState } from 'react'
import { cast3, favRed, playLightBtn, seeAllArrow, video1, video6 } from '../../Constants/images'
import PlayBiteModal from '../../CreaterDashboard/Bites/playBite'

function BitesShop({ seriesDetail }) {
    const [videoUrl, setVideoUrl] = useState("")
    const channelName = localStorage.getItem("channelName")
    const channelImg = localStorage.getItem("channelImg")

    const [id, setId] = useState(null)
    const [item, setItem] = useState("")
    const [showBite, setShowBite] = useState(false)
    const [currentBiteIndex, setCurrentBiteIndex] = useState("")
    const handleBitePlay = (item) => {
        setVideoUrl(item.video)
        setShowBite(!showBite);
        // setCurrentBiteIndex(id);
        setId(item.id)
        setItem(item)
    }

    return (
        <div>
            <section className=" latest-challdata latest-challenge response-padding">
                <div className="container">
                    {
                        seriesDetail?.reels?. length > 0 && <div className="chanel-header">
                        <h3 className="fw-600 mb-0">Bites</h3>
                        {/* <button                            
                            className="see-all fw-600 f18-size">See all <img src={seeAllArrow} alt="" className="ms-2" /></button> */}
                    </div>
                    }
                   
                    <div className='row'>
                        {
                            seriesDetail?.reels?.map((item) => {
                                return (
                                    <div
                                        // key={challenges?.id} onClick={() => navigate(ROUTES.CHALLENGES_DETAILS, { state: { item: challenges } })} 
                                        className='col-12 col-md-6 col-lg-4 col-xl-3'>
                                        <div className='bites-seriesdetails mt-4 ' onClick={() => handleBitePlay(item)}>
                                            <div className='video-challenge position-relative'>
                                                <img src={item.thumbnail} className="video-deatil-chall img-fluid" alt="" />
                                                <div className=" btn-play-video"><img src={playLightBtn} alt="" className='img-fluid play-icon-data' /></div>
                                                <div className="grid-data-detail">
                                                    <div className='grid-circle-img'>
                                                        <img src={channelImg} className="img-fluid grid-data-img " alt="" />
                                                    </div>
                                                    <div>
                                                        <h6 className="white fw-500 mb-0">{item.title} </h6>
                                                        <p className=' mb-0'>{channelName}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            {
                showBite &&
                <PlayBiteModal currentBiteIndex={currentBiteIndex} setCurrentBiteIndex={setCurrentBiteIndex} showBite={showBite} setShowBite={setShowBite} videoUrl={videoUrl} id={id} item={item} />
            }
        </div>
    )
}
export default BitesShop