import React, { useEffect, useState } from 'react'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../Helper/config"
import ReactLoader from '../../ControlComponents/react-loader'
import DownloadDetail from './download-detail'
function Download() {
    const [channelList, setChannelList] = useState([])
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    async function getShopApi() {
        setLoading(true)
        try {
            const result = await apiHelper.getRequest("shop/my-purchased-channel")
            if (result?.code == DEVELOPMENT_CONFIG.statusCode) {
                if (Object.keys(result.data).length === 0) {
                    setChannelList([]);
                } else {
                    setChannelList(result.data);
                }
                setLoading(false);
            }
            else if (result.code == 401) {
                let refreshToken = localStorage.getItem("refresh-token")
                let data = {
                    "refresh_token": refreshToken,
                    "scope": "refresh_token"
                }
                let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
                if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                    localStorage.setItem("token", refreshTokenResult.data.token)
                    localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                    setRefresh(true)
                }
            }
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        getShopApi()
    }, [refresh])


    return (
        <div className='main-inner-content'>
            {loading && <ReactLoader />}
            <section className="streaming py-6 response-padding ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="">
                            <h3 className="fw-600">Downloads</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="favchannel-main-cus">
                            {/* <PurchaseList channelList={channelList} loading={loading} /> */}
                            <DownloadDetail/>
            </section>
        </div>

    )
}

export default Download
