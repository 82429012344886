import React, { useState } from 'react';
import {Link, NavLink } from 'react-router-dom';
import { logoPurple } from './../../Constants/images';
import ROUTES from "./../../Helper/route-helper"
import './header1.css'

function Header1() {
   const [isMenuOpen, setMenuOpen] = useState(false);

   const toggleMenu = () => {
      setMenuOpen(!isMenuOpen);
   };
   return (
      <>
         <header className="topbar">
            <nav className="navbar navbar-expand-lg">
               <div className="container">
                  <Link className="navbar-brand" to="/">
                     <img src={logoPurple} alt="" />
                  </Link>
                  <button
                     className="navbar-toggler"
                     type="button"
                     data-bs-toggle="collapse"
                     data-bs-target="#navbarSupportedContent"
                     // aria-controls="navbarSupportedContent"
                     aria-expanded="false"
                     aria-label="Toggle navigation"
                     onClick={toggleMenu}
                  >
                     <span className="navbar-toggler-icon"><i className="fa fa-bars" aria-hidden="true"></i>
                     </span>
                  </button>
                  <div className={`collapse navbar-collapse${isMenuOpen ? ' show' : ''}`}>
                     <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-2 gap-lg-4">
                        <li className="nav-item">
                           <NavLink activeclassname="active" className="nav-link" aria-current="page" to="/"
                           >Home
                           </NavLink>
                        </li>
                        <li className="nav-item">
                           <NavLink activeclassname="active" className="nav-link" to={ROUTES.ABOUT_US}>About us</NavLink>
                        </li>
                        <li className="nav-item">
                           <NavLink activeclassname="active" className="nav-link" to={ROUTES.HELP}>Help</NavLink>
                        </li>
                        <li className="nav-item">
                           <NavLink activeclassname="active" className="nav-link" to={ROUTES.CONTACT_US}>Contact us</NavLink>
                        </li>
                     </ul>
                     <div className="d-flex gap-3 ms-3">
                        <NavLink activeclassname="active" to={ROUTES.SIGN_IN} className="common-btn header-btn">Login</NavLink>
                        <NavLink activeclassname="active" to={ROUTES.SIGN_UP} className="common-btn btn-blue header-btn">Sign up</NavLink>
                     </div>
                  </div>
               </div>
            </nav>
         </header>
      </>
   )
}

export default Header1