import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Pages/Bite/bite.css";
import { favGrey, favRed, shareIcon } from "../../Constants/images";
import apiHelper from "../../Helper/api-helper";
import DEVELOPMENT_CONFIG from "../../Helper/config";
import ReactLoader from "../../ControlComponents/react-loader";
let index = 2;
const BiteModal = (props) => {
  // const [isVideosPlaying, setIsVideoPlaying] = useState(true);
  const videoRef = useRef(null);
  const nextVideoRef = useRef(null);
  const prevVideoRef = useRef(null);
  const slickRef = useRef(null);
  const [refresh, setRefresh] = useState(false);
  const [_, forceUpdate] = useState(0);
  const index = useRef(0);
  const bite = useRef(props?.bitesList[index.current]);
  const { currentBiteIndex, setCurrentBiteIndex } = props;
  const [isLoading, setIsLoading] = useState(true);


  function handleClose(e) {
    if (e.target == videoRef.current) {
      props?.setShowBite(!props?.showBite);
      // props?.setBitesList2([]);
      //   props?.setOffset(0);
    }
  }

  const likeDislikeReelFunction = async (item) => {
    if (item?.is_liked == 1) {
      item.is_liked = 0;
      item.like_count = item.like_count - 1;
    } else {
      item.is_liked = 1;
      item.like_count = item.like_count + 1;
    }
    forceUpdate(Math.random());
    let data = {
      reel_id: item?.id,
      creator_id: item?.user?.id,
    };

    let result = await apiHelper.postRequest("bites/like-dislike-reel", data);
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
    } else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token");
      let data = {
        refresh_token: refreshToken,
        scope: "refresh_token",
      };
      let refreshTokenResult = await apiHelper.postRequest(
        "auth/refresh-token",
        data
      );
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token);
        localStorage.setItem(
          "refresh-token",
          refreshTokenResult.data.refresh_token
        );
        likeDislikeReelFunction(item);
      }
    }
  };

  const handlePrevious = () => {
    setCurrentBiteIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const handleNext = () => {
    setCurrentBiteIndex((prevIndex) => (prevIndex < props.bitesList.length - 1 ? prevIndex + 1 : prevIndex));
  };

  const handleVideoLoaded = () => {
    setIsLoading(false);
  };

  return (
    <div
      ref={videoRef}
      onClick={handleClose}
      className="modal fade play-video show"
      style={{ display: "block", backdropFilter: "blur(6px)" }}
      id="bites"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-bitesreel-custom">
        <div className="modal-content">
          <div className="videoCard position-relative">
            {isLoading && <ReactLoader />}
            <video
              className="videoCard_player"
              src={props.bitesList[currentBiteIndex]?.video}
              loop
              autoPlay={true}
              onLoadedData={handleVideoLoaded}
              onLoadStart={() => setIsLoading(true)}
            />
            {
              !isLoading &&
              <div className="share-like-main">
                <div
                  onClick={() => likeDislikeReelFunction(props.bitesList[currentBiteIndex])}
                  className="like-video"
                >
                  {props.bitesList[currentBiteIndex]?.is_liked === 1 ? (
                    <img src={favRed} className="img-fluid" />
                  ) : (
                    <img src={favGrey} className="img-fluid" />
                  )}
                  <span className="like-count">{props.bitesList[currentBiteIndex]?.like_count}</span>
                </div>
                <div className="share-video mt-4">
                  <img src={shareIcon} className="img-fluid" />
                </div>
              </div>
            }

            {!isLoading &&
              <div className="video-carousel">
                <div className="chanel-text d-flex gap-3 align-items-center">
                  <img src={bite.current?.thumbnail} className="bite-inner-img" />
                  <div>
                    <h5 className="fw-600 text-white">{props.bitesList[currentBiteIndex]?.channel?.name}</h5>
                    <p className="mb-0">{props.bitesList[currentBiteIndex]?.channel?.category?.name}</p>
                  </div>
                </div>
              </div>}
          </div>
          <div
            className="carousel-control-prev"
            style={{ display: "block", top: "275px" }}
            onClick={handlePrevious}
          >
            <span className="carousel-control-prev-icon" aria-hidden="true">
              <i className="fa fa-angle-left" aria-hidden="true"></i>
            </span>
            <span className="visually-hidden">Previous</span>
          </div>
          <div
            className="carousel-control-next"
            style={{ display: "block", top: "275px" }}
            onClick={handleNext}
            >
            <span className="carousel-control-next-icon" aria-hidden="true">
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </span>
            <span className="visually-hidden">Next</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BiteModal;