import React from 'react'
import { copyLink, facebook, facebookImg, instaImg, linkdenImg, modalCross, pinterest, shareFreinds, twitter, twitterImg } from '../../Constants/images'


const ShareModal = ({ setShareModal}) => {

    
    return (
        <div className="modal fade show modal-cast" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
            <div className='loader'>
            </div>
            <div className="modal-dialog modal-dialog-centered modal-schedule-width">
                <div className="modal-content modal-radius  border-0">
                    <div className="modal-header forgot-header align-items-center pt-4">
                        <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Share With Friends</h3>
                        <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img style={{ cursor: "pointer" }} onClick={() => {
                            setShareModal(false)
                        }}
                            src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                    </div>
                    <div className="modal-body  verify-modal-box px-2 px-sm-5">
                           <div className='share-friend-img'><img src={shareFreinds}></img></div>
                           <div className='main-icon'>
                            <div className='share-icons'><img src={facebookImg} className='icon-img'></img>
                           <div className='icon-text'>facebook</div>
                           </div>
                           <div className='share-icons'><img src={instaImg} className='icon-img'></img>
                           <div className='icon-text'>Instagram</div>
                           </div>
                           <div className='share-icons'><img src={linkdenImg} className='icon-img'></img>
                           <div className='icon-text'>Linkden</div>
                           </div>
                           <div className='share-icons'><img src={twitterImg} className='icon-img'></img>
                           <div className='icon-text'>Twitter</div>
                           </div>
                           <div className='share-icons'><img src={pinterest} className='icon-img'></img>
                           <div className='icon-text'>Pinterest</div>
                           </div>
                           <div className='share-icons'><img src={copyLink} className='icon-img'></img>
                           <div className='icon-text'>Copy Link</div>
                           </div>
                           </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShareModal
