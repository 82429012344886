import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import apiHelper from '../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../../Helper/config';
import ChannelSearch from '../SearchChannels/Components/ChannelSearch/ChannelSearch';
import SearchSeries from '../SeriesSearch/Components/SearchSeries/SearchSeries';
import './mainSearch.css';
import { filter, noSearchResults, noSeries, search } from '../../../Constants/images';
import AllChallengesFilter from '../../AllChallenges/Components/AllChallengesFilter/AllChallengesFilter';
import ReactLoader from '../../../ControlComponents/react-loader';

function MainSearch() {
  const location = useLocation()
  const searchTag = location?.state?.item
  const [catList, setCatList] = useState([])
  const [tags, setTags] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [refresh, setRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [tabSwitch, setTabSwitch] = useState(1)
  const [tabSwitch1, setTabSwitch1] = useState()
  const [inputValue, setInputValue] = useState("")
  const [channelList, setChannelList] = useState([])
  const [seriesList, setSeriesList] = useState([])
  const [check, setCheck] = useState(false)
  const [selectedTags, setSelectedTags] = useState(tagsSelected);

  const handleChange = async (e) => {
    setInputValue(e.target.value)
    let url;
    if (tags.length > 0) {
      let tagsSearch = tags?.join(',')
      url = "auth/global-search?type=" + tabSwitch + "&search=" + e.target.value + "&cat_id=" + tagsSearch
    } else {
      url = "auth/global-search?type=" + tabSwitch + "&search=" + e.target.value
    }
    let result = await apiHelper?.getRequest(url)
    if (result.code === DEVELOPMENT_CONFIG?.statusCode) {
      if (tabSwitch == 2) {
        setChannelList(result?.data)
      } else {
        setSeriesList(result?.data)
      }
    }
  }

  const getFilterApi = async (tagsSearch) => {
    let url = "auth/global-search?type=" + tabSwitch + "&catID=" + tagsSearch
    let result = await apiHelper.getRequest(url)
    if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
      setSeriesList(result?.data)
      setCheck(true)
      setTabSwitch1()
      setSelectedTags([])
    }
  }

  const searchTagsApi = async () => {
    setInputValue(searchTag)
    let url = "auth/global-search?type=" + tabSwitch + "&tags=" + searchTag
    let result = await apiHelper?.getRequest(url)
    if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
      setSeriesList(result?.data)
    }
  }

  useEffect(() => {
    if (searchTag) {
      searchTagsApi()
    }
  }, [searchTag])

  async function getDetailsAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest("auth/category-list")
    if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
      setCatList(result?.data)
      setIsLoading(false)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  useEffect(() => {
    getDetailsAPI()
  }, [refresh, tags, tabSwitch])

  return (
    <div className="main-inner-content">
      {
        isLoading && <ReactLoader />
      }
      <div className='container main-search-main'>
        <div className="search-title">
          <h3 className="fw-600 mb-4">Search</h3>
        </div>
        <div className="form-allchannel-search-cus  position-relative ">
          <form>
            <input onChange={handleChange} type="text" name="search" className="form-control" placeholder="Search" value={inputValue} />
            <button type="button" className="icon-search">
              <img src={search} alt="" className="search-img" />
            </button>
            {
              tabSwitch == 1 && <button data-bs-target="#Filter" data-bs-toggle="modal" type="button" className="filter-search">
                <img src={filter} alt="" className="search-img" /></button>
            }
          </form>
        </div>
        <div className="container-fluid mt-4">
          <div className="main-search-btn">
            <div className="stream-btn pt-3">
              <button onClick={() => setTabSwitch(1)} className={tabSwitch == 1 ? "common-btn btn-blue" : "common-btn btn-white"}>Series</button>
              <button onClick={() => setTabSwitch(2)} className={tabSwitch == 2 ? "common-btn btn-blue" : "common-btn btn-white"}>Channel</button>
            </div>
          </div>
        </div>
        {
          tabSwitch == 2 ?
            <>
              {
                channelList?.length == 0 && inputValue != '' &&
                <div style={{ flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }} className='no-search-results'>
                  {/* <img src={noSearchResults} /> */}
                  <p className="mb-0">
                    No results found for {inputValue}, try searching again with another keyword
                  </p>
                </div>
              }
              <ChannelSearch channelList={channelList} />
            </>
            :
            <>
              {
                seriesList?.length == 0 && inputValue != '' ?
                  <div style={{ flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }} className='no-search-results'>
                    <p className="mb-0">
                      No results found for {inputValue}, try searching again with another keyword
                    </p>
                  </div> : seriesList.length == 0 && check == true ?
                    <div style={{ flexDirection: 'column' }} className='no-search-results'>
                      <img src={noSearchResults} />
                      <h6>No series found</h6>
                    </div> :
                    <SearchSeries seriesList={seriesList} />
              }
            </>
        }
      </div>

      <AllChallengesFilter tagsSelected={tagsSelected} setTagsSelected={setTagsSelected} cat={catList} setTags={setTags} tags={tags} getFilterApi={getFilterApi} setTabSwitch={setTabSwitch1} tabSwitch={tabSwitch1} setSelectedTags={setSelectedTags} selectedTags={selectedTags} />

    </div>
  )
}

export default MainSearch;