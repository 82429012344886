import React, { useState } from 'react'
import { cast1, cast2, cast3, cast4, cast5, cast_user, dummy, img1, img2, img3, img4, img5, playLightbtn, seeAllArrow, videoPlayButton } from '../../Constants/images';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import SeriesVideoPlayer from '../../Components/VideoPlayer/seriesVideoPlayer';
import EpisodeModal from './addEpisodeModal';
import uploadToS3 from '../component/fileUpload';
import ERR_MESSAGE from '../../Helper/error-helper';
import ProgressBarComponent from '../../ControlComponents/progressBar';
import ReactLoader from '../../ControlComponents/react-loader';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../Helper/config"
import { NotificationManager } from 'react-notifications';
import EpisodeSeriesVideoPlayer from '../component/episodeSeriesPlayer';
function Episodes(props) {
  const [isLoading, setLoading] = useState(false)
  const [id, setId] = useState(null)
  const [episodeModal, setEpisodeModal] = useState(false)
  const [errors, setErrors] = useState("")
  const [thumbnail, setThumbnail] = useState("")
  const [formattedDuration, setFormattedDuration] = useState("")
  const [episodeUrl, setEpisodeUrl] = useState("");
  const [progressLoading, setProgressLoading] = useState(false)
  const [progress, setProgress] = useState();
  const [episodeFileUrl, setEpisodeFileUrl] = useState("");
  const [thumbnail1, setThumbnail1] = useState("")
  const [episodes, setEpisodes] = useState([]);
  const [episodes1, setEpisodes1] = useState([]);

  const [info, setInfo] = useState({
    title: "",
    description: "",
    episodeTitle: "",
    episodeSummary: "",
    castName: "",
    castSeriesName: "",
    category: ""
  })

  const handleChange = (e) => {
    setErrors("")
    const name = e.target.name
    const value = e.target.value
    setInfo({
      ...info,
      [name]: value
    })
  }
  const generateThumbnail = async (videoElement, files) => {
    const canvas = document.createElement('canvas');
    const videoWidth = videoElement.videoWidth;
    const videoHeight = videoElement.videoHeight;
    canvas.width = videoWidth;
    canvas.height = videoHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(videoElement, 0, 0, videoWidth, videoHeight);
    const thumbnailURL = canvas.toDataURL('image/jpeg');
    const fileThumbnail = dataURLtoFile(thumbnailURL, files[0].name.split(".")[0]);
    return { thumbnailURL, fileThumbnail };
  };


  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const fileChangeEpisode = async (e) => {
    setErrors("")
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];

    if (file) {
      const fileType = file.type.split('/')[0];
      if (fileType === 'video') {
        const files = e.target.files
        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata';

        const handleMetadataLoaded = () => {
          return new Promise(async (resolve) => {
            const durationInSeconds = videoElement.duration;
            const formattedDuration = formatDuration(durationInSeconds);
            setFormattedDuration(formattedDuration)
            const { thumbnailURL, fileThumbnail } = await generateThumbnailFromVideo(videoElement, durationInSeconds / 2, files);
            setThumbnail(thumbnailURL)
            fileUploadEpisode(fileThumbnail, file)
            const formData = new FormData();
            formData.append('mediaFile', file);
            const videoURL = URL.createObjectURL(file);
            setEpisodeUrl(videoURL)
            resolve();
          });
        };

        videoElement.addEventListener('loadedmetadata', async () => {
          await handleMetadataLoaded();
        });

        videoElement.src = URL.createObjectURL(file);
      }
    }
  };
  const generateThumbnailFromVideo = async (videoElement, timeInSeconds, files) => {
    return new Promise((resolve) => {
      const seekTime = Math.min(timeInSeconds, videoElement.duration - 0.1);
      videoElement.currentTime = seekTime;
      videoElement.addEventListener('seeked', async () => {
        const { thumbnailURL, fileThumbnail } = await generateThumbnail(videoElement, files);
        resolve({ thumbnailURL, fileThumbnail });
      });
    });
  };


  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    const hoursStr = hours < 10 ? `0${hours}` : `${hours}`;
    const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  };
  const fileUploadEpisode = async (thumb, file) => {
    const state = { videoLocation: null, thumbnailLocation: null };
    setProgressLoading(true)
    try {
      if (file || thumb) {
        await uploadToS3(thumb, file, state, setProgress);
        if (state.videoLocation || state.thumbnailLocation) {
          setProgressLoading(false)
          setEpisodeFileUrl(state.videoLocation)
          setThumbnail1(state.thumbnailLocation)
        }
      }

    }
    catch (error) {
    }
  };
  const [video, setVideo] = useState("")
  const [show, setShow] = useState(false)

  const handleClick = (item) => {
    setVideo(item?.video)
    setShow(!show)
    const trimmedName = item.title?.replace(/\s/g, '');;
    const chatId = `${item?.id}${trimmedName}`;
    localStorage.setItem("episodeChatId", chatId)
  }
  const handleSaveEpisode = (episode, episode1) => {
    const newEpisodes = [...episodes, episode];
    const newEpisodes1 = [...episodes1, episode1];
    setEpisodes(newEpisodes);
    setEpisodes1(newEpisodes1);
    handleEditAPI(newEpisodes1);
  };

  const handleValidation1 = () => {
    let errors = {};
    let formIsValid = true;
    const { episodeTitle, episodeSummary } = info;
    if (!thumbnail1 || thumbnail1.trim().length === 0) {
      formIsValid = false;
      errors["thumbnail1"] = ERR_MESSAGE.EPISODE_EMPTY
    }
    if (!episodeTitle || episodeTitle?.trim().length === 0) {
      formIsValid = false;
      errors["episodeTitle"] = ERR_MESSAGE.TITLE_EMPTY
    }
    if (!episodeSummary || episodeSummary.trim().length === 0) {
      formIsValid = false;
      errors["episodeSummary"] = ERR_MESSAGE.SUMMARY_EMPTY
    }
    setErrors(errors);
    return formIsValid;
  };
  const handleSubmitEpisode = (e) => {
    e.preventDefault();
    if (handleValidation1()) {
      const episodeObject = {
        video: episodeUrl,
        thumbnail: thumbnail,
        title: info.episodeTitle,
        description: info.episodeSummary,
        duration: formattedDuration,
        is_active: 1,
      };
      const episodeObject1 = {
        video: episodeFileUrl,
        thumbnail: thumbnail1,
        title: info.episodeTitle,
        description: info.episodeSummary,
        duration: formattedDuration,
        is_active: 1,
      };
      handleSaveEpisode(episodeObject, episodeObject1);

    }
  };

  async function handleEditAPI(episodes1) {
    let data = {
      "seriesId": id,
      "episodesData": episodes1,
    }
    setLoading(true)
    let result = await apiHelper.postService("creator/add-episode-and-clip", data)
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false)
      NotificationManager.success(result.message)
      setEpisodeModal(false);
      setInfo({
        ...info,
        episodeTitle: "",
        episodeSummary: ""
      })
      props.getDetailsAPI()
      setThumbnail1('')
    }
    else {
      setLoading(false)
      NotificationManager.error(result.message)
    }
  }

  return (
    <div>

      <section className="epsiode py-5">
        {isLoading && <ReactLoader />}
        {progressLoading && <>
          <ProgressBarComponent progress={progress} />
        </>}
        <div className="container">
          <div className='d-flex justify-content-between align-items-center'>
            <h3 className="fw-600 mb-0">Episodes</h3>
            <div className='col-6 text-end'>
              <button className=" common-btn" onClick={() => {
                setEpisodeModal(true)
                setId(props?.seriesDetail?.id)
              }}>Add</button>
            </div>
          </div>

          <section className='shortlistedepisode mt-4'>
            <div className="container">
              <div className='row align-items-center'>
                <div className='col-6'>
                  <h3 className="fw-600 mb-0"></h3>
                </div>

              </div>
              <div className='row mt-3'>
                <div className='col-12'>
                  <div className='trailor-slide-main'>
                    {
                      props?.seriesDetail?.episodes?.map((item) => (
                        <div className="add-epi-head pt-3" onClick={() => handleClick(item)} style={{ cursor: "pointer" }}>
                          <div className="add-sub-itemepi">
                            <div className="position-relative">
                              <img src={item.thumbnail} alt="" className="episodeimg-create text-center mx-auto" />
                              <div className="play-epi">
                                <img src={videoPlayButton} class="img-fluid" />
                              </div>
                            </div>
                            <div className="p-2">
                              <h6 className="fw-600 blue-text f16-size mb-0">{item.title}</h6>
                              <p className="fw-500 video-text mb-0">{props.modifyDuration(item.duration)}</p>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section >
      <section className="cast-crew">
        <div className="container">
          <h3 className="fw-600">Cast & Crew</h3>
          <div className="crew-wrpper-slider text-center gap-4 mt-5 ">
            {
              props?.seriesDetail?.series_casts.map((item) => {
                return (
                  <div className="crew-caste">
                    <div className='width-img'>
                      <img src={item.image} alt="" />
                    </div>
                    <h5 className="mt-3">{item?.name}</h5>
                    <p className="movie-tex video-text">{item?.name_in_series}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
      {
        episodeModal && <EpisodeModal setEpisodeModal={setEpisodeModal} handleChange={handleChange} info={info} fileChangeEpisode={fileChangeEpisode} episodeFileUrl={episodeFileUrl} thumbnail1={thumbnail1} errors={errors} handleSubmitEpisode={handleSubmitEpisode} setErrors={setErrors} setInfo={setInfo} setThumbnail1={setThumbnail1} />
      }
      {
        show && <EpisodeSeriesVideoPlayer show={show} setShow={setShow} video_url={video}
        chatOpen={props.chatOpen} creatorName={props.creatorName} creatorImg={props.creatorImg}  />
      }

    </div >
  )
}

export default Episodes;