import React, { useState } from 'react'
import { cast1, cast2, cast3, cast4, cast5, img1, img2, img3, img4, img5, playLightbtn, seeAllArrow } from '../../Constants/images';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import SeriesVideoPlayer from '../../Components/VideoPlayer/seriesVideoPlayer';
import EpisodeSeriesVideoPlayer from '../../CreaterDashboard/component/episodeSeriesPlayer';

function ShopEpisode(props) {
  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    var hDisplay = h > 0 ? h + ":" : "";
    var mDisplay = m > 0 ? m : "00";
    var sDisplay = s > 0 ? s : "00";
    return hDisplay + mDisplay + ":" + sDisplay;
  }

  const navigate = useNavigate()

  const [video, setVideo] = useState("")
  const [show, setShow] = useState(false)

  const handleClick = (item) => {
    setVideo(item?.video)
    setShow(!show)
    const trimmedName = item.title?.replace(/\s/g, '');;
    const chatId = `${item?.id}${trimmedName}`;
    localStorage.setItem("episodeChatId", chatId)
  }

  return (
    <div>
      <section className="epsiode py-60">
        <div className="container">
          <div className='d-flex justify-content-between align-items-center'>
            <h3 className="fw-600">Episodes</h3>
            <button onClick={() => navigate(ROUTES.MORE_EPISODE, { state: { episodes: props?.seriesDetail } })}
              className="see-all fw-600 f18-size">See all <img src={seeAllArrow} className="ms-2" /></button>
          </div>
          <div className="epsiode-slider-details mt-5">
            {
              props?.seriesDetail?.episodes?.map((item, index) => {
                return (
                  <div key={item?.id} onClick={() => handleClick(item)} className="item">
                    <div className="epsoide-slider-custom position-relative">
                      <img
                        src={item?.thumbnail}
                        className="episode-img-thumb"
                        alt=""
                      />
                      <div className="epsoide-title">
                        <h5 className=" m-0 fw-600">{item?.title}</h5>
                        <p className="mb-0 fw-500">{props.modifyDuration(item?.duration)}</p>
                      </div>
                      {/* <div className="trending-number-epi">
                        <h2 className="white">{index + 1}</h2>
                      </div> */}
                      <div className="epsoide-play">
                        <button className="eps-play">
                          <img src={playLightbtn} alt="" className='play-epi-slider' />
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section >
      <section className="cast-crew">
        <div className="container">
          <h3 className="fw-600">Cast & Crew</h3>
          <div className="crew-wrpper-slider text-center gap-4 mt-5 ">
            {
              props?.seriesDetail?.series_casts.map((item) => {
                return (
                  <div className="crew-caste">
                    <div className='width-img'>
                      <img src={item?.image} alt="" />
                    </div>
                    <h5 className="mt-3">{item?.name}</h5>
                    <p className="movie-tex video-text">{item?.name_in_series}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
      {
        show && <EpisodeSeriesVideoPlayer chatOpen={props.chatOpen} creatorName={props.creatorName} creatorImg={props.creatorImg} show={show} setShow={setShow} video_url={video} />
      }
    </div >
  )
}

export default ShopEpisode;