import React, { useEffect, useState } from 'react'

import apiHelper from '../../../Helper/api-helper'
import DEVELOPMENT_CONFIG from '../../../Helper/config'
import ReactLoader from '../../../ControlComponents/react-loader'
import Recomended from './Recomended/Recomended'
import Featured from '../../../Components/Featured/Featured'
import SeriesList from './seriesList'

function UserSeries() {

  const [featuredList, setFeaturedList] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)

  async function getAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest("series/series-list?channel_id=0")
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setIsLoading(false)
      return setFeaturedList(result?.data)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  useEffect(() => {
    getAPI()
  }, [refresh]);

  return (
    <div className="main-inner-content">
      {
        isLoading && <ReactLoader />
      }
      <Featured featuredList={featuredList} getAPI={getAPI}/>
      <SeriesList featuredList={featuredList} getAPI={getAPI}/>
    </div>
  )
}

export default UserSeries;