import React, { useState } from 'react'
import { biteImg, favRed, favouriteLike, image1, noFavourites, playLight, playLightBtn, videoPlayButton } from '../../Constants/images';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';
import apiHelper from '../../Helper/api-helper';

function AllFavSeries({ setTabSwitch, favSeries, getDetailsAPI, setIsLoading }) {
  const navigate = useNavigate()
  const [_, forceUpdate] = useState(0)

  async function addFavourites(item) {
    setIsLoading(true)
    let data = {
      "series_id": item?.series_id,
      "type": 2
    }
    forceUpdate(Math.random())
    let result = await apiHelper.postRequest("channel/add-favorites", data)
    if (result == undefined) {
      setIsLoading(false)
      getDetailsAPI()
    }
  }

  return (
    <div> <section className="channel-detail favchannel-main-cus">
      <div className="container">
        <div className="row">
          <div className="col-12 pt-3 pb-4">
            <h3 className="latest-vid-head fw-600">Favourites</h3>
            <div className="streaming py-6 response-padding">
              <div className="row">
                <div className="stream-btn">
                  <button onClick={() => setTabSwitch(0)} className="common-btn btn-blue">Series</button>
                  <button onClick={() => setTabSwitch(1)} className="common-btn btn-white">Channel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          {
            favSeries?.map((series) => {
              return (
                <div key={series?.series.id} className="col-12 col-sm-6 col-lg-4 col-xl-4 pb-4">
                  <div className="latest-video mx-auto">
                    <div className='position-relative'>
                      <div
                        className="latest-video-content  pt-2 pt-sm-3 px-2 px-sm-3"
                        style={{ cursor: "pointer" }} onClick={() => navigate(ROUTES.SERIES_DETAIL, { state: { id: series?.series.id } })} >
                        <Link to="" className="play-vid-btn"
                        ><img
                            src={videoPlayButton}
                            className="img-fluid"
                          /></Link>
                        <img
                          src={series?.series?.thumbnail}
                          className="img-fluid latest-vid-img"
                        />
                      </div>
                      <div className=" like-fav-cus" onClick={() => addFavourites(series)}>
                        <img src={favRed} alt="" className='fav-like-series' />
                      </div>
                    </div>
                    <div className="latest-video-cus px-3 py-3">
                      <h4 className="fw-600">{series?.series?.title}</h4>
                      <div className="latest-series-name">
                        <div className="video-tile">
                          <p className='mb-0 fw-400'>{series?.series?.episodesCount} {series.series.episodesCount == 1 ? "Episode":"Episodes"}</p>
                          <p className='mb-0 fw-500'>{series?.series?.year}</p>
                        </div>
                        <button onClick={() => navigate(ROUTES.SERIES_DETAIL, { state: { id: series?.series_id } })} className="common-btn watch-btn">
                          <span className='me-2'>Watch</span><img
                            src={playLightBtn}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }

        </div>
      </div>
    </section>
    </div>
  )
}

export default AllFavSeries;